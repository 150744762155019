import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Alert,
    Button, Card,
    DatePicker,
    Form,
    Input,
    InputNumber,
    message,
    Select,
    Space,
    Switch,
    Tag,
    TimePicker
} from 'antd';
import { Table } from 'antd';
import {
    formatDate, FORMATE_DATE_TIME, FORMATE_TIME,
    formatTimestamp, getDateTimestamp, IServerInfo,  ITableActivityQuest,
} from '@/types';
import {useFetchListWrapper, useContentHeight, useFetchConfigByKey, useFetchListWithCount} from '@/hooks';
import { FormModal,IFormOK } from '@/components/formModal';

import {getList, create, update, deleteRow} from "@/services/activityQuest";
import {TableFuncs} from "@/components/tableFuncs";
import {forEach, isArray, isNumber, isString, toNumber, unset} from "lodash";
import {createServer, getAllServerList} from "@/services/server";
import {JsonPreview} from "@/components/jsonPreview";
import dayjs from "dayjs";
import {XlsxUploader} from "@/components/xlsxUploader";
import {DownloadOutlined} from "@ant-design/icons";
import {FormContext} from "antd/es/form/context";
import {getGameConfigByKey} from "@/services/gameConfig";
import {ITableConfigLanguage} from "@/types/typings/config";
import {observe} from "web-vitals/dist/modules/lib/observe";


// activityList 活动列表ID
const activityList = [
    {
        id:10010,
        name:'积天好礼'
    },
    {
        id:10011,
        name:'挑战奖励'
    }
]

interface DataTaskReward {
   ID?:number;
   Value?:number;
}

interface DataTaskCondition {
    id?:number;
    point?:number;
    target?:number;
    target_value?:number;
    desc?:string;
}

interface DataTask {
    id?:number;
    tag?:string;
    desc?:string;
    pre_conditions?:DataTaskCondition[];
    conditions?:DataTaskCondition[];
    reward?:DataTaskReward[];
}

interface DataCycle {
    type?:number;
    value?:number[];
    start_time?:string;
    end_time?:string;
}

interface ActivityDataProps {
    score_item_id?:Number;
    config?:String;
    task_list?:DataTask[];
    cycle?:DataCycle
}

interface ActivityProps {
    sub_type?:number,
    server_ids?:number[],
    start_at?:string,
    end_at?:string,
    data?:ActivityDataProps
}

const ActivityView: React.FC<{value?:ActivityProps, initialValue?:any, onChange?:(value:ActivityProps)=>void}> =({value ={}, initialValue,onChange}) =>{
    const {form} = useContext(FormContext);
    const [serverList, fetchAllServer] = useFetchListWrapper<IServerInfo>(getAllServerList);
    const [serverSelectOptions, setServerSelectOptions] = useState((activityList || []).map((d) => ({
        value: d.id,
        label: `(${d.id}) ${d.name}`
    })))

    const [languageConfig,fetchLanguageConfig] = useFetchConfigByKey<ITableConfigLanguage>(getGameConfigByKey)
    const languageMap = new Map<string,string>();

    for (let i = 0; i < languageConfig.length; i++) {
        const elem = languageConfig[i];
        languageMap.set(elem.Key, elem.Zh);
    }

    const myValue:ActivityProps = {
        ...initialValue
    }

    if (myValue?.sub_type != undefined){
        myValue.sub_type = myValue.sub_type;
    }

    if (myValue?.server_ids != undefined){
        myValue.server_ids = myValue.server_ids;
        if (isString(myValue.server_ids)){
            myValue.server_ids =JSON.parse(myValue.server_ids);
        }

    }

    if (myValue?.start_at != undefined){
        myValue.start_at = myValue.start_at;
        if (isNumber((myValue.start_at))){
            myValue.start_at = dayjs.unix(myValue.start_at).format(FORMATE_DATE_TIME);
        }
    }

    if (myValue?.end_at != undefined && toNumber(myValue.end_at) > 0){
        myValue.end_at = myValue.end_at;
        if (isNumber((myValue.end_at))){
            myValue.end_at = dayjs.unix(myValue.end_at).format(FORMATE_DATE_TIME);
        }
    }

    if (myValue?.data != undefined){
        if (isString(myValue.data)){
            myValue.data = JSON.parse(myValue.data);
        }
    }

    if (!myValue.data){
        myValue.data = {
            cycle: {
                type:0,
                start_time:'00:00:00',
                end_time:'23:59:59'
            }
        }
    }

    useEffect(()=>{
        fetchAllServer();
        fetchLanguageConfig('Language');
    },[]);

    const triggerChange = (changedValue: ActivityProps) => {
        onChange?.({...value, ...changedValue });
    };

    const valueRef = useRef<ActivityProps>(myValue)
    value = valueRef.current

    const [cycle, setCycle] = useState<DataCycle>(value?.data?.cycle!);

    const cycleTypeOnChange = (v:number,opt:any)=> {
        const nv = value?.data?.cycle!;
        nv.type = v;

        switch (nv.type){
            case 0:
                nv.value=[];
                break;
            case 1:
                if (nv.value?.length){
                    nv.value = nv.value.slice(0,1);
                }else{
                    nv.value = [1];
                }
                break;
            case 2:
            case 3:
                if (nv.value?.length){
                    nv.value = nv.value.slice(0,2);
                }else{
                    nv.value = [1,1];
                }

                if (nv.value.length == 1){
                    nv.value[1] = 1;
                }
                break;
        }

        setCycle({...nv, type:v});
        triggerChange(valueRef.current);
    }

    const cycleOpt1OnChange = (v:number|null)=>{
        if (v != null && v >= 0){
            const nv = value?.data?.cycle;
            let cv = nv?.value;
            if (!cv){
                cv = [];
            }
            cv[0] = v;
            nv!.value = cv;

            valueRef.current = value

            setCycle(p => ({...p,value:[...cv!]}));
            triggerChange(valueRef.current);
        }
       };

    const cycleOpt2OnChange = (v:number|null)=>{
        if (v){
            const nv = value?.data?.cycle;
            let cv = nv?.value;
            if (!cv){
                cv = [1];
            }else if (!cv[0]){
                cv[0] = 1;
            }

            cv[1] = v;
            nv!.value = cv;
            valueRef.current = value

            setCycle(p => ({...p,value:[...cv!]}));
            triggerChange(valueRef.current);
        }
       };

    const cycleOptionUpdate =(cycle:DataCycle) => {
        switch (cycle?.type){
            case 0:
                return (
                        <Tag>
                            一直开启，直到结束
                        </Tag>
                )
            case 1:
                return (
                    <Space>
                        <Form.Item name={['data','cycle','value',0]} initialValue={cycle?.value![0]} rules={[{required:true}]} style={{margin:0}}>
                            <InputNumber onChange={cycleOpt1OnChange} defaultValue={1} min={1} placeholder={"请输入天数"} />
                        </Form.Item>
                        <Tag>
                            每次开启{cycle.value![0]}天，并轮训
                        </Tag>
                    </Space>
                )
            case 2:
                return (
                    <Space>
                        <Form.Item name={['data','cycle','value',0]} initialValue={cycle?.value![0]} rules={[{required:true}]} style={{margin:0}}>
                            <InputNumber onChange={cycleOpt1OnChange} defaultValue={1} min={1} placeholder={"请输入天数"} />
                        </Form.Item>
                        <Form.Item name={['data','cycle','value',1]} initialValue={cycle?.value![1]} rules={[{required:true}]} style={{margin:0}}>
                            <InputNumber onChange={cycleOpt2OnChange} defaultValue={1} min={1} max={cycle?.value![0]} placeholder={"请输入天数"} />
                        </Form.Item>
                        <Tag>
                            每{cycle.value![0]}天开启{cycle.value![1]}天
                        </Tag>
                    </Space>
                )
            case 3:
                return (
                    <Space>
                        <Form.Item name={['data','cycle','value',0]} initialValue={cycle?.value![0]} rules={[{required:true}]} style={{margin:0}} >
                            <InputNumber onChange={cycleOpt1OnChange} defaultValue={1} min={0} max={6} placeholder={"请输入天数"} />
                        </Form.Item>
                        <Form.Item name={['data','cycle','value',1]} initialValue={cycle?.value![1]} rules={[{required:true}]} style={{margin:0}}>
                            <InputNumber onChange={cycleOpt2OnChange} defaultValue={1} min={1} max={7} placeholder={"请输入天数,不能大于7"} />
                        </Form.Item>
                        <Tag>
                            每周的周{cycle.value![0]}开启{cycle.value![1]}天
                        </Tag>
                    </Space>
                )
        }
    }

    const taskListConfigValidator = (_: any, value:any)=>{
        console.log(value);
        if (!value){
            return Promise.reject(new Error('任务清单未设置'));
        }
        try{
            let obj = value;
            if (isString(obj)){
                obj = JSON.parse(obj);
            }
            if (!isArray(obj)){
                return Promise.reject(new Error('内容必须是一个JSON数组'));
            }
         }catch (ex:any){
            return Promise.reject(new Error('无效JSON数据:'+ex.toString()));
        }
        return Promise.resolve();
    }

    const serverSelectChange = (e :any, o:any)=>{

    }

    const [taskList, setTaskList] = useState<DataTask[]>(myValue.data.task_list||[]);
    return (
        <span>
            <Form.Item label="活动子类型" name="sub_type" initialValue={myValue.sub_type} rules={[{ required: true, message: '活动不能为空' }]}>
                <Select
                    showSearch
                    defaultValue={myValue.sub_type}
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    options={serverSelectOptions}
                />
            </Form.Item>
            <Form.Item label="分服" initialValue={myValue.server_ids} name="server_ids" rules={[{ required: true, type: 'array', message: '选择分服' }]}>
                <Select
                    showSearch
                    onSelect={serverSelectChange}
                    mode="multiple"
                    allowClear
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    options={(serverList || []).map((d) => ({
                        value: d.id,
                        label: `(${d.id}) ${d.name}`,
                    }))}
                />
            </Form.Item>
            <Space>
                <Form.Item label="开始时间" name="start_at" initialValue={dayjs(myValue.start_at)} rules={[{ required: true, message: '未设置开始时间' }]}>
                    <DatePicker showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss" />
                </Form.Item>
                <Form.Item label="结束时间" name="end_at" initialValue={myValue.end_at?dayjs(myValue.end_at):''} tooltip={'保持为空表示该活动一直开启'} >
                    <DatePicker showTime={{ format: 'HH:mm:ss' }} allowClear format="YYYY-MM-DD HH:mm:ss" />
                </Form.Item>
            </Space>
            <Card title={"任务清单"} size={"small"} style={{marginBottom:10}}>
                <div style={{height:0,position:'absolute',right:3,top:3}}>
                    <Space >
                        <Button type={'link'} href={'./assets/任务型活动任务清单模板.xlsx'} >下载模板</Button>
                        <XlsxUploader icon={<DownloadOutlined />} btnTxt={'导入数据表'} onChange={(_,result)=>{
                            try {
                                // 删除头两个元素
                                result.shift();
                                result.shift();

                                let data = form?.getFieldValue('data') as ActivityDataProps;
                                if (!data){
                                    data = {};
                                }

                                result.forEach((v)=> {
                                    if (v.pre_conditions){
                                        v.pre_conditions = JSON.parse(v.pre_conditions);
                                    }else {
                                        v.pre_conditions = [];
                                    }
                                    v.conditions = JSON.parse(v.conditions);
                                    v.reward = JSON.parse(v.reward);
                                });

                                data.task_list = result;
                                setTaskList(result);
                                form?.setFieldValue('data',data);
                            } catch (e: any) {
                                message.error(e.toString());
                            }
                        }} />
                    </Space>
                </div>
                <Form.Item style={{margin:0}} name={['data','task_list']} initialValue={myValue.data.task_list} rules={[{required:true,type:'array', validator:taskListConfigValidator}]}>
                    <Input style={{display:'none'}} />
                    <Table scroll={{y:400}} rowKey={'id'} columns={[
                        {
                            title:'任务ID',
                            key:'id',
                            dataIndex:'id',
                            width:80,
                        },
                        {
                            title:'标签',
                            key:'tag',
                            dataIndex:'tag',
                        },
                        {
                            title:'任务描述',
                            key:'desc',
                            dataIndex:'desc',
                            render:(_,record)=>{
                                const v = languageMap.get(record.desc!);
                                return v?v:record.desc;
                            }
                        },
                        {
                            title:'条件列表',
                            key:'conditions',
                            dataIndex:'conditions',
                            render:(value, record, index)=>{
                                return (<JsonPreview json={record.conditions?.map((e)=>{
                                    const v = languageMap.get(e.desc!);
                                    return {
                                        ...e,
                                        title:v?v:e.desc
                                    }
                                })} />);
                            }
                        },
                        {
                            title:'奖励',
                            key:'reward',
                            dataIndex:'reward',
                            render:(value, record, index)=>{
                                return (<JsonPreview json={record.reward} />);
                            }
                        }
                    ]} dataSource={taskList} pagination={false}>
                </Table>
                </Form.Item>
                {/*<Form.Item style={{margin:0}} name={['data','task_list']} initialValue={JSON.stringify(myValue.data.task_list,null,"  ")} rules={[{required:true,validator:taskListConfigValidator}]}>*/}
                {/*    <Input.TextArea rows={10} minLength={1} placeholder={'输入json格式的配置'} />*/}
                {/*</Form.Item>*/}
            </Card>
            <Card title={"周期设置"} size={"small"} style={{marginBottom:10}}>

                <Space direction={"vertical"}>
                    <Space>
                    <Form.Item label={"类型"} initialValue={cycle?.type} name={['data','cycle','type']} style={{margin:0}}>
                        <Select
                        onChange={cycleTypeOnChange}
                            style={{width:220}}
                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                            options={[
                                {label:"持续",value:0},
                                {label:"每次开启X天并轮训",value:1},
                                {label:"每X天开启Y天",value:2},
                                {label:"每周的周X开启，并持续Y天",value:3}
                            ]}
                        />
                    </Form.Item>
                    {cycleOptionUpdate(cycle)}
                    </Space>

                    <Space>
                        <Form.Item label="开始" name={['data','cycle','start_time']} initialValue={dayjs(cycle?.start_time,'HH:mm:ss')} style={{margin:0}} >
                            <TimePicker allowClear={false} format="HH:mm:ss"/>
                        </Form.Item>

                        <Form.Item label="结束" name={['data','cycle','end_time']} initialValue={dayjs(cycle?.end_time,'HH:mm:ss')} style={{margin:0}}>
                            <TimePicker allowClear={false} format="HH:mm:ss"/>
                        </Form.Item>
                    </Space>
                </Space>

            </Card>
            <Card title={"扩展设置"} size={"small"}>
                <Form.Item label="积分物品ID"  initialValue={myValue.data.score_item_id} name={['data','score_item_id']} >
                    <InputNumber placeholder={'输入物品ID'} />
                </Form.Item>
                <Form.Item label="自定义配置" initialValue={myValue.data.config} name={['data','config']} style={{margin:0}} >
                    <Input.TextArea rows={5} minLength={1} placeholder={'输入json格式的配置'} />
                </Form.Item>
            </Card>
        </span>
    );
}

const ActivityQuestPage: React.FC = () => {
    const [serverList, fetchAllServer] = useFetchListWrapper<IServerInfo>(getAllServerList);
    const [data, fetchActivityList] = useFetchListWithCount<ITableActivityQuest>(getList);
    const contentHeight = useContentHeight();

    const searchServerId = useRef(-1);
    const searchSubType = useRef(0);
    const searchStatus = useRef([0,1]);

    useEffect(() => {
        fetchList();
        fetchAllServer();
    }, []);

    const fetchList = (page: number = 0, pageSize: number = 50) => {
        const param: { [key: string]: any } = { page, pageSize };

        if (searchServerId.current >= 0) {
            param['serverId'] = searchServerId.current;
        }else{
            param['serverId'] = -1
        }

        if (searchSubType.current){
            param['subType'] = searchSubType.current;
        }

        if (searchStatus.current != undefined){
            param['status'] = searchStatus.current.join(',');
        }

        fetchActivityList(param);
    };

    // 解析活动数据
    const parseActivityData = (values:any) => {
        values.server_ids.sort((a:number,b:number)=>{return a-b});
        if (values.server_ids[0]==0){
            values.server_ids=[0];
        }

        values.server_ids = JSON.stringify(values.server_ids);

        const data:ActivityDataProps = {
            ...values.data,
            cycle:{
                ...values.data.cycle,
                start_time:formatDate(values.data.cycle.start_time,FORMATE_TIME),
                end_time:formatDate(values.data.cycle.end_time,FORMATE_TIME),
            },
        }

        if (isString(data.task_list)){
            data.task_list = JSON.parse(data.task_list);
        }

        data.task_list?.forEach((v,i,arr)=>{
            if (isString(v.conditions)){
                v.conditions = JSON.parse(v.conditions);
            }

            if (isString(v.pre_conditions)){
                v.pre_conditions = JSON.parse(v.pre_conditions);
            }

            if (isString(v.reward)){
                v.reward = JSON.parse(v.reward);
            }
        })

        if (!data.task_list?.length){
            throw new Error("任务清单未填写。");
        }

        values.start_at = getDateTimestamp(formatDate(values.start_at, FORMATE_DATE_TIME));
        if (values.end_at) {
            values.end_at = getDateTimestamp(formatDate(values.end_at, FORMATE_DATE_TIME));
        }else{
            values.end_at = 0;
        }

        values.data = JSON.stringify(data);
        return values
    }

    // 创建活动
    const createActivity = (args:IFormOK) => {
        try {
            const values = parseActivityData(args.values);
            create(values)
                .then((res) => {
                    if (!res.data.result){
                        message.error('创建失败');
                        return
                    }

                    args.closeModal();
                    fetchList();
                    message.success('创建成功');
                })
                .catch((e) => {})
                .finally(() => args.closeLoading());
        } catch (e: any) {
            args.closeLoading();
            message.error(e.toString());
        }
    }

    // 更新活动
    const updateActivity = (args:IFormOK) => {
        try {
            const values = parseActivityData(args.values);
            update(values)
                .then((res) => {
                    if (!res.data.result){
                        message.error('修改失败');
                        return
                    }
                    args.closeModal();
                    fetchList();
                    message.success('修改成功');
                })
                .catch((e) => {})
                .finally(() => args.closeLoading());
        } catch (e: any) {
            args.closeLoading();
            message.error(e.toString());
        }
    }

    // 删除活动
    const deleteActivity = (args:IFormOK) => {
        try {
            const values = args.values;
            values.actividyId = values.actividy_id;
            delete values.actividy_id;
            deleteRow(values)
                .then((res) => {
                    if (!res.data.result){
                        message.error('删除失败');
                        return
                    }
                    args.closeModal();
                    fetchList();
                    message.success('删除成功');
                })
                .catch((e) => {})
                .finally(() => args.closeLoading());
        } catch (e: any) {
            args.closeLoading();
            message.error(e.toString());
        }
    }


    return (
        <div>
            <TableFuncs>
                <Space size={[5, 5]} wrap>
                    <FormModal
                        btnText="新增活动"
                        btnType="primary"
                        onOK={createActivity}
                        winWidth={800}
                    >
                        <ActivityView />
                    </FormModal>
                </Space>
            </TableFuncs>

            <TableFuncs>
                <Space size={[5, 5]} wrap>
                    <Select
                        style={{ width: 200 }}
                        size={'large'}
                        showSearch
                        allowClear={true}
                        placeholder={'分服'}
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        options={
                        (serverList || []).
                            map((d) => ({
                            value: d.id,
                            label: `(${d.id}) ${d.name}`
                        }))}
                        onChange={(val: any, option) => {
                            searchServerId.current = val;
                        }}
                    />
                    <Select
                        style={{ width: 200 }}
                        size={'large'}
                        showSearch
                        allowClear
                        placeholder={'活动'}
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        options={(activityList || []).map((d) => ({
                            value: d.id,
                            label: `(${d.id}) ${d.name}`
                        }))}
                    />
                    <Select
                        style={{ width: 200 }}
                        size={'large'}
                        showSearch
                        mode={'multiple'}
                        allowClear={true}
                        placeholder={'状态'}
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        options={[{value:0,label:'未启用'},{value:1,label:'启用中'},{value:2,label:'已删除'}]}
                        onChange={(val: any, option) => {
                            searchStatus.current = val;
                        }}
                        defaultValue={searchStatus.current}
                    />
                    <Button
                        size={'large'}
                        type={'primary'}
                        onClick={(evt) => {
                            fetchList();
                        }}
                    >
                        查询
                    </Button>
                </Space>
            </TableFuncs>
            <Table
                rowKey="id"
                columns={[
                    {
                        title: "#",
                        key: '#',
                        width: 50,
                        dataIndex: 'id'
                    },
                    {
                        title: '分服ID',
                        key: 'server_ids',
                        dataIndex: 'server_ids',
                        render:(value, record, index)=>{
                            const serverIDs = JSON.parse(record.server_ids);
                            if (serverIDs.includes(0)){
                                return '所有分服'
                            }
                            return record.server_ids
                        }
                    },
                    {
                        title: '活动',
                        key: 'sub_type',
                        render: (_,r )=>{
                            const v = activityList.find(v => (v.id == r.sub_type));
                            if (!v){
                                return  '无效的活动';
                            }
                            return `(${v.id}) ${v.name}`;
                        },
                    },
                    {
                        title: '活动配置',
                        key: 'data',
                        dataIndex: 'data',
                        render: (_, record) => {
                            if (record.data.indexOf("{")>=0){
                                return <JsonPreview json={record.data} />;
                            }
                            return record.data;
                        },
                    },
                    {
                        title: '开始时间',
                        key:'start_at',
                        render:(_,r)=>{
                            return formatTimestamp(r.start_at!)
                        }
                    },
                    {
                        title: '结束时间',
                        key:'end_at',
                        render:(_,r)=>{
                            if (r.end_at){
                                return formatTimestamp(r.end_at!)
                            }
                            return '永久'
                        }
                    },
                    {
                        title: '状态',
                        key: 'status',
                        render:(_,r)=>{
                            if (r.status == 2){
                                return (<Tag color={"error"} >已删除</Tag>);
                            }
                            if (r.status == 0){
                                return (<Tag color={"yellow"} >未启用</Tag>);
                            }
                            if (r.status == 1){
                                return (<Tag color={"success"} >启用中</Tag>);
                            }
                        }
                    },
                    {
                        title: '更新时间',
                        key: 'updated_at',
                        dataIndex: 'updated_at',
                    },
                    {
                        title: '更新人',
                        key: 'updated_by',
                        dataIndex: 'updated_by',
                    },
                    {
                        title: '创建时间',
                        key: 'created_at',
                        dataIndex: 'created_at',
                    },
                    {
                        title: '创建人',
                        key: 'created_by',
                        dataIndex: 'created_by',
                    },
                    {
                        title: '操作',
                        key: 'operate',
                        render:(_,r)=>{
                            return (
                                <Space size={[5, 5]} wrap>
                                    <FormModal
                                        title={'复制活动'}
                                        btnText="复制"
                                        onOK={createActivity}
                                        winWidth={800}
                                    >
                                    <ActivityView initialValue={r} />

                                    </FormModal>
                                    {r.status == 0 && (
                                        <FormModal
                                            title={'修改活动'}
                                            btnText="修改"
                                            btnType="primary"
                                            onOK={updateActivity}
                                            winWidth={800}
                                        >
                                            <Space style={{marginBottom:24}}>
                                                <Form.Item label="启用" name="status" style={{margin:0}} initialValue={false}>
                                                    <Switch defaultChecked={false} />
                                                </Form.Item>
                                                <Alert  type={"warning"} message={'启用后将无法进行修改,需要进行删除后重新创建活动'}></Alert>
                                            </Space>

                                            <Form.Item name='id' initialValue={r.id} style={{display:'none'}}>
                                                <Input type={'hidden'} />
                                            </Form.Item>
                                            <ActivityView initialValue={r} />
                                        </FormModal>
                                    )}

                                    {r.status != 2 && (
                                        <FormModal
                                            btnType={'primary'}
                                            danger={true}
                                            btnText={'删除'}
                                            title={'删除活动'}
                                            onOK={deleteActivity}
                                        >
                                            <Form.Item name='id' initialValue={r.id} style={{display:'none'}}>
                                                <Input type={'hidden'} />
                                            </Form.Item>
                                            <Alert message={'请输入活动ID: '+r.sub_type+' 进行删除'} />
                                            <Form.Item label="活动ID" name="sub_type"  rules={[{ required: true, message: '活动ID不能为空' }]}>
                                                <InputNumber maxLength={100} minLength={1} style={{ width: 200 }} />
                                            </Form.Item>
                                        </FormModal>
                                    )}
                                </Space>
                            )
                        }
                    }
                ]}
                dataSource={data[0]}
                pagination={{pageSize: 50,total:data[1]}}
                scroll={{y:contentHeight - 150}}
            >
            </Table>
        </div>
    );
};

export default ActivityQuestPage;