import React, { useEffect } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Button, Upload, message, Form, Input, Space } from 'antd';
import { getHeaders, getUploadHostName } from '@/utils/request';
import { Table, Switch } from 'antd';
import { createVersion, getList, IVersionItem, renameApkVersion, setVersion, updateVersion } from '@/services/version';
import { StatusType, VersionPlatformType, formatDate, formatSqlData } from '@/types';
import { useFetchListWrapper, useContentHeight } from '@/hooks';
import { FormModal } from '@/components/formModal';
import { TableFuncs } from '@/components/tableFuncs';
import { CopyText } from '@/components/copyText';
import { QRCode } from '@/components/qrCode';
import { UrlLink } from '@/components/urlLink';
import { Checkbox } from 'antd';

const uploadPath = '/hmf/admin/uploadApk';

const UploadZip: React.FC<{ id: number; onSuccess?: Function; onError?: Function }> = ({ id, onSuccess, onError }) => {
  const props: UploadProps = {
    accept: '.apk',
    name: 'file',
    action: `${getUploadHostName()}${uploadPath}`,
    headers: {
      ...getHeaders(uploadPath),
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        onSuccess?.(id, info.file.response);
        message.success(`上传成功:${info.file.name}`);
      } else if (info.file.status === 'error') {
        onError?.(id, info.file.response);
        message.error(`上传失败:${info.file.response.errMsg}`);
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  return (
    <Upload {...props}>
      <Button icon={<UploadOutlined />}>上传 Android apk 包</Button>
    </Upload>
  );
};

const VersionAndroidPage: React.FC = () => {
  const [data, fetchList] = useFetchListWrapper<IVersionItem>(getList);
  const contentHeight = useContentHeight();

  useEffect(() => {
    fetchList({ platform: VersionPlatformType.Android });
  }, []);

  return (
    <div>
      <TableFuncs>
        <FormModal
          btnText="新建Android版本"
          btnType="primary"
          onOK={(args) => {
            try {
              if (!/^\d+\.\d+\.\d+$/.test(args.values.clientVersion)) {
                throw '版本号格式错误';
              }
              if (!/^\d+$/.test(args.values.clientBuild)) {
                throw 'build号格式错误';
              }

              createVersion({ ...args.values, platform: VersionPlatformType.Android })
                .then((res) => {
                  args.closeModal();
                  fetchList({ platform: VersionPlatformType.Android });
                })
                .catch((e) => {})
                .finally(() => args.closeLoading());
            } catch (e: any) {
              args.closeLoading();
              message.error(e);
            }
          }}
        >
          <Form.Item label="名称" name="version" rules={[{ required: true, message: '输入名称' }]}>
            <Input maxLength={100} minLength={1} />
          </Form.Item>
          <Form.Item label="版本号" name="clientVersion" rules={[{ required: true, message: '输入版本号' }]}>
            <Input maxLength={100} minLength={1} />
          </Form.Item>
          <Form.Item label="build号" name="clientBuild" rules={[{ required: true, message: '输入build号' }]}>
            <Input maxLength={100} minLength={1} />
          </Form.Item>
        </FormModal>
      </TableFuncs>

      <Table
        rowKey="id"
        columns={[
          {
            title: '#',
            key: '#',
            width: 50,
            render: (text, record, rowIndex) => rowIndex + 1,
          },
          {
            title: '名称',
            dataIndex: 'version',
            key: 'version',
          },
          {
            title: '版本',
            key: 'client_info',
            render: (_, record) => {
              return (
                <Checkbox
                  checked={!!record.active}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setVersion(record.id).then(() => {
                        fetchList({ platform: VersionPlatformType.Android });
                        message.success('操作成功');
                      });
                    } else {
                      message.error('不能操作取消');
                    }
                  }}
                >{`${record.clientVersion}-${record.clientBuild}`}</Checkbox>
              );
            },
          },
          {
            title: '正式地址',
            dataIndex: 'pordUrl',
            key: 'pordUrl',
            render: (_, record) => {
              return <UrlLink href={record.pordUrl} />;
            },
          },
          {
            title: '创建时间',
            key: 'create_at',
            render: (_, record) => {
              return formatSqlData(record.create_at!);
            },
          },
          {
            title: '测试地址',
            dataIndex: 'url',
            key: 'url',
            render: (_, record) => {
              return <UrlLink href={record.url} />;
            },
          },
          {
            title: '测试状态',
            key: 'status',
            render: (_, record) => {
              return (
                <Switch
                  onClick={() => {
                    renameApkVersion({
                      url: record.url,
                      version: record.status === StatusType.active ? undefined : record.version,
                    }).then((res) => {
                      updateVersion({
                        id: record.id,
                        platform: VersionPlatformType.Android,
                        url: res.data.url,
                        status: record.status === StatusType.active ? StatusType.pending : StatusType.active,
                      }).then((res) => {
                        fetchList({ platform: VersionPlatformType.Android });
                      });
                    });
                  }}
                  checked={record.status === StatusType.active}
                />
              );
            },
          },
          {
            title: '操作',
            key: 'action',
            render: (_, record) => {
              return (
                <Space size={[5, 5]} wrap>
                  <CopyText content={record.url} btnText="复制链接" />
                  <QRCode content={record.url} title={`Android: v${record.version}`} />
                  {record.status === StatusType.active ? null : (
                    <UploadZip
                      onSuccess={(id: number, res: any) => {
                        updateVersion({
                          id,
                          platform: VersionPlatformType.Android,
                          url: res.url,
                        }).then((res) => {
                          fetchList({ platform: VersionPlatformType.Android });
                        });
                      }}
                      id={record.id}
                    />
                  )}
                  <FormModal
                    btnText="更新正式地址"
                    onOK={(args) => {
                      if (!/^https?:\/\//.test(args.values.pordUrl)) {
                        message.error('地址格式错误');
                        args.closeLoading();
                        return;
                      }
                      updateVersion({
                        ...record,
                        ...args.values,
                      })
                        .then((res) => {
                          args.closeModal();
                          fetchList({ platform: VersionPlatformType.Android });
                        })
                        .catch((e: any) => {})
                        .finally(() => args.closeLoading());
                    }}
                  >
                    <Form.Item label="地址" name="pordUrl" rules={[{ required: true, message: '输入地址' }]}>
                      <Input maxLength={1000} minLength={1} />
                    </Form.Item>
                  </FormModal>
                </Space>
              );
            },
          },
        ]}
        dataSource={data}
        pagination={{ pageSize: 50 }}
        scroll={{ y: contentHeight - 150 }}
      />
    </div>
  );
};

export default React.memo(VersionAndroidPage);
