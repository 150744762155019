import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { useFetchListWrapper } from '@/hooks';
import { getUserGameData } from '@/services/user';
import Scrollbars from 'react-custom-scrollbars';
import ReactJson from 'react-json-view';

export const UserGameData: React.FC<{ uid?: number }> = ({ uid }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<any>({});

  useEffect(() => {
    if (uid && open) {
      getUserGameData({ uid: uid }).then((res) => {
        console.log(res.data);
        setData(res.data);
      });
    }
  }, [open]);

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
      >
        游戏数据
      </Button>
      <Modal footer={<></>} width={800} open={open} onCancel={() => setOpen(false)} destroyOnClose>
        <div style={{ height: 500 }}>
          <Scrollbars>
            <ReactJson
              collapsed={1}
              groupArraysAfterLength={1000}
              name={false}
              enableClipboard={false}
              displayDataTypes={false}
              src={data}
            />
          </Scrollbars>
        </div>
      </Modal>
    </>
  );
};
