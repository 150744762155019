import { StoreProvider } from 'easy-peasy';
import ReactDOM from 'react-dom';
import App from './entry/app';
import store from './entry/store';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Register from './entry/register';

ReactDOM.render(
  <StoreProvider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="*">
          <App />
        </Route>
      </Switch>
    </BrowserRouter>
  </StoreProvider>,
  document.getElementById('root'),
);
