import React, { useState, useEffect } from 'react';
import { Button, Modal, Table } from 'antd';
import { formatDate, StatusTypeName, ITableAccountForbidden, formatSqlData } from '@/types';
import { useFetchListWrapper } from '@/hooks';
import { accountForbiddenList } from '@/services/account';

export const AccountForbidden: React.FC<{ accountId?: number }> = ({ accountId }) => {
  const [open, setOpen] = useState(false);
  const [data, fetchList] = useFetchListWrapper<ITableAccountForbidden>(accountForbiddenList);

  useEffect(() => {
    if (accountId && open) {
      fetchList({ accountId:accountId });
    }
  }, [open]);

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
      >
        封禁记录
      </Button>
      <Modal title="封禁记录" width={800} open={open} footer={null} onCancel={() => setOpen(false)} destroyOnClose>
        <Table
          rowKey="id"
          columns={[
            {
              title: '#',
              key: '#',
              width: 50,
              render: (text, record, rowIndex) => rowIndex + 1,
            },
            {
              title: '状态',
              dataIndex: 'status',
              key: 'status',
              render: (_, record) => {
                return <>{StatusTypeName[`CN${record.status || 1}`]}</>;
              },
            },
            {
              title: '开始时间/UTC0',
              dataIndex: 'begin_at',
              key: 'begin_at',
              render: (_, record) => {
                return formatDate(record.begin_at!);
              },
            },
            {
              title: '结束时间/UTC0',
              dataIndex: 'end_at',
              key: 'end_at',
              render: (_, record) => {
                return formatDate(record.end_at!);
              },
            },
            {
              title: '创建时间',
              key: 'create_at',
              render: (_, record) => {
                return formatSqlData(record.created_at!);
              },
            },
          ]}
          dataSource={data}
          pagination={{ pageSize: 50 }}
        />
      </Modal>
    </>
  );
};
