import { IBaseResponse, ITableAccount, ITableUserForbidden } from '@/types';
import request from '@/utils/request';

interface IGetUserListReq {
  page: number;
  pageSize: number;
  serverId:number;
  accountId:number;
  uid:number;
  nickname:string;
}
interface IGetUserListRes {
  list: ITableAccount[];
  count: number;
}
export const getList = async function (params: IGetUserListReq) {
  return await request<IGetUserListRes>(`/hmf/admin/user/list`, {
    method: 'GET',
    params,
  });
};

interface IGetUserDataReq {
  uid: number;
}
interface IGetUserForbiddenListRes {
  list: ITableUserForbidden[];
}
export const getUserForbiddenList = async function (params: IGetUserDataReq) {
  return await request<IGetUserForbiddenListRes>(`/hmf/admin/user/forbiddenList`, {
    method: 'GET',
    params,
  });
};

export const getUserGameData = async function (params: IGetUserDataReq) {
  return await request<IGetUserForbiddenListRes>(`/hmf/admin/user/getGameData`, {
    method: 'POST',
    data: { ...params, timezone: 8 },
  });
};

export const clearUserData = async function (uid: number) {
  return await request<IBaseResponse>(`/hmf/admin/user/clearUserData`, {
    method: 'GET',
    params: { uid },
  });
};


export const forbiddenUser = async function (data: any) {
  return await request<IBaseResponse>(`/hmf/admin/user/forbidden`, {
    method: 'POST',
    data,
  });
};

export const kickOutUser = async function (data: any) {
  return await request<IBaseResponse>(`/hmf/admin/user/kickOut`, {
    method: 'POST',
    data,
  });
};
