import {IItem} from "@/types/typings/useData";

/**
 * 物品表数据
 */
export interface ITableConfigItem {

    ID: number;
    /** 品质 */
    Quality: number;

    Name:string;

    Icon?:string;
}

/**
 * 语言表数据
 */
export interface ITableConfigLanguage {
    // 健
    Key: string;

    // 中文名
    Zh: string;
}


/**
 * (付费)Type=1时，ID为订单ID
 * (广告)Type=2时，ID固定为0，need固定为0
 * (兑换)Type=3时，ID固定为需求道具id，need为需求数量,当need固定时,
 */
export interface ITableConfigShopConsume {
    Type:number;

    ID:number;

    Need:number;
}

/**
 * 商店表数据
 */
export interface ITableConfigShop {

    /**
     * 商店ID
     */
    ID:number;

    /**
     * *编组
     */
    Group:number;

    /**
     * *商品名
     */
    Name:string;

    /**
     * *商店类型
     */
    ShopType:number;

    /**
     * *每日限购次数
     */
    Limit:number;

    /**
     * *限购次数
     */
    BuyLimit:number;

    /**
     * *折扣范围
     */
    Discount:number;

    /**
     * *购买参数
     */
    Consume:ITableConfigShopConsume;

    /**
     * *奖励
     */
    Reward:IItem[];

    /**
     * *是否首次双倍
     */
    IsDouble:number;

    /**
     * 关联付费订单id
     */
    OrderID:number;
}

// convertQualityName 品质转名称
export function convertQualityName(quality:number){
    switch (quality){
        case 1:
            return '普通-白';
        case 2:
            return '稀有-蓝';
        case 3:
            return '史诗-紫';
        case 4:
            return '传说-橙';
        default:
            return '未知';
    }
}