import { useState } from 'react';
import {ITableConfigItem, ITableConfigLanguage, ITableConfigShop} from "@/types/typings/config";
import {getGameConfigByKey} from "@/services/gameConfig";
import {IItem} from "@/types/typings/useData";
import {map} from "lodash";

export function useFetchConfigByKey<T>(fetchFunc: Function): [T[], (args?: any) => Promise<void>] {
  const [data, setData] = useState<T[]>([]);

  //  table 组件需要包装一个 key 字段
  const fetchData = async (args?: any) => {
    const res = await fetchFunc(args);
    setData(JSON.parse(res.data.data.value).Items);
  };
  return [data, fetchData];
}

/**
 * 获取所有物品信息
 */
export function useFetchItems ():[ITableConfigItem[], (() => Promise<ITableConfigItem[]>)] {
  const [data, setData] = useState<ITableConfigItem[]>([]);
  const fun = async ()=> {
    const itemRsp = await getGameConfigByKey("Item");
    const languageRsp = await  getGameConfigByKey("Language");
    const items = (JSON.parse(itemRsp.data.data.value!)).Items as ITableConfigItem[];
    const languages = (JSON.parse(languageRsp.data.data.value!)).Items as ITableConfigLanguage[];
    const result:ITableConfigItem[] = [];


    const lMap = new Map();
    for (const elem of languages){
      lMap.set(elem.Key, elem.Zh);
    }

    for (const elem of items) {
      const n = lMap.get(elem.Name);
      result.push({
        ...elem,
        Name:n,
      })
    }

    setData(result);
    return result;
  }
  return [data, fun];
}


/**
 * 获取所有商店信息
 */
export function useFetchShops ():[ITableConfigShop[], (() => Promise<ITableConfigShop[]>)] {
  const [data, setData] = useState<ITableConfigShop[]>([]);

  const fun = async ()=> {
    const itemRsp = await getGameConfigByKey("Item");
    const shopRsp = await getGameConfigByKey("Shop");
    const languageRsp = await  getGameConfigByKey("Language");

    const items = (JSON.parse(itemRsp.data.data.value!)).Items as ITableConfigItem[];
    const shops = (JSON.parse(shopRsp.data.data.value!)).Items as ITableConfigShop[];
    const languages = (JSON.parse(languageRsp.data.data.value!)).Items as ITableConfigLanguage[];
    const result:ITableConfigShop[] = [];




    const lMap = new Map();
    for (const elem of languages){
      lMap.set(elem.Key, elem.Zh);
    }

    const itemNameMap  = new Map<number,string>();
    for (const elem of items) {
      itemNameMap.set(elem.ID, lMap.get(elem.Name))
    }

    for (const elem of shops) {
      const n  = lMap.get(elem.Name);
      result.push({
        ...elem,
        Name:n?n:elem.Name,
        Consume:JSON.parse(elem.Consume as unknown as string),
        Reward:((e):IItem[]=>{
          e.forEach((v)=>{
            const o :any = v;
            const n = itemNameMap.get(v.ID);
            o['Name'] = n?n:v.ID.toString();
          })
          return e
        })(elem.Reward),
      })
    }

    setData(result);
    return result;
  }
  return [data, fun];
}
