import {
  SolutionOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  ToolOutlined,
  DesktopOutlined,
  PieChartOutlined,
  PlusOutlined,
  FileTextOutlined,
  FormOutlined,
  UserOutlined,
  MailOutlined,
  NotificationOutlined,
  CarryOutOutlined,
  PartitionOutlined,
  TableOutlined,
  GiftOutlined,
  ContactsOutlined
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import AccountPage from '@/pages/account/index';
import UserPage from '@/pages/user/index';
import VersionWebPage from '@/pages/version/web';
import VersionAndoridPage from '@/pages/version/android';
import TablePage from '@/pages/table/index';
import { isProdEnv } from '@/utils/request';
import SystemMailPage from '@/pages/mail/system';
import UserMailPage from '@/pages/mail/user';
import NoticePage from '@/pages/notice';

import ActivityWarOrderPage from '@/pages/activity/warOrder';
import ActivityQuestPage from '@/pages/activity/quest';
import ActivityDrawPage from '@/pages/activity/draw';
import ActivityShopPage from '@/pages/activity/shop';
import ActivityHeroTrialPage from "@/pages/activity/heroTrial";
import ActivityHeroStarUpgradePage from '@/pages/activity/heroStarUpgrade';

import SingleConfig from '@/pages/singleConfig/singleConfig';
import ToolPage from '@/pages/tool';
import RedisKeyPage from '@/pages/redisKey';
import HomePage from '@/pages/home';
import ServerListPage from '@/pages/server';
import { PermissionType } from '@/types';
import AdminPage from '@/pages/admin';
import RedeemCodePage from "@/pages/redeemCode";

type MenuItem = Required<MenuProps>['items'][number];

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], permission?: PermissionType): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    permission,
  } as any;
}

interface IConfigItem {
  select?: boolean;
  page?: any;
  key: string;
  label: string;
  icon?: any;
  subs?: IConfigItem[];
  permission?: PermissionType;
}

export let configs: IConfigItem[] = [
  {
    select: true,
    key: '100000',
    label: '面板',
    icon: <DashboardOutlined />,
    page: <HomePage />,
  },
  {
    key: '9999',
    label: '分服列表',
    icon: <PartitionOutlined />,
    page: <ServerListPage />,
  },

  {
    key: '2',
    label: '账户',
    icon: <SolutionOutlined />,
    page: <AccountPage />,
  },
  {
    key: '3',
    label: '玩家',
    icon: <UserOutlined />,
    page: <UserPage />,
  },
  {
    key: '4',
    label: '公告',
    icon: <NotificationOutlined />,
    page: <NoticePage />,
  },
  {
    key: '5',
    label: '邮件',
    icon: <MailOutlined />,
    page: <SystemMailPage />,
    subs: [
      {
        key: '5.1',
        label: '系统邮件',
        page: <SystemMailPage />,
      },
      {
        key: '5.2',
        label: '玩家邮件',
        page: <UserMailPage />,
      },
    ],
  },
  {
    key: '6',
    label: '活动',
    icon: <CarryOutOutlined />,
    subs: [
      {
        key: '8.1',
        label: '战令',
        page: <ActivityWarOrderPage />,
      },
      {
        key: '8.2',
        label: '任务',
        page: <ActivityQuestPage />,
      },
      {
        key: '8.3',
        label: '抽奖',
        page: <ActivityDrawPage />,
      },
      {
        key: '8.4',
        label: '英雄升星',
        page: <ActivityHeroStarUpgradePage />,
      },
      {
        key: '8.5',
        label: '限时商店',
        page: <ActivityShopPage />,
      },
      {
        key: '8.6',
        label: '英雄试炼',
        page: <ActivityHeroTrialPage />,
      },
    ],
  },
  {
    key: '7',
    label: '兑换码',
    icon: <GiftOutlined  />,
    page: <RedeemCodePage />
  },
  {
    key: '800',
    label: '配表',
    icon: <TableOutlined />,
    page: <TablePage />,
    permission: PermissionType.Planner,
  },
  {
    key: '801',
    label: '系统配置',
    icon: <FileTextOutlined />,
    page: <SingleConfig />,
    permission: PermissionType.Planner,
  },
  {
    key: '996',
    label: '版本',
    icon: <DesktopOutlined />,
    subs: [
      {
        key: '6.1',
        label: 'Web',
        page: <VersionWebPage />,
      },
      {
        key: '6.2',
        label: 'Android',
        page: <VersionAndoridPage />,
      },
    ],
    permission: PermissionType.Admin,
  },
  {
    key: '997',
    label: '工具',
    icon: <ToolOutlined />,
    page: <ToolPage />,
    permission: PermissionType.Admin,
  },
  {
    key: '998',
    label: 'Redis缓存',
    icon: <DatabaseOutlined />,
    page: <RedisKeyPage />,
    permission: PermissionType.Admin,
  },
  {
    key: '999',
    label: '后台账号',
    icon: <ContactsOutlined />,
    page: <AdminPage />,
    permission: PermissionType.Admin,
  }
];

// if (isProdEnv()) {
//   configs = configs.filter((item) => {
//     return item.label !== '测试';
//   });
// }

export const defaultSelectItem: any = {};
export const itemsMap: any = {};
const items: MenuItem[] = [];
export const itemsLabelMap: any = {};
function setMenuItem(v: IConfigItem) {
  itemsLabelMap[v.key] = v.label;
  itemsMap[v.key] = v;
  if (v.select) {
    defaultSelectItem.key = v.key;
    defaultSelectItem.breadcrumb = [v.label];
  }
}
configs.forEach((v) => {
  let subs: MenuItem[] = [];
  setMenuItem(v);
  if (v.subs?.length) {
    v.subs.forEach((i) => {
      setMenuItem(i);
      subs.push(getItem(i.label, i.key, i.icon, undefined, i.permission));
    });
  }
  items.push(getItem(v.label, v.key, v.icon, subs.length ? subs : undefined, v.permission));
});

export default items;
