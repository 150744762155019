import {
    IBaseResponse, ITableActivityHeroTrial, ITableActivityShop
} from "@/types";
import request from "@/utils/request";


interface IGetActivityListReq {
    page: number;
    pageSize: number;
    serverId:number;
    status:string;
    subType:number;
}

interface IGetActivityListRes {
    list: ITableActivityHeroTrial[];
    count: number;
}

/**
 * 获取活动数据列表
 * @param params 请求结构
 */
export const getList = async function (params: IGetActivityListReq) {
    return await request<IGetActivityListRes>(`/hmf/admin/activity_hero_trial/list`, {
        method: 'GET',
        params,
    });
};


/**
 * 新增活动
 * @param data 活动数据
 */
export const create = async function (data:ITableActivityHeroTrial) {
    return await request<IBaseResponse>(`/hmf/admin/activity_hero_trial/create`, {
        method: 'POST',
        data:data,
    });
};

/**
 * 更新活动
 * @param data 活动数据
 */
export const update = async function (data:ITableActivityHeroTrial) {
    return await request<IBaseResponse>(`/hmf/admin/activity_hero_trial/update`, {
        method: 'POST',
        data:data,
    });
};

/**
 * 删除活动
 * @param data 活动数据
 */
export const deleteRow = async function (data:any) {
    return await request<IBaseResponse>(`/hmf/admin/activity_hero_trial/delete`, {
        method: 'POST',
        data:{
            ...data
        },
    });
};