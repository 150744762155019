import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, Spin, Select, message } from 'antd';
import { AddAccount, AdminLogin, FetchAdmin, RegisterAccount } from '@/services/admin';
import store, { useStoreState } from './store';
import { clearAccessToken, getAccessToken, setAccessToken } from '@/utils/request';
import { PermissionType, PermissionTypeName } from '@/types';
import { Redirect } from 'react-router-dom';

const Register: React.FC = () => {
  const [toLogin, setToLogin] = useState(false);

  const onFinish = (values: any) => {
    console.log('Success:', values);
    RegisterAccount({
      screenname: values.screenname,
      username: values.username,
      pwd: values.password,
    }).then((res) => {
      message.success('注册成功');
      setToLogin(true);
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  if (toLogin) {
    return <Redirect to="/" />;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: 500,
        position: 'absolute',
        left: '50%',
        top: '30%',
        transform: 'translate(-50%,-50%)',
      }}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ width: 500 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item label="Screenname" name="screenname" rules={[{ required: true, message: 'Please input your screenname!' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Username" name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Register
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default Register;
