import React, {useEffect, useState} from "react";
import {useFetchItems} from "@/hooks";
import {Avatar, Badge, Button, InputNumber, Select, Space, Tooltip} from "antd";
import {convertQualityName} from "@/types/typings/config";

interface PackItemInputProps {
    value?:any;
    onChange?:(value:any[])=>void;
    multiple?:boolean;
    disabled?:boolean;
}

export const PackItemInput:React.FC<PackItemInputProps> =({value =[], onChange,multiple=true, disabled= false}) =>{
    const [items, fetchItems] = useFetchItems();
    const [rewardsValue, setRewardsValue] = useState<any[]>([]);
    const [selectedItems, setSelectedItems] = useState<any>({});
    const [selectedCount, setSelectedCount] = useState(1);
    if (value){
        try {
            value = JSON.parse(value);
        }catch (e:any){

        }

        value.forEach((elem:any, index:number, array:any[])=>{
            array[index] = {ID:elem.ID, Value:elem.Value}
        })
    }

    useEffect(() => {
        fetchItems()
            .then((its)=>{
            if (value){
                const map:any = {};
                for (let i = 0; i < its.length; i++) {
                    const elem = its[i];
                    map[elem.ID] = elem;
                }

                const newValue = value.map((v:any)=>{
                    const elem = map[v.ID];
                    if (elem){
                        return {
                            ...v,
                            Icon:elem.Icon,
                            Name:elem.ID + ':' + elem.Name+'('+convertQualityName(elem.Quality)+')',
                            Quality:elem.Quality,
                            Color:(()=>{
                                switch (elem.Quality){
                                    case 2:
                                        return 'blue';
                                    case 3:
                                        return 'purple'
                                    case 4:
                                        return 'orange'
                                    default:
                                        return '';
                                }})()
                        }
                    }
                    return {...v}
                });

                setRewardsValue(newValue);
            }
        });
    }, []);

    const updateRewardsValue = (data:any[])=>{
        setRewardsValue(data);

        onChange?.(data.map((elem: { ID: number; Value: number; })=> {return {ID:elem.ID, Value:elem.Value}}));
    }

    return (
        <>
            <div style={{marginBottom:6}}>
                <Space wrap>
                    {rewardsValue.map((item) => {
                        const icon = item.Icon?item.Icon:`icon_${item.ID}`;
                        return (
                            <Badge count={item.Value} size={"small"} key={item.ID} >
                                <Tooltip title={`${item.Name} x ${item.Value}`}>
                                    <Avatar shape="square" src={`/assets/icons/${icon}.png`} alt={`${item.Name} x ${item.Value}`}  style={{ borderColor:`${item.Color}`, borderWidth:2}} onClick={(e:any)=>{
                                        if (disabled) return;
                                        e.preventDefault();
                                        updateRewardsValue([...rewardsValue.filter((v) => v.ID !== item.ID)]);
                                    }}/>
                                </Tooltip>
                            </Badge>
                        );
                    })}
                </Space>
            </div>
            {
                disabled?'':<Space>
                    <Select
                        disabled={disabled}
                        placeholder={'请选择物品'}
                        showSearch={true}
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        style={{minWidth:200}}
                        options={(items || []).map((d) => ({
                            value: d.ID,
                            label: d.ID + ':' + d.Name+'('+convertQualityName(d.Quality)+')',
                            color: ((o)=>{switch (o.Quality){
                                case 2:
                                    return 'blue';
                                case 3:
                                    return 'purple'
                                case 4:
                                    return 'orange'
                                default:
                                    return '';
                            }})(d),
                            icon:d.Icon,

                        }))}
                        onChange={(value, option) => {
                            setSelectedItems(option);
                        }}
                        optionRender={(oriOption,info)=>{
                            const  data = oriOption.data;
                            const icon = data.icon?data.icon:`icon_${data.value}`;
                            return (
                                <Space>
                                    <Avatar shape="square" src={`/assets/icons/${icon}.png`} style={{ borderColor:`${data.color}`, borderWidth:2}}  />
                                    {data.label}
                                </Space>
                            )
                        }}
                    />
                    <InputNumber
                        onChange={(value) => {
                            setSelectedCount(value || 1);
                        }}
                        defaultValue={1}
                        min={1}
                        placeholder={'数量'}
                        disabled={disabled}
                    ></InputNumber>

                    <Button
                        disabled={disabled}
                        onClick={() => {
                            if (selectedItems.value && selectedCount) {
                                // 当前的数据
                                const cur = { ID: selectedItems.value, Name: selectedItems.label, Value: selectedCount, Color:selectedItems.color,Icon:selectedItems.icon };
                                let rewardsValueNew :any[] = [];

                                // 判断原先数据内是否存在当前选择的项
                                const has = !!rewardsValue.filter((v) => v.ID === cur.ID).length;

                                // 如果不是多选的模式,直接设置新的值
                                if (multiple) {
                                    // 克隆出新的值
                                    rewardsValueNew = rewardsValue.map((v) => {
                                        const nv = {...v}
                                        if (nv.ID === cur.ID) {
                                            nv.Value += cur.Value;
                                        }
                                        return nv;
                                    });

                                    if (!has) {
                                        rewardsValueNew.push(cur);
                                    }
                                }else {
                                    if (has) {
                                        rewardsValueNew = rewardsValue.filter((v)=>{
                                            const h = v.ID == cur.ID;
                                            if (h){
                                                v.Value += cur.Value;
                                            }
                                            return h;
                                        })
                                    }else {
                                        rewardsValueNew = [cur]
                                    }
                                }

                                updateRewardsValue(rewardsValueNew);
                            }
                        }}
                    >
                        添加
                    </Button>
                </Space>
            }
        </>
    );
}
