/**
 * 获取sign原始字符串
 * @param path
 * @param ts
 * @param secret
 * @returns
 */
export function getSignUrl(path: string, ts: string | number, secret: string) {
  return `${path}${ts}${secret}`;
}
