import React, {useEffect, useState} from 'react';
import { PlayCircleTwoTone , StopTwoTone, CheckCircleTwoTone,CloseCircleTwoTone } from '@ant-design/icons';
import {Button, DatePicker, Form, Input, InputNumber, Layout, message, Popover, Space, Switch, Table, Tag,} from 'antd';
import {EmailStatus, formatTimestamp, IServerInfo, SwitchStatus, ServerStatus, formatDate, FormType} from '@/types';
import {TableFuncs} from '@/components/tableFuncs';
import {FormModal} from '@/components/formModal';
import {useContentHeight, useFetchList} from '@/hooks';
import {createServer, getServerList, updateServer} from '@/services/server';
import dayjs from "dayjs";
import {isArray, isString, unset} from "lodash";

const ServerList: React.FC = () => {
  const [data, fetchList] = useFetchList<IServerInfo>(getServerList);
  const contentHeight = useContentHeight();
  const { RangePicker } = DatePicker;
  useEffect(() => {
    fetchList();
  }, []);

  return (
    <div>
      <TableFuncs>
        <Space size={[5, 5]} wrap>
          <FormModal
            btnText="新增分服"
            btnType="primary"
            onOK={(args) => {
                try {
                    const values = args.values;
                    values.begin_at = values.serve_at[0].utc().utcOffset(args.values.timezone,false).unix();
                    values.end_at = values.serve_at[1].utcOffset(args.values.timezone,false).unix();
                    unset(values,'serve_at');
                    createServer(values)
                        .then((res) => {
                            args.closeModal();
                            fetchList();
                            message.success('新建成功');
                        })
                        .catch((e) => {})
                        .finally(() => args.closeLoading());
                } catch (e: any) {
                    args.closeLoading();
                    message.error(e);
                }
            }}
          >
              <Form.Item label="名称" name="name" rules={[{ required: true, message: '分服名称不能为空' }]}>
                  <Input maxLength={100} minLength={1} />
              </Form.Item>
              <Form.Item label="描述" name="description" rules={[{ required: true, message: '分服描述不能为空' }]}>
                <Input.TextArea maxLength={255} minLength={1} rows={4} />
              </Form.Item>
              <Form.Item label="时区" name="timezone" initialValue={8} style={{display:'none'}} rules={[{ required: true,message: '时区必须填写' }]}>
                  <InputNumber max={12} min={-12}  />
              </Form.Item>
              <Form.Item label="服务时间" name="serve_at" tooltip={'跟随时区的时间'} rules={[{ required: true, message: '服务时间不能为空' }]}>
                  <RangePicker defaultValue={[dayjs(),dayjs().add(1,'year')]} showTime />
              </Form.Item>
              <Form.Item label="用户上限" name="user_limit" initialValue={1000} tooltip={'填“0”表示不限制'} rules={[{ required: true, message: '推荐用户上限必须大于0' }]}>
                  <InputNumber max={10000} min={0}  />
              </Form.Item>
              <Form.Item label="状态" name="status" initialValue={false}>
                  <Switch defaultChecked={false} />
              </Form.Item>
              <Form.Item label="新用户注册" name="registrable" initialValue={true}>
                  <Switch defaultChecked={true} />
              </Form.Item>

              {/*<Form.Item label="热门" name="is_hot" initialValue={false}>*/}
              {/*    <Switch defaultChecked={false} />*/}
              {/*</Form.Item>*/}

              <Form.Item label="新服" name="is_new" initialValue={true}>
                  <Switch defaultChecked={true} />
              </Form.Item>
              <Form.Item label="推荐" name="is_recommend" initialValue={true}>
                  <Switch defaultChecked={true} />
              </Form.Item>

          </FormModal>
        </Space>
      </TableFuncs>
      <Table
        rowKey={(data)=>{return data.id!.toString()}}
        columns={[
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width:60,
            },
            {
                title: '名称',
                dataIndex: 'name',
                key: 'name',
            },

            {
                title: '状态',
                key: 'running',
                width:120,
                render :(_,r)=>{
                    const nowUnix = dayjs().utcOffset(r.timezone!,false).unix();
                    const running = ((r.begin_at==0 && r.end_at == 0) || (r.begin_at! <= nowUnix && nowUnix <= r.end_at!)) && r.status == 1;
                    return (
                        <>
                            <Tag >{r.status==1?"启用":"停用"}</Tag>
                            <Tag color={running?'success':'error'} >{running?'开服中':'已关服'}</Tag>
                            {!(running&&r.end_at)?'':<Tag color={'warning'} >{dayjs.unix(r.end_at!).diff(dayjs(),'d')}天后关服</Tag>}
                        </>
                        );

                }
            },
            {
                title: '本地化服务时间',
                key: 'serve_at',
                width:200,
                render :(_,r)=>{
                    return (
                        <>
                            <Tag color={'success'} icon={<PlayCircleTwoTone twoToneColor={'#52c41a'} />}>{formatTimestamp(r.begin_at!)}</Tag>
                            <Tag color={'error'} icon={<StopTwoTone twoToneColor={'#ed3636'} />} >{formatTimestamp(r.end_at!)}</Tag>
                        </>
                    );
                }
            },
            // {
            //     title: '相对时差服务时间',
            //     key: 'serve_at_offset',
            //     width:200,
            //     render :(_,r)=>{
            //         return (
            //             <>
            //                 <Tag color={'success'} icon={<PlayCircleTwoTone twoToneColor={'#52c41a'} />}>{dayjs.unix(r.begin_at||0).utcOffset(r.timezone!,false).format('YYYY-MM-DD HH:mm:ss')}</Tag>
            //                 <Tag color={'error'} icon={<StopTwoTone twoToneColor={'#ed3636'} />} >{dayjs.unix(r.end_at||0).utcOffset(r.timezone!,false).format('YYYY-MM-DD HH:mm:ss')}</Tag>
            //             </>
            //         );
            //     }
            // },
            {
                title: '用户数量',
                dataIndex: 'user_count',
                key: 'user_count',
                width:90,

            },
            {
                title: '用户上限',
                dataIndex: 'user_limit',
                key: 'user_limit',
                width:90,
                render: (_, record) => {
                    return record.user_limit==0?'不限制':record.user_limit;
                },
            },
            {
                title: '推荐',
                dataIndex: 'is_recommend',
                key: 'is_recommend',
                width:60,
                render: (_, record) => {
                    return record.is_recommend==1?<CheckCircleTwoTone twoToneColor={'#52c41a'} />:<CloseCircleTwoTone twoToneColor={'#ed3636'} />;
                },
            },
            // {
            //     title: '热门',
            //     dataIndex: 'is_hot',
            //     key: 'is_hot',
            //     width:90,
            //     render: (_, record) => {
            //         return record.is_hot==1?<CheckCircleTwoTone twoToneColor={'#52c41a'} />:<CloseCircleTwoTone twoToneColor={'#ed3636'} />;
            //     },
            // },
            {
                title: '新服',
                dataIndex: 'is_new',
                key: 'is_new',
                width:60,
                render: (_, record) => {
                    return record.is_new==1?<CheckCircleTwoTone twoToneColor={'#52c41a'} />:<CloseCircleTwoTone twoToneColor={'#ed3636'} />;
                },
            },
            {
                title: '新用户注册',
                dataIndex: 'registrable',
                key: 'registrable',
                width:110,
                render: (_, record) => {
                    return record.registrable==1?<CheckCircleTwoTone twoToneColor={'#52c41a'} />:<CloseCircleTwoTone twoToneColor={'#ed3636'} />;
                },
            },
            // {
            //     title: '时区',
            //     dataIndex: 'timezone',
            //     key: 'timezone',
            //     width:60,
            // },
            {
                title: '最近更新时间',
                dataIndex: 'updated_at',
                key: 'updated_at',
                render:(_,record)=>{
                    return (<><Tag>{formatDate(record.updated_at!)}</Tag></>);
                }
            },
            {
                title: '创建时间',
                dataIndex: 'created_at',
                key: 'created_at',
                render:(_,record)=>{
                    return (<><Tag>{formatDate(record.created_at!)}</Tag></>);
                }
            },
            {
                title: '操作',
                key: 'operator',
                render:(_,record)=>{
                    return (
                        <Space size={[5, 5]} wrap>
                        <FormModal
                            btnText="更新"
                            onOK={(args) => {
                                const values = args.values;
                                values.begin_at = values.serve_at[0].utcOffset(args.values.timezone,false).unix();
                                values.end_at = values.serve_at[1].utcOffset(args.values.timezone,false).unix();
                                unset(values,'serve_at');

                                //@ 更新服务器状态
                                updateServer({
                                    id:record.id,
                                    ...values,
                                }).then((res) => {
                                    args.closeModal();
                                    fetchList();
                                    message.success('更新成功');
                                }).catch((res)=>{
                                    args.closeLoading();
                                });
                            }}
                        >
                            <Form.Item label="名称" name="name" initialValue={record.name} rules={[{ required: true, message: '分服名称不能为空' }]}>
                                <Input maxLength={100} minLength={1} />
                            </Form.Item>
                            <Form.Item label="描述" name="description" initialValue={record.description} rules={[{ required: true, message: '分服描述不能为空' }]}>
                                <Input.TextArea maxLength={255} minLength={1} rows={4} />
                            </Form.Item>
                            <Form.Item label="时区" name="timezone" initialValue={record.timezone} style={{display:'none'}} rules={[{ required: true,message: '时区必须填写' }]}>
                                <InputNumber max={12} min={-12}  />
                            </Form.Item>
                            <Form.Item label="服务时间" name="serve_at" tooltip={'跟随时区的时间'} initialValue={[dayjs.unix(record.begin_at!).utcOffset(record.timezone!,false),dayjs.unix(record.end_at!).utcOffset(record.timezone!,false)]} rules={[{ required: true, message: '服务时间不能为空' }]}>
                                <RangePicker showTime />
                            </Form.Item>
                            <Form.Item label="用户上限" name="user_limit" initialValue={record.user_limit} tooltip={'填“0”表示不限制'} rules={[{ required: true, message: '推荐用户上限必须大于0' }]}>
                                <InputNumber max={10000} min={0}  />
                            </Form.Item>
                            <Form.Item label="状态" name="status" initialValue={record.status==1}>
                                <Switch defaultChecked={record.status==1}  />
                            </Form.Item>
                            <Form.Item label="新用户注册" name="registrable" initialValue={record.registrable==1}>
                                <Switch defaultChecked={record.registrable==1} />
                            </Form.Item>
                            {/*<Form.Item label="热门" name="is_hot" initialValue={record.is_hot==1}>*/}
                            {/*    <Switch defaultChecked={record.is_hot==1} />*/}
                            {/*</Form.Item>*/}
                            <Form.Item label="新服" name="is_new" initialValue={record.is_new==1}>
                                <Switch defaultChecked={record.is_new==1} />
                            </Form.Item>
                            <Form.Item label="推荐" name="is_recommend" initialValue={record.is_recommend==1}>
                                <Switch defaultChecked={record.is_recommend==1} />
                            </Form.Item>

                        </FormModal>
                    </Space>
                    )
                }
            },
        ]}
        dataSource={data[0]}
        pagination={{ defaultPageSize:20, total:data[1], onChange:fetchList}}
        scroll={{ y: contentHeight - 150 }}
      />
    </div>
  );
};

export default React.memo(ServerList);
