import React, { useState } from 'react';
import { Modal } from 'antd';
import Scrollbars from 'react-custom-scrollbars';
import ReactJson from 'react-json-view';
import { Button } from 'antd';

export const JsonPreview: React.FC<{
  json?: string|any;
  btntxt?: string;
}> = ({ json, btntxt = false }) => {
  const [open, setOpen] = useState(false);
  if (!json) return <>-</>;

  let jsonData = json;
  try {
    jsonData = JSON.parse(json);
  }catch (e){

  }
  return (
    <>
      {btntxt ? (
        <Button
          onClick={() => {
            setOpen(true);
          }}
        >
          {btntxt}
        </Button>
      ) : (
        <a
          href="#"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpen(true);
          }}
        >
          查看 Json
        </a>
      )}

      <Modal footer={<></>} width={800} open={open} onCancel={() => setOpen(false)} destroyOnClose>
        <div style={{ height: 500 }}>
          <Scrollbars>
            <ReactJson groupArraysAfterLength={1000} name={false} enableClipboard={false} displayDataTypes={false} src={jsonData} />
          </Scrollbars>
        </div>
      </Modal>
    </>
  );
};
