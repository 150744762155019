import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, message } from 'antd';

export const CopyText: React.FC<{ content?: string; btnText?: string }> = ({ content, btnText = '复制链接' }) => {
  return (
    <>
      {content ? (
        <CopyToClipboard
          text={content}
          onCopy={() => {
            message.success('复制成功');
          }}
        >
          <Button>{btnText}</Button>
        </CopyToClipboard>
      ) : null}
    </>
  );
};
