import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, Spin } from 'antd';
import { AdminLogin, FetchAdmin } from '@/services/admin';
import store from './store';
import { clearAccessToken, getAccessToken, setAccessToken } from '@/utils/request';

export const logout = () => {
  clearAccessToken();
  store.getActions().userModel.logout();
};

const onFinish = (values: any) => {
  console.log('Success:', values);
  AdminLogin({
    username: values.username,
    pwd: values.password,
  }).then((res) => {
    const data = res.data;
    setAccessToken(data.accessToken, values.remember);
    store.getActions().userModel.login(data);
  });
};

const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo);
};

const Login: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (getAccessToken()) {
      FetchAdmin()
        .then((res) => {
          store.getActions().userModel.login(res.data);
          setIsLoading(false);
        })
        .catch((e) => {
          logout();
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: 500,
        position: 'absolute',
        left: '50%',
        top: '30%',
        transform: 'translate(-50%,-50%)',
      }}
    >
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ width: 500 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="Username" name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
            <Input.Password />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};
export default Login;
