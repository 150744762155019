import { IBaseResponse, ITableAccount, ITableAccountForbidden } from '@/types';
import request from '@/utils/request';

interface IGetAccountListReq {
  page: number;
  pageSize: number;
  accountId:number;
  openid:string;
  unionid:string;
  account:string;
  platform:string;
  channel:string;
}
interface IGetUserListRes {
  list: ITableAccount[];
  count: number;
}
export const getList = async function (params: IGetAccountListReq) {
  return await request<IGetUserListRes>(`/hmf/admin/account/list`, {
    method: 'GET',
    params,
  });
};

interface IGetAccountDataReq {
  accountId: number;
}
interface IGetAccountForbiddenListRes {
  list: ITableAccountForbidden[];
}
export const accountForbiddenList = async function (params: IGetAccountDataReq) {
  return await request<IGetAccountForbiddenListRes>(`/hmf/admin/account/forbiddenList`, {
    method: 'GET',
    params,
  });
};

export const forbiddenAccount = async function (data: any) {
  return await request<IBaseResponse>(`/hmf/admin/account/forbidden`, {
    method: 'POST',
    data,
  });
};


export const kickOutAccount = async function (data: any) {
  return await request<IBaseResponse>(`/hmf/admin/account/kickOut`, {
    method: 'POST',
    data,
  });
};
