import { useState } from 'react';

export function useFetchListWrapper<T>(fetchFunc: Function): [T[], (args?: any) => Promise<void>] {
  const [data, setData] = useState<T[]>([]);

  //  table 组件需要包装一个 key 字段
  const fetchData = async (args?: any) => {
    const res = await fetchFunc(args);
    // .then((res: any) => {
    // res.data.list.forEach((item: any) => {
    //   item.key = item.id;
    // });
    setData(res.data.list);
    // });
  };

  return [data, fetchData];
}

export function useFetchListWithCount<T>(fetchFunc: Function): [[T[], number], (args?: any) => void] {
  const [data, setData] = useState<[T[], number]>([[], 0]);

  //  table 组件需要包装一个 key 字段
  const fetchData = (args?: any) => {
    fetchFunc(args).then((res: any) => {
      // res.data.list.forEach((item: any) => {
      //   item.key = item.id;
      // });
      const { list, count } = res.data;
      setData([list, count]);
    });
  };

  return [data, fetchData];
}

export function useFetchList<T>(fetchFunc: Function): [[T[], number], (...args: any) => void] {
  const [data, setData] = useState<[T[], number]>([[], 0]);

  //  table 组件需要包装一个 key 字段
  const fetchData = (...args: any) => {
    fetchFunc.apply(this,args).then((res: any) => {
      // res.data.list.forEach((item: any) => {
      //   item.key = item.id;
      // });
      const { list, total } = res.data;
      setData([list, total]);
    });
  };

  return [data, fetchData];
}
