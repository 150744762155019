import {
    IBaseResponse, ITableActivityQuest
} from "@/types";
import request from "@/utils/request";


interface IGetActivityListReq {
    page: number;
    pageSize: number;
    serverId:number;
    status:string;
    subType:number;
}
interface IGetActivityListRes {
    list: ITableActivityQuest[];
    count: number;
}

/**
 * 获取活动数据列表
 * @param params 请求结构
 */
export const getList = async function (params: IGetActivityListReq) {
    return await request<IGetActivityListRes>(`/hmf/admin/activity_quest/list`, {
        method: 'GET',
        params,
    });
};


/**
 * 新增活动
 * @param data 活动数据
 */
export const create = async function (data:ITableActivityQuest) {
    return await request<IBaseResponse>(`/hmf/admin/activity_quest/create`, {
        method: 'POST',
        data:data,
    });
};

/**
 * 更新活动
 * @param data 活动数据
 */
export const update = async function (data:ITableActivityQuest) {
    return await request<IBaseResponse>(`/hmf/admin/activity_quest/update`, {
        method: 'POST',
        data:data,
    });
};

/**
 * 删除活动
 * @param data 活动数据
 */
export const deleteRow = async function (data:any) {
    return await request<IBaseResponse>(`/hmf/admin/activity_quest/delete`, {
        method: 'POST',
        data:{
            ...data
        },
    });
};