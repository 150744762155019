import request from '@/utils/request';
import {IBaseListResponse, IBaseResponse, IServerInfo} from '@/types';


/**
 * 获取服务端列表
 * @param page 页码
 * @param pageSize 每页的数量
 */
export const getServerList = async function (page:number, pageSize:number) {
    return await request<IBaseListResponse<IServerInfo>>(`/hmf/admin/server/list`, {
        method: 'GET',
        params: {
            page,
            pageSize,
        },
    });
};


/**
 * 新增分服
 * @param data 分服的配置
 */
export const createServer = async function (data:IServerInfo) {
    return await request<IBaseResponse>(`/hmf/admin/server/create`, {
        method: 'POST',
        data:data,
    });
};

/**
 * 更新分服
 * @param data 分服的配置
 */
export const updateServer = async function (data:IServerInfo) {
    return await request<IBaseResponse>(`/hmf/admin/server/update`, {
        method: 'POST',
        data:data,
    });
};

/**
 * 获取服务端列表
 * @param page 页码
 * @param pageSize 每页的数量
 */
export const getAllServerList = async function () {
    return await request<IBaseListResponse<IServerInfo>>(`/hmf/admin/server/allList`, {
        method: 'GET',
    });
};