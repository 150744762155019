import {
    IBaseResponse,
    ITableRedeemCode,
} from "@/types";
import request from "@/utils/request";
import {IItem} from "@/types/typings/useData";


interface IRedeemCodeListReq {
    page: number;
    pageSize: number;
    type:number;
}
interface IRedeemCodeListRsp {
    list: ITableRedeemCode[];
    count: number;
}

/**
 * 获取兑换码数据列表
 * @param params 请求结构
 */
export const listCode = async function (params: IRedeemCodeListReq) {
    return await request<IRedeemCodeListRsp>(`/hmf/admin/redeem_code/list`, {
        method: 'GET',
        params,
    });
};


/**
 * 新增兑换码
 * @param data 活动数据
 */
export const addCode = async function (data:ITableRedeemCode) {
    return await request<IBaseResponse>(`/hmf/admin/redeem_code/add`, {
        method: 'POST',
        data:data,
    });
};


export interface IRedeemCodeGenReq {
    code_count:number,
    code_len:number,
    remark:string,
    count:string,
    use_limit:number,
    start_at:string,
    end_at:string,
    rewards:IItem[]
}

/**
 * 生成兑换码
 * @param data 活动数据
 */
export const genCode = async function (data:IRedeemCodeGenReq) {
    return await request<IBaseResponse>(`/hmf/admin/redeem_code/gen`, {
        method: 'POST',
        data:data,
    });
};


/**
 * 审核数据
 * @param data 活动数据
 */
export const reviewCode = async function (data: { id:number, status:number }) {
    return await request<IBaseResponse>(`/hmf/admin/redeem_code/review`, {
        method: 'POST',
        data:data,
    });
};

/**
 * 删除数据
 * @param data
 */
export const deleteCode = async function (data: { id:number }) {
    return await request<IBaseResponse>(`/hmf/admin/redeem_code/delete`, {
        method: 'POST',
        data:data,
    });
};

/**
 * 导入
 * @param data 密钥配置列表
 */
export const importCode = async function (data:ITableRedeemCode[]) {
    return await request<IBaseResponse>(`/hmf/admin/redeem_code/import`, {
        method: 'POST',
        data:data,
    });
};