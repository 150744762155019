import React, { useEffect, useRef, useState } from 'react';
import { TableFuncs } from '@/components/tableFuncs';
import { Table, Button, Tag, message, Form, Input, Space, Select, InputNumber } from 'antd';
import {clearUserData, forbiddenUser, getList, kickOutUser} from '@/services/user';
import { useFetchListWrapper, useContentHeight, useFetchListWithCount, useFetchList, useFetchItems } from '@/hooks';
import { EmailType, formatDate, FORMATE_DATE_TIME, formatSqlData, formatTimestamp, getDateTimestamp, IServerInfo, ITableAccount, ITableUser, StatusType, StatusTypeName } from '@/types';
import { UserGameData } from '@/components/userGameData';
import { UserForbidden } from '@/components/userForbidden';
import { FormModal } from '@/components/formModal';
import { DatePicker } from 'antd';
import { cloneDeep, isArray, toNumber } from 'lodash';
import { createUserMail } from '@/services/email';
import { useStoreState } from '@/entry/store';
import { getAllServerList } from '@/services/server';
import { sleep } from '@/utils/tools';
import {PackItemInput} from "@/components/packItemInput";
// const { Search } = Input;

const UserPage: React.FC = () => {
  const [serverList, fetchAllServer] = useFetchListWrapper<IServerInfo>(getAllServerList);
  const [userList, fetchUserList] = useFetchListWithCount<ITableUser>(getList);
  const uid = useStoreState((state) => state.userModel.uid);

  const contentHeight = useContentHeight();

  const searchServerId = useRef(0);
  const searchUid = useRef(0);
  const searchAccountId = useRef(0);
  const searchNickname = useRef('');

  useEffect(() => {
    fetchUserList();
    fetchAllServer();
  }, []);

  const fetchList = (page: number = 0, pageSize: number = 50) => {
    const param: { [key: string]: any } = { page, pageSize };

    if (searchServerId.current) {
      param['serverId'] = searchServerId.current;
    }
    if (searchUid.current) {
      param['uid'] = searchUid.current;
    }
    if (searchAccountId.current) {
      param['accountId'] = searchAccountId.current;
    }

    if (searchNickname.current) {
      param['nickname'] = searchNickname.current;
    }
    fetchUserList(param);
  };

  return (
    <div>
      <TableFuncs>
        <Space size={[5, 5]} wrap>
          <Select
            style={{ width: 200 }}
            size={'large'}
            showSearch
            defaultValue={0}
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            options={(serverList || []).map((d) => ({
              value: d.id,
              label: d.name,
            }))}
            onChange={(val: any, option) => {
              searchServerId.current = val;
            }}
          />
          <InputNumber
            style={{ width: 200 }}
            size={'large'}
            key={'uidSearcher'}
            placeholder={'角色ID'}
            min={0}
            onChange={(val: any) => {
              searchUid.current = val;
            }}
          />
          <Input
            style={{ width: 200 }}
            size={'large'}
            key={'nicknameSearcher'}
            placeholder={'角色昵称'}
            allowClear
            onChange={(evt) => {
              searchNickname.current = evt.target.value;
            }}
          />
          <InputNumber
            style={{ width: 200 }}
            size={'large'}
            key={'accountIdSearcher'}
            placeholder={'账户ID'}
            min={0}
            onChange={(val: any) => {
              searchAccountId.current = val;
            }}
          />
          <Button
            size={'large'}
            type={'primary'}
            onClick={(evt) => {
              fetchList();
            }}
          >
            查询
          </Button>
        </Space>
      </TableFuncs>
      <Table
        rowKey={(data) => {
          return data.id!.toString();
        }}
        columns={[
          {
            title: '角色ID',
            key: 'id',
            dataIndex: 'id',
            width: 100,
          },
          {
            title: '账户ID',
            key: 'account_id',
            dataIndex: 'account_id',
            width: 100,
          },
          {
            title: '分服ID',
            key: 'server_id',
            dataIndex: 'server_id',
            width: 80,
          },
          {
            title: '状态',
            key: 'status',
            render: (_, record) => {
              return <>{StatusTypeName[`CN${record.status || 1}`]}</>;
            },
            width: 60,
          },
          {
            title: '昵称',
            key: 'nickname',
            render: (_, record) => {
              if (record.nickname) {
                return record.nickname;
              }
              return '[未设置昵称]';
            },
          },
          {
            title: '更改昵称时间',
            key: 'rename_time',
            render: (_, record) => {
              if (record.rename_time) {
                return formatTimestamp(record.rename_time!);
              }
              return '[未设置昵称]';
            },
          },
          {
            title: '登陆时间',
            key: 'login_at',
            render: (_, record) => {
              return formatSqlData(record.login_at!);
            },
          },
          {
            title: '最后更新时间',
            key: 'updated_at',
            render: (_, record) => {
              return formatSqlData(record.updated_at!);
            },
          },
          {
            title: '创建时间',
            key: 'created_at',
            render: (_, record) => {
              return formatSqlData(record.created_at!);
            },
          },

          {
            title: '操作',
            key: 'action',
            render: (_, record) => {
              return (
                <Space size={[5, 5]} wrap>
                  <UserGameData uid={record.id} />
                  <UserForbidden uid={record.id} />
                  <FormModal
                    btnText="发送邮件"
                    onOK={async (args) => {
                      try {
                        const ExpiryTime = getDateTimestamp(formatDate(args.values.ExpiryTime.$d, FORMATE_DATE_TIME));
                        let uids = args.values.uids
                          .replace(/，/gi, ',')
                          .replace(/、/gi, ',')
                          .split(',')
                          .map((v: string) => v.trim());
                        uids = Array.from(new Set(uids));
                        delete args.values.uids;
                        for (let i = 0; i < uids.length; i++) {
                          const uid = toNumber(uids[i]);
                          if (uid) {
                            const data = {
                              ...args.values,
                              eid: 0,
                              uid,
                              ExpiryTime,
                              Type: EmailType.Unread,
                            };
                            message.info(`发送[${uid}]邮件`);
                            await createUserMail(data);
                            await sleep(200);
                          }
                        }

                        fetchList();
                        args.closeModal();
                        args.closeLoading();
                      } catch (e) {

                        args.closeLoading();
                        message.error('奖励格式错误');
                      }
                    }}
                  >
                    <Form.Item label="角色" initialValue={`${record.id}`} name="uids" rules={[{ required: true, message: '输入角色id，多个id格式 [uid],[uid]' }]}>
                      <Input.TextArea minLength={1} rows={2} />
                    </Form.Item>
                    <Form.Item label="标题" name="Title" rules={[{ required: true, message: '输入标题' }]}>
                      <Input maxLength={100} minLength={1} />
                    </Form.Item>
                    <Form.Item label="内容" name="Content" rules={[{ required: true, message: '输入内容' }]}>
                      <Input.TextArea maxLength={2000} rows={5} minLength={1} />
                    </Form.Item>
                    <Form.Item label="过期" name="ExpiryTime" rules={[{ required: true, message: '输入过期时间' }]}>
                      <DatePicker showTime />
                    </Form.Item>
                    <Form.Item label="奖励" name="Rewards" initialValue={[]} rules={[{ required: false, message: '输入奖励' }]}>
                      <PackItemInput />
                    </Form.Item>
                  </FormModal>
                  {record.status === StatusType.active ? (
                    <>
                      <FormModal
                        btnText="封禁玩家"
                        danger={true}
                        btnType={"primary"}
                        onOK={(args) => {
                          forbiddenUser({
                            isEnable: false,
                            uid: record.id,
                            create_by: uid,
                            days: args.values.days,
                          })
                            .then((res) => {
                              message.success('设置成功');
                              args.closeModal();
                              fetchList();
                            })
                            .catch((e) => {
                              message.error('设置失败');
                            })
                            .finally(() => args.closeLoading());
                        }}
                      >
                        <Form.Item label="天数" name="days" rules={[{ required: true, message: '输入封禁天数' }]}>
                          <Input maxLength={100} minLength={1} />
                        </Form.Item>
                      </FormModal>

                      <Button danger={true} type={"dashed"}
                          onClick={() => {
                            kickOutUser({
                              uid: record.id,
                            })
                                .then(() => {
                                  message.success('将玩家踢下线成功');
                                })
                                .catch(() => {
                                  message.error('将玩家踢下线失败');
                                });
                          }}
                      >
                        踢  下  线
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          forbiddenUser({
                            isEnable: true,
                            uid: record.id,
                            create_by: uid,
                          })
                            .then(() => {
                              message.success('设置成功');
                              fetchList();
                            })
                            .catch(() => {
                              message.error('设置失败');
                            });
                        }}
                      >
                        解除封禁
                      </Button>
                    </>
                  )}
                </Space>
              );
            },
          },
        ]}
        dataSource={userList[0]}
        pagination={{ defaultPageSize: 50, total: userList[1], onChange: fetchList }}
        scroll={{ y: contentHeight - 150 }}
      />
    </div>
  );
};

export default UserPage;
