import React, {useEffect, useRef, useState} from 'react';
import {
    Alert,
    Button,
    DatePicker,
    Form,
    Input,
    InputNumber,
    message, Modal,
    Select,
    Space,
    Tag, Upload, UploadProps,
} from 'antd';

import { Table } from 'antd';
import {DaytsUTC, formatDate, FORMATE_DATE_TIME, ITableRedeemCode} from '@/types';
import {useFetchListWrapper, useContentHeight, useFetchItems, useFetchList, useFetchListWithCount} from '@/hooks';
import { FormModal,IFormOK } from '@/components/formModal';

import { listCode as getList,addCode , genCode, importCode, reviewCode, deleteCode, IRedeemCodeGenReq} from "@/services/redeemCode";
import {TableFuncs} from "@/components/tableFuncs";
import {PackItemInput} from '@/components/packItemInput'
import {JsonPreview} from "@/components/jsonPreview";
import {isString} from "lodash";
import {XlsxUploader} from "@/components/xlsxUploader";
import {Dayts} from "@/types/utils/dateKit";
import dayjs from "dayjs";


const RedeemCodeView:React.FC<{value?:any, disable?:boolean}> = ({value = {}, disable=false}) => {
    const initialValue = {...value}
    if (!initialValue.time_range){
        initialValue.time_range = [
            dayjs(dayjs().format('YYYY-MM-DDT00:00:00Z')),
            dayjs(dayjs().add(1,'month').subtract(1,'day').format('YYYY-MM-DD 23:59:59'))
        ];

        if (initialValue.start_at){
            initialValue.time_range[0] = dayjs(initialValue.start_at);
        }
        if (initialValue.end_at){
            initialValue.time_range[1] = dayjs(initialValue.end_at);
        }
    }

    return (
        <>
            <Form.Item label={'兑换码'} name={'code'} rules={[{ required: true, message: '必选项' },{ pattern:/^[A-Za-z0-9]{6,15}$/,message:'必须在6~15个字符之间,只允许 英文字母跟数字' }]} initialValue={initialValue.code}>
                <Input minLength={6} maxLength={15} disabled={disable} />
            </Form.Item>

            <Form.Item label={'备注'} name={'remark'} initialValue={initialValue.remark}>
                <Input disabled={disable} />
            </Form.Item>

            <Form.Item label={'发放人次'} name={'count'} initialValue={initialValue.count||100000} rules={[{ required: true, message: '必选项' }]}>
                <InputNumber min={1} disabled={disable} />
            </Form.Item>

            <Form.Item label={'每人可领取次数'} name={'use_limit'} initialValue={initialValue.use_limit||1} rules={[{ required: true, message: '必选项' }]}>
                <InputNumber min={1} disabled={disable} />
            </Form.Item>

            <Form.Item label="有效期" name={'time_range'} initialValue={initialValue.time_range} rules={[{ required: true, message: '必选项' }]}>
                <DatePicker.RangePicker showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss" disabled={disable}/>
            </Form.Item>

            <Form.Item label="奖励列表" name={'rewards'} initialValue={initialValue.rewards} rules={[{ required: true, message: '输入奖励' }]}>
                <PackItemInput disabled={disable}/>
            </Form.Item>
        </>
    )
}

const RedeemCodePage: React.FC = () => {
    const a = dayjs(dayjs().format('YYYY-MM-DDT00:00:00Z')).format();

    const [data, fetchRedeemCodeList] = useFetchListWithCount<ITableRedeemCode>(getList);
    const contentHeight = useContentHeight();
    const defaultPageSize = 20;
    const searchStatus = useRef(undefined);
    const searchPage = useRef(0);
    const searchPageSize = useRef(defaultPageSize);

    useEffect(() => {
        fetchList();
    }, []);

    const fetchList = (page?: number , pageSize?: number) => {
        if (page){
            searchPage.current = page;
        }
        if (pageSize){
            searchPageSize.current = pageSize;
        }

        const param: { [key: string]: any } = { page:searchPage.current, pageSize:searchPageSize.current };

        if (searchStatus.current != undefined){
            param['status'] = searchStatus.current;
        }

        fetchRedeemCodeList(param);
    };

    // 解析添加数据
    const parseAddData = (values:any) => {
        const data  = {
            code:values.code,
            count:values.count,
            use_limit:values.use_limit,
            remark:values.remark,
            rewards:values.rewards.map((elem: { ID: number; Value: number; })=> {return {ID:elem.ID,Value:elem.Value}}),
            start_at:values.time_range[0].format(),
            end_at:values.time_range[1].format(),
        }
       return data as ITableRedeemCode ;
    }

    // 添加兑换码
    const addRedeemCode = (args:IFormOK) => {
        try {
            const values = parseAddData(args.values);
            addCode(values)
                .then((res) => {
                    if (!res.data.result){
                        message.error('添加失败');
                        return;
                    }
                    args.closeModal();
                    fetchList();
                    message.success('添加成功');
                })
                .catch((e) => {})
                .finally(() => args.closeLoading());
        } catch (e: any) {
            args.closeLoading();
            message.error(e.toString());
        }
    }

    // 解析生成数据
    const parseGenData = (values:any) => {
        const data  = {
            code_count:values.code_count,
            code_len:values.code_len,
            count:values.count,
            use_limit:values.use_limit,
            remark:values.remark,
            rewards:values.rewards.map((elem: { ID: number; Value: number; })=> {return {ID:elem.ID,Value:elem.Value}}),
            start_at:values.time_range[0].format(),
            end_at:values.time_range[1].format(),
        }
        return data as IRedeemCodeGenReq ;
    }

    // 生成兑换码
    const genRedeemCode = (args:IFormOK) => {
        try {
            const values = parseGenData(args.values);
            genCode(values)
                .then((res) => {
                    if (!res.data.result){
                        message.error('生成失败');
                        return;
                    }
                    args.closeModal();
                    fetchList();
                    message.success('生成成功');
                })
                .catch((e) => {})
                .finally(() => args.closeLoading());
        } catch (e: any) {
            args.closeLoading();
            message.error(e.toString());
        }
    }

    // 审核数据
    const reviewFn = (id:number,status:number)=>{
        try {
            reviewCode({id,status})
                .then((res) => {
                    if (res.data.result){
                        message.success(`审核成功`);
                        fetchList();
                    }else{
                        message.error(`审核失败`);
                    }
                })
                .catch((e) => {
                    message.error(`审核失败`,e.toString());
                }).finally(()=>{fetchList();})
        } catch (e: any) {
            message.error(e.toString());
        }
    }

    // 删除兑换码
    const deleteRedeemCode = (args:IFormOK) => {
        try {
            const data = args.values;

            if (!data.id){
                message.error('记录ID不能为空');
                args.closeLoading();
                return;
            }
            if (!data.code_input){
                message.error('未输入兑换码');
                args.closeLoading();
                return;
            }

            if (data.code != data.code_input){
                message.error('输入的兑换码不正确');
                args.closeLoading();
                return;
            }

            deleteCode({id:data.id})
                .then((res) => {
                    if (!res.data.result){
                        message.error('删除失败');
                        return;
                    }
                    args.closeModal();
                    fetchList();
                    message.success('删除成功');
                })
                .catch((e) => {})
                .finally(() => args.closeLoading());
        } catch (e: any) {
            args.closeLoading();
            message.error(e.toString());
        }
    }

    const codeRegexp = /^[A-Za-z0-9]{6,15}$/;

    const statusList = [
        {
            value:-1,
            label:'所有',
        },
        {
            value:0,
            label:'待审核',
        },
        {
            value:1,
            label:'已启用',
        },
        {
            value:2,
            label:'已驳回',
        },
        {
            value:3,
            label:'已删除',
        }
    ];

    // 解析生成数据
    const parseImportData = (values:any) => {
        const result:ITableRedeemCode[] = [];
        for (let i = 0; i < values.length; i++) {
            const elem = values[i];
            if (!codeRegexp.test(elem.code)){
                console.log('不合法',elem);
                continue;
            }
            if (isString(elem.rewards)){
                elem.rewards = JSON.parse(elem.rewards);
            }
            const data:ITableRedeemCode  = {
                code:elem.code,
                count:elem.count,
                use_limit:elem.use_limit,
                remark:elem.remark,
                rewards:elem.rewards.map((elem: { ID: number; Value: number; })=> {return {ID:elem.ID,Value:elem.Value}}),
                start_at:elem.start_at,
                end_at:elem.end_at
            }
            result.push(data);
        }
        return result;
    }

    return (
        <div>
            <TableFuncs>
                <Space direction={"vertical"}>
                    <Space size={[5, 5]} wrap>
                        <FormModal
                            title={'添加兑换码'}
                            btnText="添加兑换码"
                            btnType="primary"
                            onOK={addRedeemCode}
                            layout={'vertical'}
                        >
                           <RedeemCodeView />
                        </FormModal>

                        <FormModal
                            title={'生成兑换码'}
                            btnText="生成兑换码"
                            btnType="primary"
                            onOK={genRedeemCode}
                            layout={'vertical'}
                        >
                            <Form.Item label={'生成数量'} name={'code_count'} initialValue={1} rules={[{ required: true, type:'number', min:1,  message: '必选项' }]}>
                                <InputNumber min={1} />
                            </Form.Item>
                            <Form.Item label={'密钥长度'} name={'code_len'} initialValue={6} rules={[{ required: true, type:'number', min:6, max:15, message: '必选项' }]}>
                                <InputNumber min={6} max={15} />
                            </Form.Item>

                            <Form.Item label={'备注'} name={'remark'}>
                                <Input  />
                            </Form.Item>

                            <Form.Item label={'发放人次'} name={'count'} initialValue={1000000} rules={[{ required: true, message: '必选项' }]}>
                                <InputNumber min={1}  />
                            </Form.Item>

                            <Form.Item label={'每人可领取次数'} name={'use_limit'} initialValue={1} rules={[{ required: true, message: '必选项' }]}>
                                <InputNumber min={1}  />
                            </Form.Item>

                            <Form.Item label="有效期" name={'time_range'} initialValue={[
                                dayjs(dayjs().format('YYYY-MM-DD 00:00:00')),
                                dayjs(dayjs().add(1,'month').subtract(1,'day').format('YYYY-MM-DD 23:59:59'))
                            ]} rules={[{ required: true, message: '必选项' }]}>
                                <DatePicker.RangePicker showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss" />
                            </Form.Item>

                            <Form.Item label="奖励列表" name={'rewards'} rules={[{ required: true, message: '输入奖励' }]}>
                                <PackItemInput />
                            </Form.Item>
                        </FormModal>


                        <XlsxUploader btnTxt={'导入兑换码'} onChange={(_,data)=>{
                            try {
                                const values = parseImportData(data);
                                if (values.length == 0){
                                    message.error('没有兑换码需要导入');
                                    return;
                                }
                                importCode(values)
                                    .then((res) => {
                                        const data:any = res.data;
                                        message.success(`导入完成:\n成功:${data['success'].length}条\n失败:${data['failure'].length}条`);
                                        fetchList();
                                    })
                                    .catch((e) => {})
                            } catch (e: any) {
                                message.error(e.toString());
                            }
                        }} />
                        <Button type={'link'} href={'./assets/兑换码模板.xlsx'} >下载兑换码表格模板</Button>
                    </Space>
                    <Space size={[5, 5]} wrap>
                        <Select
                            style={{width:120}}
                            size={'large'}
                            defaultValue={-1}
                            options={statusList}
                            onChange={(val: any, option) => {
                                searchStatus.current = val;
                            }}
                        />

                        <Button
                            size={'large'}
                            type={'primary'}
                            onClick={(evt) => {
                                fetchList();
                            }}
                        >
                            查询
                        </Button>
                    </Space>
                </Space>
            </TableFuncs>
            <Table
                rowKey="id"
                columns={[
                    {
                        title: "#",
                        key: '#',
                        width: 80,
                        dataIndex: 'id',
                        fixed:'left',
                    },
                    {
                        title: '兑换码',
                        key: 'code',
                        dataIndex: 'code',
                        fixed:'left',
                        width:180,
                    },
                    {
                        title: '状态',
                        key: 'status',
                        render:(_,r)=>{
                            if (r.status == 0){
                                return (<Tag color={"error"} >待审核</Tag>);
                            }

                            if (r.status == 1){
                                return (<Tag color={"success"} >启用中</Tag>);
                            }

                            if (r.status == 2){
                                return (<Tag color={"yellow"} >已驳回</Tag>);
                            }

                            if (r.status == 3){
                                return (<Tag color={"error"} >已删除</Tag>);
                            }
                        },
                        fixed:'left',
                        width:100,
                    },
                    {
                        title: '发放人次',
                        key: 'count',
                        dataIndex: 'count',
                        fixed:'left',
                        width:120,
                    },

                    {
                        title: '类型',
                        key: 'type',
                        render:(_,r)=>{
                            switch (r.type){
                                case 1:
                                    return '生成';
                                case 2:
                                    return '导入';
                                default:
                                    return '添加';
                            }
                        },
                        width:100,
                    },

                    {
                        title: '备注',
                        key: 'remark',
                        dataIndex: 'remark',
                        width:150,
                    },
                    {
                        title: '已领取人次',
                        key: 'used_count',
                        dataIndex: 'used_count',
                        width:80,
                    },
                    {
                        title: '每人可领取次数',
                        key: 'use_limit',
                        dataIndex: 'use_limit',
                        width:100,
                    },
                    {
                        title: '开启时间',
                        key: 'start_at',
                        dataIndex:'start_at',
                        width:200,
                        render:(_,r)=>{
                            return (
                                <Tag>{dayjs(r.start_at).format()}</Tag>
                            );
                        }
                    },
                    {
                        title: '到期时间',
                        key:'end_at',
                        dataIndex:'end_at',
                        width:200,
                        render:(_,r)=>{
                            return (
                                <Tag>{dayjs(r.end_at).format()}</Tag>
                            );
                        }
                    },
                    {
                        title: '创建时间',
                        key: 'created_at',
                        dataIndex: 'created_at',
                        width:200,
                        render:(_,r)=>{
                            return (
                                <Tag>{dayjs(r.created_at).format()}</Tag>
                            );
                        }
                    },
                    {
                        title: '审核人',
                        key: 'review_by',
                        dataIndex: 'review_by',
                        width:100,
                    },
                    {
                        title: '创建人',
                        key: 'created_by',
                        dataIndex: 'created_by',
                        width:100,
                    },
                    {
                        title: '删除人',
                        key: 'updated_by',
                        width:100,
                        render:(_,r)=>{
                            if (r.status == 3){
                                return r.updated_by;
                            }
                        }
                    },
                    {
                        title: '操作',
                        key: 'operate',
                        fixed:'right',
                        width:260,
                        render:(_,r)=>{

                            if (r.status == 0){
                                return (
                                    <>
                                        <FormModal
                                            title={'兑换码预览'}
                                            btnText="预览"
                                            btnType="dashed"
                                            layout={'vertical'}
                                        >
                                            <RedeemCodeView disable={true} value={r} />
                                        </FormModal>
                                        <Button type={'primary'} onClick={()=>{reviewFn(r.id!,1)}} >通过</Button>
                                        <Button type={'dashed'} danger={true} onClick={()=>{reviewFn(r.id!,2)}} >驳回</Button>
                                    </>
                                    );
                            }else if (r.status == 1){
                                return (
                                    <>
                                        <FormModal
                                            title={'兑换码预览'}
                                            btnText="预览"
                                            btnType="dashed"
                                            layout={'vertical'}
                                        >
                                            <RedeemCodeView disable={true} value={r} />
                                        </FormModal>

                                        <FormModal
                                        btnType={'primary'}
                                        danger={true}
                                        btnText={'删除'}
                                        title={'删除兑换码'}
                                        onOK={deleteRedeemCode}
                                    >
                                            <Form.Item name='id' initialValue={r.id} style={{display:'none'}}>
                                                <Input type={'hidden'} />
                                            </Form.Item>

                                            <Form.Item name='code' initialValue={r.code} style={{display:'none'}}>
                                                <Input type={'hidden'} />
                                            </Form.Item>

                                            <Alert message={'请输入兑换码: '+r.code+' 进行删除。\n注意: 删除后无法再次使用。'} />
                                            <Form.Item label="兑换码" name="code_input"  rules={[{ required: true, message: '兑换码' }]}>
                                                <Input maxLength={16} minLength={1} style={{ width: 200 }} />
                                            </Form.Item>
                                        </FormModal>
                                    </>

                                );
                            }
                        }
                    }
                ]}
                dataSource={data[0]}
                pagination={{ defaultPageSize: defaultPageSize, total: data[1], onChange: fetchList}}
                scroll={{y:contentHeight - 150,x:1500}}
            >
            </Table>
        </div>
    );
};

export default RedeemCodePage;