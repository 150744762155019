import { IBaseResponse, ITableSystemMail, ITableUserMail } from '@/types';
import request from '@/utils/request';

interface IGetSystemMailList {
  list: ITableSystemMail[];
}
export const getSystemMailList = async function ({ preLastId = 0, limit = 50 }) {
  return await request<IGetSystemMailList>(`/hmf/admin/email/getSystemList`, {
    method: 'GET',
    params: {
      preLastId,
      limit,
    },
  });
};

export const createSystemMail = async function (data: ITableSystemMail) {
  return await request<IBaseResponse>(`/hmf/admin/email/insertSystemMail`, {
    method: 'POST',
    data: { systemMail: JSON.stringify(data) },
  });
};

export const updateSystemMail = async function (data: ITableSystemMail) {
  return await request<IBaseResponse>(`/hmf/admin/email/updateSystemMail`, {
    method: 'POST',
    data: { systemMail: JSON.stringify(data) },
  });
};

interface IGetUserMailList {
  list: ITableUserMail[];
}

export const getUserMailList = async function ({ uid = 0, preLastId = 0, limit = 50 }) {
  return await request<IGetUserMailList>(`/hmf/admin/email/getUserList`, {
    method: 'GET',
    params: {
      preLastId,
      limit,
      uid,
    },
  });
};

export const createUserMail = async function (data: ITableUserMail) {
  return await request<IBaseResponse>(`/hmf/admin/email/insertUserMail`, {
    method: 'POST',
    data: { userMail: JSON.stringify(data) },
  });
};

export const updateUserMail = async function (data: ITableUserMail) {
  return await request<IBaseResponse>(`/hmf/admin/email/updateUserMail`, {
    method: 'POST',
    data: { userMail: JSON.stringify(data) },
  });
};
