import { action, Action } from 'easy-peasy';

export interface IMenuModel {
  menuKey: string;
  setMenuKey: Action<IMenuModel, string>;
  breadcrumb: string[];
  setBreadcrumb: Action<IMenuModel, string[]>;
}

export const menuModel: IMenuModel = {
  breadcrumb: [],
  setBreadcrumb: action((state, payload) => {
    state.breadcrumb = [...payload];
  }),
  menuKey: '',
  setMenuKey: action((state, payload) => {
    state.menuKey = payload;
  }),
};
