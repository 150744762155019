import { createStore, createTypedHooks } from 'easy-peasy';

import { IMenuModel, menuModel } from '@/models/menuModel';
import { IUserModel, userModel } from '@/models/userModel';
interface IAppStore {
  menuModel: IMenuModel;
  userModel: IUserModel;
}

const store = createStore<IAppStore>({
  menuModel,
  userModel,
});

const typedHooks = createTypedHooks<IAppStore>();
export const useStoreState = typedHooks.useStoreState;
export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;

export default store;
