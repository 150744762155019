import { IBaseResponse, ITableNotice } from '@/types';
import request from '@/utils/request';

interface IGetNoticeList {
  list: ITableNotice[];
}
export const getNoticeList = async function ({ preLastId = 0, limit = 50 }) {
  return await request<IGetNoticeList>(`/hmf/admin/notice/getList`, {
    method: 'GET',
    params: {
      preLastId,
      limit,
    },
  });
};

export const createNotice = async function (data: ITableNotice) {
  return await request<IBaseResponse>(`/hmf/admin/notice/insert`, {
    method: 'POST',
    data: { notice: JSON.stringify(data) },
  });
};

export const updateNotice = async function (data: ITableNotice) {
  return await request<IBaseResponse>(`/hmf/admin/notice/update`, {
    method: 'POST',
    data: { notice: JSON.stringify(data) },
  });
};

export const instantNotice = async function (data: ITableNotice) {
  return await request<IBaseResponse>(`/hmf/admin/notice/instant`, {
    method: 'POST',
    data: { data: JSON.stringify(data) },
  });
};
