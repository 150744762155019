import React from 'react';
import { Button, Space, Input, Form, message } from 'antd';
import { FormModal } from '@/components/formModal';
import {clearUserCache, cloneUserData, delCache, mockByLTUid, mockByUid, registerAccount} from '@/services/tool';
import { clearUserData } from '@/services/user';
import { toNumber } from 'lodash';
import { getNamespace } from '@/utils/request';
import { sleep } from '@/utils/tools';

// const UserMPage: React.FC = () => {
//   return (
//     <>
//       <div>
//         <Space size={[5, 5]} wrap>
//           <FormModal
//             btnText="注册账号"
//             btnType="primary"
//             onOK={async (args) => {
//               console.log(args.values);
//               registerAccount({ ...args.values })
//                 .then((res) => {
//                   message.success('注册成功');
//                   args.closeModal();
//                 })
//                 .catch((e) => {
//                   // message.error('注册失败');
//                 })
//                 .finally(() => {
//                   args.closeLoading();
//                 });
//             }}
//           >
//             <Form.Item label="账号" name="account" rules={[{ required: true, message: '输入账号' }]}>
//               <Input placeholder={`账号`} minLength={1} />
//             </Form.Item>
//             <Form.Item label="密码" name="pwd" rules={[{ required: true, message: '输入密码' }]}>
//               <Input.Password placeholder={`密码`} minLength={1} />
//             </Form.Item>
//           </FormModal>
//           <FormModal
//             btnText="清除UID缓存"
//             btnType="primary"
//             onOK={async (args) => {
//               const ids: string[] = Array.from(new Set(args.values.uids.split(',')));
//               console.log(ids);
//               for (let i = 0; i < ids.length; i++) {
//                 const id = ids[i];
//                 message.info(`清除[${id}]`);
//                 await clearUserCache(id);
//                 await sleep(200);
//               }
//               args.closeModal();
//               args.closeLoading();
//               message.success('清除完成');
//             }}
//           >
//             <Form.Item label="UID" name="uids" rules={[{ required: true, message: '输入内容' }]}>
//               <Input.TextArea placeholder={`多个id逗号隔开：id1,id2`} rows={5} minLength={1} />
//             </Form.Item>
//           </FormModal>
//           <FormModal
//             btnText="清除UID数据"
//             btnType="primary"
//             onOK={async (args) => {
//               const ids: string[] = Array.from(new Set(args.values.uids.split(',')));
//               console.log(ids);
//               for (let i = 0; i < ids.length; i++) {
//                 const id = ids[i];
//                 message.info(`清除[${id}]`);
//                 await clearUserData(toNumber(id));
//                 await sleep(200);
//               }
//               args.closeModal();
//               args.closeLoading();
//               message.success('清除完成');
//             }}
//           >
//             <Form.Item label="UID" name="uids" rules={[{ required: true, message: '输入内容' }]}>
//               <Input.TextArea placeholder={`多个id逗号隔开：id1,id2`} rows={5} minLength={1} />
//             </Form.Item>
//           </FormModal>
//
//           <FormModal
//             btnText="清除redis数据"
//             btnType="primary"
//             onOK={async (args) => {
//               // const keys: string[] = Array.from(new Set(args.values.keys.split(',')));
//               // console.log(ids);
//               // for (let i = 0; i < ids.length; i++) {
//               //   const id = ids[i];
//               //   message.info(`清除[${id}]`);
//               //   await clearUserData(toNumber(id));
//               //   await sleep(200);
//               // }
//               await delCache(args.values.keys);
//               args.closeModal();
//               args.closeLoading();
//               message.success('清除完成');
//             }}
//           >
//             <Form.Item label="keys" name="keys" rules={[{ required: true, message: '输入内容' }]}>
//               <Input.TextArea placeholder={`多个key逗号隔开：key1,key2`} rows={5} minLength={1} />
//             </Form.Item>
//           </FormModal>
//         </Space>
//       </div>
//       <div style={{ marginTop: 30 }}>
//         <Space size={[5, 5]} wrap>
//           <FormModal
//             btnText="批量处理游戏UID为最大等级"
//             btnType="primary"
//             onOK={async (args) => {
//               const ids: string[] = Array.from(new Set(args.values.uids.split(',')));
//               console.log(ids);
//               for (let i = 0; i < ids.length; i++) {
//                 const id = ids[i];
//                 message.info(`设置[${id}]`);
//                 await mockByUid(id);
//                 await sleep(200);
//               }
//               args.closeModal();
//               args.closeLoading();
//               message.success('设置完成');
//             }}
//           >
//             <Form.Item label="UIDs" name="uids" rules={[{ required: true, message: '输入内容' }]}>
//               <Input.TextArea placeholder={`多个id逗号隔开：id1,id2`} rows={5} minLength={1} />
//             </Form.Item>
//           </FormModal>
//           <FormModal
//             btnText="批量处理游戏UID为高级账号"
//             btnType="primary"
//             onOK={async (args) => {
//               const ids: string[] = Array.from(new Set(args.values.uids.split(',')));
//               console.log(ids);
//               for (let i = 0; i < ids.length; i++) {
//                 const id = ids[i];
//                 message.info(`设置[${id}]`);
//                 await mockByUid(id, undefined, 'lv3');
//                 await sleep(200);
//               }
//               args.closeModal();
//               args.closeLoading();
//               message.success('设置完成');
//             }}
//           >
//             <Form.Item label="UIDs" name="uids" rules={[{ required: true, message: '输入内容' }]}>
//               <Input.TextArea placeholder={`多个id逗号隔开：id1,id2`} rows={5} minLength={1} />
//             </Form.Item>
//           </FormModal>
//           <FormModal
//             btnText="批量处理游戏UID为中级账号"
//             btnType="primary"
//             onOK={async (args) => {
//               const ids: string[] = Array.from(new Set(args.values.uids.split(',')));
//               console.log(ids);
//               for (let i = 0; i < ids.length; i++) {
//                 const id = ids[i];
//                 message.info(`设置[${id}]`);
//                 await mockByUid(id, undefined, 'lv2');
//                 await sleep(200);
//               }
//               args.closeModal();
//               args.closeLoading();
//               message.success('设置完成');
//             }}
//           >
//             <Form.Item label="UIDs" name="uids" rules={[{ required: true, message: '输入内容' }]}>
//               <Input.TextArea placeholder={`多个id逗号隔开：id1,id2`} rows={5} minLength={1} />
//             </Form.Item>
//           </FormModal>
//           <FormModal
//             btnText="批量处理游戏UID为初级账号"
//             btnType="primary"
//             onOK={async (args) => {
//               const ids: string[] = Array.from(new Set(args.values.uids.split(',')));
//               console.log(ids);
//               for (let i = 0; i < ids.length; i++) {
//                 const id = ids[i];
//                 message.info(`设置[${id}]`);
//                 await mockByUid(id, undefined, 'lv1');
//                 await sleep(200);
//               }
//               args.closeModal();
//               args.closeLoading();
//               message.success('设置完成');
//             }}
//           >
//             <Form.Item label="UIDs" name="uids" rules={[{ required: true, message: '输入内容' }]}>
//               <Input.TextArea placeholder={`多个id逗号隔开：id1,id2`} rows={5} minLength={1} />
//             </Form.Item>
//           </FormModal>
//           <FormModal
//             btnText="拷贝账号"
//             btnType="primary"
//             onOK={async (args) => {
//               const ids: string[] = Array.from(new Set(args.values.uids.split(',')));
//               console.log(ids);
//               for (let i = 0; i < ids.length; i++) {
//                 const id = ids[i];
//                 message.info(`设置[${id}]`);
//                 await mockByUid(id, args.values.targetUid);
//                 await sleep(200);
//               }
//               args.closeModal();
//               args.closeLoading();
//               message.success('设置完成');
//             }}
//           >
//             <Form.Item label="UID" name="targetUid" rules={[{ required: true, message: '输入内容' }]}>
//               <Input placeholder={`拷贝的账号`} minLength={1} />
//             </Form.Item>
//             <Form.Item label="UIDs" name="uids" rules={[{ required: true, message: '输入内容' }]}>
//               <Input.TextArea placeholder={`拷贝到的账号，多个id逗号隔开：id1,id2`} rows={5} minLength={1} />
//             </Form.Item>
//           </FormModal>
//         </Space>
//       </div>
//
//       <div style={{ marginTop: 30 }}>
//         <Space size={[5, 5]} wrap>
//           <Button
//             onClick={() => {
//               message.info('开始部署');
//               const namespace = getNamespace();
//               let env = 'dev';
//               if (namespace === 'pre') {
//                 env = 'pre';
//               } else if (namespace === 'beta') {
//                 env = 'beta';
//               }
//               // const env = namespace === 'pre' ? 'pre' : 'dev';
//               fetch(`https://api.quietfire.fun/run-script-deploy-hmf?key=4m!f@bwjfhmm@78m@hupcwdy&env=${env}`)
//                 .then((res) => {
//                   return res.json();
//                 })
//                 .then((data) => {
//                   if (data.result) {
//                     message.success('部署成功');
//                   } else {
//                     message.error('部署失败');
//                   }
//                 });
//             }}
//           >
//             api部署
//           </Button>
//           <Button
//             onClick={() => {
//               message.info('开始部署');
//               const namespace = getNamespace();
//               // const env = namespace === 'pre' ? 'pre' : 'dev';
//               let env = 'dev';
//               if (namespace === 'pre') {
//                 env = 'pre';
//               } else if (namespace === 'beta') {
//                 env = 'beta';
//               }
//               fetch(`https://api.quietfire.fun/run-script-deploy-hmf?key=4m!f@bwjfhmm@78m@hupcwdy&env=${env}&dir=front`)
//                 .then((res) => {
//                   return res.json();
//                 })
//                 .then((data) => {
//                   if (data.result) {
//                     message.success('部署成功');
//                   } else {
//                     message.error('部署失败');
//                   }
//                 });
//             }}
//           >
//             后台页面部署
//           </Button>
//           <Button
//             onClick={() => {
//               message.info('开始部署');
//               const namespace = getNamespace();
//               // const env = namespace === 'pre' ? 'pre' : 'dev';
//               let env = 'dev';
//               if (namespace === 'pre') {
//                 env = 'pre';
//               } else if (namespace === 'beta') {
//                 env = 'beta';
//               }
//               fetch(`https://api.quietfire.fun/run-script-deploy-hmf?key=4m!f@bwjfhmm@78m@hupcwdy&env=${env}&dir=go`)
//                 .then((res) => {
//                   return res.json();
//                 })
//                 .then((data) => {
//                   if (data.result) {
//                     message.success('部署成功');
//                   } else {
//                     message.error('部署失败');
//                   }
//                 });
//             }}
//           >
//             Go服务部署
//           </Button>
//         </Space>
//       </div>
//     </>
//   );
// };


const UserMPage: React.FC = () => {
    return (
        <>
            <div style={{ marginTop: 30 }}>
                <Space size={[5, 5]} wrap>
                    <FormModal
                        btnText="拷贝账号"
                        btnType="primary"
                        onOK={async (args) => {
                            const ids: string[] = Array.from(new Set(args.values.uids.split(',')));
                            for (let i = 0; i < ids.length; i++) {
                                const id = ids[i];
                                message.info(`设置[${id}]`);
                                await cloneUserData(id, args.values.targetUid);
                                await sleep(200);
                            }
                            args.closeModal();
                            args.closeLoading();
                            message.success('设置完成');
                        }}
                    >
                        <Form.Item label="UID" name="targetUid" rules={[{ required: true, message: '输入内容' }]}>
                            <Input placeholder={`拷贝的账号`} minLength={1} />
                        </Form.Item>
                        <Form.Item label="UIDs" name="uids" rules={[{ required: true, message: '输入内容' }]}>
                            <Input.TextArea placeholder={`拷贝到的账号，多个id逗号隔开：id1,id2`} rows={5} minLength={1} />
                        </Form.Item>
                    </FormModal>
                </Space>
            </div>

            <div style={{ marginTop: 30 }}>
                <Space size={[5, 5]} wrap>
                    <Button
                        onClick={() => {
                            message.info('开始部署');
                            const namespace = getNamespace();
                            let env = 'dev';
                            if (namespace === 'pre') {
                                env = 'pre';
                            } else if (namespace === 'beta') {
                                env = 'beta';
                            }
                            // const env = namespace === 'pre' ? 'pre' : 'dev';
                            fetch(`https://api.quietfire.fun/run-script-deploy-hmf?key=4m!f@bwjfhmm@78m@hupcwdy&env=${env}`)
                                .then((res) => {
                                    return res.json();
                                })
                                .then((data) => {
                                    if (data.result) {
                                        message.success('部署成功');
                                    } else {
                                        message.error('部署失败');
                                    }
                                });
                        }}
                    >
                        api部署
                    </Button>
                    <Button
                        onClick={() => {
                            message.info('开始部署');
                            const namespace = getNamespace();
                            // const env = namespace === 'pre' ? 'pre' : 'dev';
                            let env = 'dev';
                            if (namespace === 'pre') {
                                env = 'pre';
                            } else if (namespace === 'beta') {
                                env = 'beta';
                            }
                            fetch(`https://api.quietfire.fun/run-script-deploy-hmf?key=4m!f@bwjfhmm@78m@hupcwdy&env=${env}&dir=front`)
                                .then((res) => {
                                    return res.json();
                                })
                                .then((data) => {
                                    if (data.result) {
                                        message.success('部署成功');
                                    } else {
                                        message.error('部署失败');
                                    }
                                });
                        }}
                    >
                        后台页面部署
                    </Button>
                    <Button
                        onClick={() => {
                            message.info('开始部署');
                            const namespace = getNamespace();
                            // const env = namespace === 'pre' ? 'pre' : 'dev';
                            let env = 'dev';
                            if (namespace === 'pre') {
                                env = 'pre';
                            } else if (namespace === 'beta') {
                                env = 'beta';
                            }
                            fetch(`https://api.quietfire.fun/run-script-deploy-hmf?key=4m!f@bwjfhmm@78m@hupcwdy&env=${env}&dir=go`)
                                .then((res) => {
                                    return res.json();
                                })
                                .then((data) => {
                                    if (data.result) {
                                        message.success('部署成功');
                                    } else {
                                        message.error('部署失败');
                                    }
                                });
                        }}
                    >
                        Go服务部署
                    </Button>
                </Space>
            </div>
        </>
    );
};

export default UserMPage;
