import React, {useContext, useEffect, useState} from 'react';
import { TableFuncs } from '@/components/tableFuncs';
import {Table, Button, Upload, message, Form, Input, Space, Switch, Select} from 'antd';
import {useFetchListWrapper, useContentHeight, useFetchListWithCount, useFetchConfigByKey} from '@/hooks';
import {
    Dayts,
    EmailStatus,
    EmailType,
    EmailTypeName,
    formatDate,
    FORMATE_DATE_TIME,
    formatSqlData,
    formatTimestamp,
    getDateTimestamp,
    IServerInfo,
    ITableSystemMail,
    ITableUserMail
} from '@/types';
import { createSystemMail, getSystemMailList, getUserMailList, updateSystemMail, updateUserMail } from '@/services/email';
import { JsonPreview } from '@/components/jsonPreview';
import { FormModal } from '@/components/formModal';
import { DatePicker } from 'antd';
import {cloneDeep, isArray, isUndefined, toNumber} from 'lodash';
import {getAllServerList} from "@/services/server";
import {PackItemInput} from "@/components/packItemInput";
import dayjs from "dayjs";
import {ITableConfigLanguage} from "@/types/typings/config";
import {getGameConfigByKey} from "@/services/gameConfig";
import {formatString} from "@/utils/strKit";
import {FormContext} from "antd/es/form/context";
const { Search } = Input;

const languageRegexp = new RegExp('\{LANG:([a-zA-Z0-9_]+)(\|[^}]*)}','g');

const MailView: React.FC<{value?:any,disable?:boolean}> = ({value= {},disable=false})=>{
    const {form} = useContext(FormContext);
    form?.resetFields();




    const [languageConfig, fetchLanguageConfig] = useFetchConfigByKey<ITableConfigLanguage>(getGameConfigByKey);
    const [initialValue, setInitialValue] = useState<any>({});
    console.log("1",initialValue);


    useEffect(()=>{
        console.log("2",initialValue);
        fetchLanguageConfig('Language');
    },[]);


    useEffect(()=>{

        const v = {...value};

        console.log("3",initialValue);
        const languageMap = new Map<string,string>();

        for (let i = 0; i < languageConfig.length; i++) {
            const elem = languageConfig[i];
            languageMap.set(elem.Key, elem.Zh);
        }

        const replaceFunc = (str:string):string => {
            if (!str)return str;

            return  str.replace(languageRegexp,(match:string,...args:any)=> {
                const lang = languageMap.get(args[0]);

                if (!lang){
                    return match
                }

                let params:any[] = [];
                if (args[1] && args[1].length > 0){
                    const a = args[1] as string;
                    params = a.split('|');
                    params.unshift();
                }

                return formatString(lang,...params)
            });
        }

        setInitialValue({...v,Title:replaceFunc(v.Title),Content:replaceFunc(v.Content)});
        console.log("4",initialValue);
    },[languageConfig]);


    console.log("5",initialValue);
    return (
        <>
            <Form.Item label="标题" name="Title" initialValue={initialValue.Title} rules={[{ required: true, message: '输入标题' }]}>
                <Input maxLength={100} minLength={1} disabled={disable} />
            </Form.Item>
            <Form.Item label="内容" name="Content" initialValue={initialValue.Content} rules={[{ required: true, message: '输入内容' }]}>
                <Input.TextArea maxLength={2000} rows={5} minLength={1} disabled={disable}  />
            </Form.Item>
            <Form.Item label="过期日期" name="ExpiryTime" initialValue={dayjs(initialValue.ExpiryTime*1000)} rules={[{ required: true, message: '输入时间范围' }]}>
                <DatePicker showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss" disabled={disable}/>
            </Form.Item>
            <Form.Item label="奖励" name="Rewards" initialValue={initialValue.Rewards} rules={[{ required: false, message: '输入奖励' }]}>
                <PackItemInput disabled={disable}/>
            </Form.Item>
        </>
    );

}

const MailPage: React.FC = () => {
  const [data, fetchList] = useFetchListWithCount<ITableUserMail>(getUserMailList);
  const contentHeight = useContentHeight();

  return (
    <div>
      <TableFuncs>
        <Search
          placeholder="输入uid"
          allowClear
          enterButton="查询"
          size="large"
          onSearch={(val) => {
            const id = toNumber(val);
            if (!id) {
              message.error('uid 无效');
            } else {
              fetchList({ uid: id, limit: 50, preLastId: 0 });
            }
          }}
        />
      </TableFuncs>
      <Table
        rowKey="id"
        columns={[
          {
            title: 'id',
            key: 'id',
            dataIndex: 'id',
          },
          {
            title: '邮件来源',
            key: 'eid',
            render: (_, record) => {
              if (record.eid) return `系统: ${record.eid}`;
              return '-';
            },
          },
          {
            title: '标题',
            dataIndex: 'Title',
            key: 'Title',
          },
          {
            title: '过期时间/UTC',
            key: 'ExpiryTime',
            render: (_, record) => {
              return formatTimestamp(record.ExpiryTime!);
            },
          },
          {
            title: '创建时间',
            key: 'create_at',
            render: (_, record) => {
              return formatSqlData(record.create_at!);
            },
          },
          {
            title: '状态',
            key: 'type',
            render: (_, record) => {
              return <>{EmailTypeName[`CN${record.Type || 1}`]}</>;
            },
          },
          {
            title: '邮件详情',
            key: 'content',
            render: (_, record) => {
                return (
                    <FormModal
                        btnText="预览"
                        btnType="primary">
                        <MailView value={record} disable={true} />
                    </FormModal>
                );
            },
          },
          {
            title: '操作',
            key: 'action',
            render: (_, record) => {
              return (
                <Space size={[5, 5]} wrap>
                  <Button
                    onClick={() => {
                      updateUserMail({
                        ...record,
                        Type: EmailType.Unread,
                      }).then((res) => {
                        message.success('设置成功');
                        fetchList({ uid: record.uid, limit: 50, preLastId: 0 });
                      });
                    }}
                  >
                    设成未读
                  </Button>
                  <Button
                    onClick={() => {
                      updateUserMail({
                        ...record,
                        Type: EmailType.Readalready,
                      }).then((res) => {
                        message.success('设置成功');
                        fetchList({ uid: record.uid, limit: 50, preLastId: 0 });
                      });
                    }}
                  >
                    设成已读
                  </Button>
                  <Button
                    onClick={() => {
                      updateUserMail({
                        ...record,
                        Type: EmailType.Delete,
                      }).then((res) => {
                        message.success('设置成功');
                        fetchList({ uid: record.uid, limit: 50, preLastId: 0 });
                      });
                    }}
                  >
                    设成删除
                  </Button>
                </Space>
              );
            },
          },
        ]}
        dataSource={data[0]}
        pagination={{ pageSize: 50 }}
        scroll={{ y: contentHeight - 150 }}
      />
    </div>
  );
};

export default MailPage;
