import { StatusType, VersionPlatformType } from '@/types';
import request from '@/utils/request';

interface IVersionListReq {
  platform: VersionPlatformType;
}

export interface IVersionItem {
  key: number;
  id: number;
  version: string;
  url: string;
  pordUrl: string;
  platform: VersionPlatformType;
  status: StatusType;
  active: number;
  clientVersion: string;
  clientBuild: string;
  update_at: string;
  create_at: string;
  create_by: string;
}

interface IVersionListRes {
  list: IVersionItem[];
}

export const getList = async function (params: IVersionListReq) {
  return await request<IVersionListRes>(`/hmf/admin/version/getList`, {
    method: 'GET',
    params,
  });
};

interface IVersionCreateReq {
  version: string;
  platform: VersionPlatformType;
}

interface IVersionCreateRes {
  result: boolean;
}

export const createVersion = async function (params: IVersionCreateReq) {
  return await request<IVersionCreateRes>(`/hmf/admin/version/create`, {
    method: 'POST',
    data: params,
  });
};

interface IVersionUpdateReq {
  id: number;
  platform: VersionPlatformType;
  url: string;
  status?: StatusType;
}

interface IVersionUpdateRes {
  result: boolean;
}
export const updateVersion = async function (params: IVersionUpdateReq) {
  return await request<IVersionUpdateRes>(`/hmf/admin/version/update`, {
    method: 'POST',
    data: params,
  });
};

interface IVersionRenameReq {
  url: string;
  version?: string;
}

interface IVersionRenameRes {
  url: string;
}
export const renameWebVersion = async function (params: IVersionRenameReq) {
  return await request<IVersionRenameRes>(`/hmf/admin/renameWebZip`, {
    method: 'POST',
    data: params,
  });
};

export const renameApkVersion = async function (params: IVersionRenameReq) {
  return await request<IVersionRenameRes>(`/hmf/admin/renameApk`, {
    method: 'POST',
    data: params,
  });
};

export const setVersion = async function (id: number) {
  return await request<IVersionRenameRes>(`/hmf/admin/version/setVersion`, {
    method: 'POST',
    data: { id },
  });
};

/**
 * 获取接口版本
 * @returns
 */
export const getApiVersion = async function () {
  return await request<{ v: string }>(`/hmf/version`, {
    method: 'GET',
  });
};
