import { isProdEnv } from '@/utils/request';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export const FORMATE_DATE_TIME = 'YYYY-MM-DD HH:mm:ss';

export const FORMATE_DATE = 'YYYY-MM-DD';

export const FORMATE_TIME = 'HH:mm:ss';

export const localTimezone = 8;


dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * 格式化给定时间
 * @param time
 * @param format
 * @returns
 */
export function formatDate(time: string | number, format = FORMATE_DATE_TIME) {
  return dayjs(time).format(format);
}

export function formatSqlData(time: string | number, format = FORMATE_DATE_TIME) {
  return formatDate(time, format);
}

/**
 * 格式化unix时间戳给定时间
 * @param timestamp 时间戳
 * @param format
 * @returns
 */
export function formatTimestamp(timestamp: number, format = FORMATE_DATE_TIME) {
  return dayjs(timestamp * 1000).format(format);
}

/**
 * 格式化utc时间戳给定时间
 * @param timestamp 时间戳
 * @param format
 * @returns
 */
export function formatUtcDate(timestamp: number, format = FORMATE_DATE_TIME) {
  return dayjs.utc(timestamp * 1000).format(format);
}

/**
 * 转换为时间戳戳/秒
 * @param time
 * @returns
 */
export function getDateTimestamp(time: string) {
  return dayjs(time).unix();
}

// /**
//  * 转换为utc时间戳戳/秒
//  * @param time
//  * @returns
//  */
// export function getDateUtcTimestamp(time: string) {
//   return dayjs.utc(time).unix();
// }

/**
 * 获取当前utc时间戳/秒
 * @returns
 */
export function getCurrentUtcTimestamp() {
  return dayjs().utc().unix();
}

/**
 * 检查utc时间戳是否过期
 */
export function checkIsExpiryUTCTimestamp(ts: number) {
  return getCurrentUtcTimestamp() >= ts;
}


/** 当前时间+8小时*/
export function getLocalUnix() {
  return dayjs.utc().unix();
}


let defaultTz : string = 'Asia/Shanghai';

export function DaytsUTC (date?: dayjs.ConfigType, ...args:any):dayjs.Dayjs {
  args.unshift(date);
  return dayjs.utc(...args).tz();
}

export function Dayts (date?: dayjs.ConfigType, ...args:any):dayjs.Dayjs {
  args.unshift(date);
  return dayjs.tz(...args);
}

export function setDaytsTz (tz :string) {
  // defaultTz = tz
  console.log('设置了默认时区',tz);
  dayjs.tz.setDefault(tz);
  console.log('设置了默认时区',Dayts());
}