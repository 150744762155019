import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Alert,
    Button,
    Card,
    DatePicker,
    Form,
    Input,
    InputNumber,
    message,
    Select,
    Space,
    Switch,
    Table,
    Tag
} from 'antd';
import {FORMATE_DATE_TIME, formatTimestamp, IServerInfo, ITableActivityHeroTrial, ITableActivityQuest,} from '@/types';
import {useContentHeight, useFetchListWithCount, useFetchListWrapper, useFetchShops} from '@/hooks';
import {FormModal, IFormOK} from '@/components/formModal';

import {create, deleteRow, getList, update} from "@/services/activityHeroTrial";
import {TableFuncs} from "@/components/tableFuncs";
import {cloneDeep, isNumber, isString} from "lodash";
import {getAllServerList} from "@/services/server";
import {JsonPreview} from "@/components/jsonPreview";
import dayjs from "dayjs";
import {FormContext} from "antd/es/form/context";
import {XlsxUploader} from "@/components/xlsxUploader";
import {DownloadOutlined} from "@ant-design/icons";
import {IItem} from "@/types/typings/useData";


const defaultShopType = 12;

// activityList 活动列表ID
const activityList = [
    {
        id:50001,
        name:'英雄试炼'
    },
]

/**
 * 配置信息
 */
interface ActivityDataHero {
    id:number;
    position:number;
}

interface ActivityDataRankReward {
    rank:number[];
    reward:IItem[]
}

interface ActivityDataDamageReward {
    id:number;
    damage:number;
    reward:number;
}

/**
 * 活动数据属性
 */
interface  ActivityDataProps {
    hero_level?:number;
    heroes?:ActivityDataHero[];
    attack_scale?:number;
    life_scale?:number;
    hero_star_level?:number;
    rank_reward?:ActivityDataRankReward[];
    damage_reward?:ActivityDataDamageReward[];
    battle_duration?:number;
    banner?:string
}


/**
 * 活动记录属性
 */
interface ActivityProps {
    sub_type?:number,
    remark?:string,
    server_ids?:number[],
    start_at?:number,
    end_at?:number,
    data?:ActivityDataProps
}

/**
 * 活动渲染器属性
 */
interface ActivityViewProps {
    initialValue?:any;
    disabled?:boolean
    editable?:boolean
}

/**
 * 活动表单子渲染器 ActivityFormChildView
 * @param initialValue
 * @param disabled
 * @constructor
 */
const ActivityFormChildView: React.FC<ActivityViewProps> =({initialValue, disabled=false}) =>{
    const {form} = useContext(FormContext);
    const [serverList, fetchAllServer] = useFetchListWrapper<IServerInfo>(getAllServerList);

    const [serverSelectOptions, setServerSelectOptions] = useState((activityList || []).map((d) => ({
        value: d.id,
        label: `(${d.id}) ${d.name}`
    })))

    const [configData,setConfigData] = useState<ActivityDataProps>(initialValue?.data||{})

    const myValue:any = {
        ...initialValue
    }

    if (myValue?.sub_type != undefined){
        myValue.sub_type = myValue.sub_type;
    }

    if (myValue?.server_ids != undefined){
        myValue.server_ids = myValue.server_ids;
        if (isString(myValue.server_ids)){
            myValue.server_ids =JSON.parse(myValue.server_ids);
        }
    }

    if (myValue?.start_at != undefined){
        myValue.start_at = myValue.start_at;
        if (isNumber(myValue.start_at)){
            myValue.start_at = dayjs.unix(myValue.start_at).format(FORMATE_DATE_TIME);
        }
    }else {
        myValue.start_at = dayjs().format('YYYY-MM-DD 00:00:00');
    }

    if (myValue?.end_at != undefined && myValue.end_at > 0){
        myValue.end_at = myValue.end_at;
        if (isNumber(myValue.end_at)){
            myValue.end_at = dayjs.unix(myValue.end_at).format(FORMATE_DATE_TIME);
        }
    }else{
        myValue.end_at = dayjs().add(1,'month').format('YYYY-MM-DD 23:23:59');
    }

    try {
        if (isString(myValue.data)){
            myValue.data = JSON.parse(myValue.data);
        }
    }catch (e:any){
        message.error(e.toString());
    }
    if (!myValue.data){
        myValue.data = {}
    }

    const [rankRewardList, setRankRewardList] = useState<ActivityDataRankReward[]>(myValue.data.rank_reward)
    const [damageRewardList, setDamageRewardList] = useState<ActivityDataDamageReward[]>(myValue.data.damage_reward)
    const [heroesList, setHeroesList] = useState<ActivityDataHero[]>(myValue.data.heroes)

    useEffect(()=>{
        fetchAllServer();
    },[]);

    const serverSelectChange = (e :any, o:any)=>{

    }

    return (
        <span>
            <Form.Item label="活动子类型" name="sub_type" initialValue={myValue.sub_type} rules={[{ required: true, message: '活动不能为空' }]}>
                <Select
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    options={serverSelectOptions}
                    disabled={disabled}
                />
            </Form.Item>
            <Form.Item label={'备注'} name={'remark'} initialValue={myValue.remark}>
                <Input.TextArea rows={2} disabled={disabled} />
            </Form.Item>

            <Form.Item label="分服" initialValue={myValue.server_ids} name="server_ids" rules={[{ required: true, type: 'array', message: '选择分服' }]}>
                <Select
                    showSearch
                    onSelect={serverSelectChange}
                    mode="multiple"
                    allowClear
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    options={(serverList || []).map((d) => ({
                        value: d.id,
                        label: `(${d.id}) ${d.name}`,
                    }))}
                    disabled={disabled}
                />
            </Form.Item>

            <Space>
                <Form.Item label="开始时间" name="start_at" initialValue={dayjs(myValue.start_at)} rules={[{ required: true, message: '未设置开始时间' }]}>
                    <DatePicker showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss" disabled={disabled} />
                </Form.Item>
                <Form.Item label="结束时间" name="end_at" initialValue={myValue.end_at?dayjs(myValue.end_at):''} rules={[{ required: true, message: '未设置结束时间' }]}>
                    <DatePicker showTime={{ format: 'HH:mm:ss' }}  format="YYYY-MM-DD HH:mm:ss" disabled={disabled} />
                </Form.Item>
            </Space>

            <Card title={"配置信息"} size={"small"} >
                <Space>
                    <Form.Item label={'英雄等级'} name={['data','hero_level']} rules={[{ required: true, message: '未设置英雄等级' }]} initialValue={myValue.data?.hero_level||1}>
                        <InputNumber disabled={disabled} min={1} />
                    </Form.Item>
                    <Form.Item label={'英雄星级'} name={['data','hero_star_level']} rules={[{ required: true, message: '未设置英雄星级' }]} initialValue={myValue.data?.hero_star_level||0}>
                        <InputNumber disabled={disabled} min={0} max={15} />
                    </Form.Item>
                    <Form.Item label={'攻击力加成,倍数，支持小数点'} name={['data','attack_scale']} rules={[{ required: true, message: '未设置攻击力加成' }]} initialValue={myValue.data?.attack_scale||1}>
                        <InputNumber disabled={disabled} min={1} />
                    </Form.Item>
                     <Form.Item label={'生命力加成,倍数，支持小数点'} name={['data','life_scale']} rules={[{ required: true, message: '未设置生命力加成' }]} initialValue={myValue.data?.life_scale||1}>
                        <InputNumber disabled={disabled} min={1} />
                    </Form.Item>
                    <Form.Item label={'战斗时长，单位/秒'} name={['data','battle_duration']} rules={[{ required: true, message: '未设置战斗时长' }]} initialValue={myValue.data?.battle_duration||60}>
                        <InputNumber disabled={disabled} />
                    </Form.Item>
                </Space>

                <Space align={"start"}>
                    <Form.Item label={'英雄队伍'} name={['data','heroes']} initialValue={myValue.data.heroes} rules={[{ required: true, type:'array', message: '未设置英雄队伍' }]}>
                        <Input style={{display:'none'}} />
                         <div style={{height:0,position:'absolute',right:3,top:-36}}>
                            {(()=>{
                                if (disabled) return '';
                                return (
                                    <Space >
                                        <Button type={'link'} href={'./assets/英雄试炼队伍配置模板.xlsx'} >下载模板</Button>
                                        <XlsxUploader icon={<DownloadOutlined />} btnTxt={'导入数据表'} onChange={(_,data)=>{
                                            try {
                                                // 删除头两个元素
                                                data.shift();
                                                data.shift();
                                                setHeroesList(data);

                                                let cfgData = form?.getFieldValue('data') as ActivityDataProps;
                                                if (!cfgData){
                                                    cfgData = {};
                                                }

                                                cfgData.heroes = data

                                                form?.setFieldsValue({'data':cfgData});
                                            } catch (e: any) {
                                                message.error(e.toString());
                                            }
                                        }} />
                                    </Space>
                                )
                            })()
                            }
                        </div>
                        <Table pagination={false}  scroll={{y:400}} rowKey={'id'} columns={[
                            {
                                title:'英雄ID',
                                key:'id',
                                dataIndex:'id'
                            },
                            {
                                title:'位置',
                                key:'position',
                                dataIndex:'position'
                            },
                        ]} dataSource={heroesList}>
                        </Table>
                    </Form.Item>
                    <Form.Item label={'排名奖励'} name={['data','rank_reward']} initialValue={myValue.data.rank_reward} rules={[{ required: true, type:'array', message: '未设置排名奖励' }]}>
                        <Input style={{display:'none'}} />
                         <div style={{height:0,position:'absolute',right:3,top:-36}}>
                            {(()=>{
                                if (disabled) return '';
                                return (
                                    <Space >
                                        <Button type={'link'} href={'./assets/英雄试炼排行奖励配置模板.xlsx'} >下载模板</Button>
                                        <XlsxUploader icon={<DownloadOutlined />} btnTxt={'导入数据表'} onChange={(_,data)=>{
                                            try {
                                                // 删除头两个元素
                                                data.shift();
                                                data.shift();


                                                let cfgData = form?.getFieldValue('data') as ActivityDataProps;
                                                if (!cfgData){
                                                    cfgData = {};
                                                }

                                                try {
                                                    data.forEach((value, index, array)=>{
                                                        value.rank = JSON.parse(value.rank);
                                                        value.reward = JSON.parse(value.reward);
                                                    })
                                                }catch (e:any) {
                                                }
                                                cfgData.rank_reward = data

                                                setRankRewardList(data);
                                                form?.setFieldsValue({'data':cfgData});
                                            } catch (e: any) {
                                                message.error(e.toString());
                                            }
                                        }} />
                                    </Space>
                                )
                            })()
                            }
                        </div>
                        <Table pagination={false}  scroll={{y:400}} rowKey={'rank'} columns={[
                            {
                                title:'排行',
                                key:'rank',
                                render:(_,r)=>{
                                    return `从${r.rank[0]}到${r.rank[1]}级`
                                }
                            },
                            {
                                title:'奖励物品',
                                key:'reward',
                                render:(_,r)=>{
                                    return  (
                                        <JsonPreview json={r.reward} />
                                    );
                                }
                            },
                        ]} dataSource={rankRewardList}>
                        </Table>
                    </Form.Item>
                    <Form.Item label={'伤害奖励'} name={['data','damage_reward']} initialValue={myValue.data.damage_reward} rules={[{ required: true, type:'array', message: '未设置伤害奖励' }]}>
                        <Input style={{display:'none'}} />
                        <div style={{height:0,position:'absolute',right:3,top:-36}}>
                            {(()=>{
                                if (disabled) return '';
                                return (
                                    <Space >
                                        <Button type={'link'} href={'./assets/英雄试炼伤害奖励配置模板.xlsx'} >下载模板</Button>
                                        <XlsxUploader icon={<DownloadOutlined />} btnTxt={'导入数据表'} onChange={(_,data)=>{
                                            try {
                                                // 删除头两个元素
                                                data.shift();
                                                data.shift();

                                                let cfgData = form?.getFieldValue('data') as ActivityDataProps;
                                                if (!cfgData){
                                                    cfgData = {};
                                                }

                                                cfgData.damage_reward = data

                                                setDamageRewardList(data);

                                                form?.setFieldsValue({'data':cfgData});
                                            } catch (e: any) {
                                                message.error(e.toString());
                                            }
                                        }} />
                                    </Space>
                                )
                            })()
                            }
                        </div>
                        <Table pagination={false}   scroll={{y:400}} rowKey={'damage'} columns={[
                            {
                                title:'伤害值',
                                key:'damage',
                                dataIndex:'damage'
                            },
                            {
                                title:'掉落宝箱ID',
                                key:'reward',
                                dataIndex:'reward'
                            },
                        ]} dataSource={damageRewardList}>
                        </Table>
                    </Form.Item>
                </Space>

            </Card>
        </span>
    );
}

const ActivityHeroTrialPage: React.FC = () => {
    const [serverList, fetchAllServer] = useFetchListWrapper<IServerInfo>(getAllServerList);
    const [data, fetchActivityList] = useFetchListWithCount<ITableActivityHeroTrial>(getList);
    const contentHeight = useContentHeight();

    const searchServerId = useRef(-1);
    const searchSubType = useRef(0);
    const searchStatus = useRef<number[]>([0,1]);

    useEffect(() => {
        fetchList();
        fetchAllServer();
    }, []);

    const fetchList = (page: number = 0, pageSize: number = 50) => {
        const param: { [key: string]: any } = { page, pageSize };

        if (searchServerId.current >= 0) {
            param['serverId'] = searchServerId.current;
        }else{
            param['serverId'] = -1
        }

        if (searchSubType.current){
            param['subType'] = searchSubType.current;
        }

        if (searchStatus.current != undefined){
            param['status'] = searchStatus.current.join(',');
        }
        fetchActivityList(param);
    };

    // 解析活动数据
    const parseActivityData = (values:any) => {
        // 如果有设置全部区服活动，则进行对应替换
        values.server_ids.sort((a:number,b:number)=>{return a-b});
        if (values.server_ids[0]==0){
            values.server_ids=[0];
        }
        // 将区服转换成字符串
        values.server_ids = JSON.stringify(values.server_ids);

        //  将数据转换成字符串
        // values.data = JSON.stringify(values.data);

        values.start_at = values.start_at.unix();
        values.end_at = values.end_at.unix();

        console.log(values);
        return values
    }

    // 创建活动
    const createActivity = (args:IFormOK) => {
        try {
            const values = parseActivityData(args.values);
            //
            console.log('提交的数据',values);
            create(values)
                .then((res) => {
                    if (!res.data.result){
                        message.error('创建失败');
                        return
                    }
                    args.closeModal();
                    fetchList();
                    message.success('创建成功');
                })
                .catch((e) => {})
                .finally(() => args.closeLoading());
        } catch (e: any) {
            args.closeLoading();
            message.error(e.toString());
        }
    }

    // 更新活动
    const updateActivity = (args:IFormOK) => {
        try {
            const values = parseActivityData(args.values);
            update(values)
                .then((res) => {
                    if (!res.data.result){
                        message.error('修改失败');
                        return
                    }
                    args.closeModal();
                    fetchList();
                    message.success('修改成功');
                })
                .catch((e) => {})
                .finally(() => args.closeLoading());
        } catch (e: any) {
            args.closeLoading();
            message.error(e.toString());
        }
    }

    // 删除活动
    const deleteActivity = (args:IFormOK) => {
        try {
            const values = args.values;
            values.actividyId = values.actividy_id;
            delete values.actividy_id;
            deleteRow(values)
                .then((res) => {
                    if (!res.data.result){
                        message.error('删除失败');
                        return
                    }
                    args.closeModal();
                    fetchList();
                    message.success('删除成功');
                })
                .catch((e) => {})
                .finally(() => args.closeLoading());
        } catch (e: any) {
            args.closeLoading();
            message.error(e.toString());
        }
    }


    return (
        <div>
            <TableFuncs>
                <Space size={[5, 5]} wrap>
                    <FormModal
                        btnText="新增活动"
                        btnType="primary"
                        onOK={createActivity}
                        winWidth={1080}
                        layout={'vertical'}
                    >
                        <ActivityFormChildView />
                    </FormModal>
                </Space>
            </TableFuncs>
            <TableFuncs>
                <Space size={[5, 5]} wrap>
                    <Select
                        style={{ width: 200 }}
                        size={'large'}
                        showSearch
                        allowClear={true}
                        placeholder={'分服'}
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        options={
                        (serverList || []).
                            map((d) => ({
                            value: d.id,
                            label: `(${d.id}) ${d.name}`
                        }))}
                        onChange={(val: any, option) => {
                            searchServerId.current = val;
                        }}
                    />
                    <Select
                        style={{ width: 200 }}
                        size={'large'}
                        showSearch
                        allowClear
                        placeholder={'活动'}
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        options={(activityList || []).map((d) => ({
                            value: d.id,
                            label: `(${d.id}) ${d.name}`
                        }))}
                    />
                    <Select
                        style={{ width: 200 }}
                        size={'large'}
                        showSearch
                        mode={'multiple'}
                        allowClear={true}
                        placeholder={'状态'}
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        options={[{value:1,label:'启用中'},{value:0,label:'未启用'},{value:2,label:'已删除'}]}
                        onChange={(val: any, option) => {
                            searchStatus.current = val;
                        }}
                        defaultValue={searchStatus.current}
                    />
                    <Button
                        size={'large'}
                        type={'primary'}
                        onClick={(evt) => {
                            fetchList();
                        }}
                    >
                        查询
                    </Button>
                </Space>
            </TableFuncs>
            <Table
                rowKey="id"
                columns={[
                    {
                        title: "#",
                        key: '#',
                        width: 50,
                        dataIndex: 'id'
                    },
                    {
                        title: '分服ID',
                        key: 'server_ids',
                        dataIndex: 'server_ids',
                        render:(value, record, index)=>{
                            const serverIDs = JSON.parse(record.server_ids);
                            if (serverIDs.includes(0)){
                                return '所有分服'
                            }
                            return record.server_ids
                        }
                    },
                    {
                        title: '活动子类型',
                        key: 'sub_type',
                        render: (_,r )=>{
                            const v = activityList.find(v => (v.id == r.sub_type));
                            if (!v){
                                return  '无效的活动';
                            }
                            return `(${v.id}) ${v.name}`;
                        },
                    },
                    {
                        title: '活动基础配置',
                        key: 'data',
                        dataIndex: 'data',
                        render: (_, record) => {
                            return <JsonPreview json={record.data} />;
                        },
                    },
                    {
                        title: '开始时间',
                        key:'start_at',
                        render:(_,r)=>{
                            return formatTimestamp(r.start_at!)
                        }
                    },
                    {
                        title: '结束时间',
                        key:'end_at',
                        render:(_,r)=>{
                            if (r.end_at){
                                return formatTimestamp(r.end_at!)
                            }
                            return '永久'
                        }
                    },
                    {
                        title: '状态',
                        key: 'status',
                        render:(_,r)=>{
                            if (r.status == 2){
                                return (<Tag color={"error"} >已删除</Tag>);
                            }
                            if (r.status == 0){
                                return (<Tag color={"yellow"} >未启用</Tag>);
                            }
                            if (r.status == 1){
                                return (<Tag color={"success"} >启用中</Tag>);
                            }
                        }
                    },
                    {
                        title: '更新时间',
                        key: 'updated_at',
                        dataIndex: 'updated_at',
                    },
                    {
                        title: '更新人',
                        key: 'updated_by',
                        dataIndex: 'updated_by',
                    },
                    {
                        title: '创建时间',
                        key: 'created_at',
                        dataIndex: 'created_at',
                    },
                    {
                        title: '创建人',
                        key: 'created_by',
                        dataIndex: 'created_by',
                    },
                    {
                        title: '操作',
                        key: 'operate',
                        render:(_,r)=>{
                            return (
                                <Space size={[5, 5]} wrap>
                                    <FormModal
                                        title={'预览数据'}
                                        btnText="预览"
                                        okText={'确定'}
                                        onOK={(args)=>args.closeModal()}
                                        winWidth={1080}
                                        layout={'vertical'}
                                    >
                                        <ActivityFormChildView initialValue={r} disabled={true} />
                                    </FormModal>
                                    <FormModal
                                        title={'复制活动'}
                                        btnText="复制"
                                        okText={'复制'}
                                        onOK={createActivity}
                                        winWidth={1080}
                                        layout={'vertical'}
                                    >
                                        <ActivityFormChildView initialValue={r} />
                                    </FormModal>

                                    {r.status == 0 && (
                                        <FormModal
                                            title={'修改活动'}
                                            btnText="修改"
                                            okText={'修改'}
                                            btnType="primary"
                                            onOK={updateActivity}
                                            winWidth={1080}
                                            layout={'vertical'}
                                        >
                                            <Space style={{marginBottom:24}}>
                                                <Form.Item label="启用" name="status" style={{margin:0}} initialValue={false}>
                                                    <Switch defaultChecked={false} />
                                                </Form.Item>
                                                <Alert  type={"warning"} message={'启用后将无法进行修改,需要进行删除后重新创建活动'}></Alert>
                                            </Space>

                                            <Form.Item name='id' initialValue={r.id} style={{display:'none'}}>
                                                <Input type={'hidden'} />
                                            </Form.Item>
                                            <ActivityFormChildView initialValue={r} />
                                        </FormModal>
                                    )}

                                    {r.status != 2 && (
                                        <FormModal
                                            btnType={'primary'}
                                            danger={true}
                                            btnText={'删除'}
                                            okText={'删除'}
                                            title={'删除活动'}
                                            onOK={deleteActivity}
                                        >
                                            <Form.Item name='id' initialValue={r.id} style={{display:'none'}}>
                                                <Input type={'hidden'} />
                                            </Form.Item>
                                            <Alert message={'请输入活动ID: '+r.sub_type+' 进行删除'} />
                                            <Form.Item label="活动ID" name="sub_type"  rules={[{ required: true, message: '活动ID不能为空' }]}>
                                                <InputNumber maxLength={100} minLength={1} style={{ width: 200 }} />
                                            </Form.Item>
                                        </FormModal>
                                    )}
                                </Space>
                            )
                        }
                    }
                ]}
                dataSource={data[0]}
                pagination={{pageSize: 50,total:data[1]}}
                scroll={{y:contentHeight - 150}}
            >
            </Table>
        </div>
    );
};

export default ActivityHeroTrialPage;