import React, { useState } from 'react';
import { Button, Modal, Form } from 'antd';
import {FormLayout} from "antd/es/form/Form";
import {BaseButtonProps} from "antd/es/button/button";
import {ButtonType} from "antd/es/button/buttonHelpers";

export interface IFormOK {
  closeModal: Function;
  closeLoading: Function;
  values: any;
}

export const FormModal: React.FC<{
  btnText: string;
  title?: string;
  onOK?: (args: IFormOK) => void;
  okText?:string;
  cancelText?:string;
  btnType?: ButtonType;
  danger?: boolean;
  onOpen?: () => void;
  winWidth?:number;
  layout?:FormLayout;
}> = ({ btnText, onOK, okText='确定', cancelText='取消', title = '新建', btnType = 'default', danger = false, children, onOpen,winWidth  ,layout}) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  const closeModal = () => {
    setOpen(false);
  };

  const closeLoading = () => {
    setConfirmLoading(false);
  };

  return (
    <>
      <Button
        onClick={() => {
          setConfirmLoading(false);
          try {
              form.resetFields();
          }catch (ex:any){}
          setOpen(true);
          onOpen?.();
        }}
        type={btnType as any}
        danger={danger}
      >
        {btnText}
      </Button>
      <Modal
        title={title}
        open={open}
        onOk={() => {
          form.validateFields().then((values) => {
            // console.log(values);
            setConfirmLoading(true);
            if (onOK){
                onOK?.call(null,{
                    values,
                    closeModal,
                    closeLoading,
                });
            }else {
               setOpen(false);
            }

          }).catch((reason:any)=>{
          });
        }}
        okText={okText}
        cancelText={cancelText}
        confirmLoading={confirmLoading}
        onCancel={() => setOpen(false)}
        destroyOnClose
        maskClosable={false}
        width={winWidth}
      >
        <Form
          name="create"
          form={form}
          // labelCol={{ span: 3 }}
          // wrapperCol={{ span: 16 }}
          // style={{ width: 630 }}
          autoComplete="off"
          layout={layout}
        >
          {children}
        </Form>
      </Modal>
    </>
  );
};
