import React, { useEffect, useRef, useState } from 'react';
import { TableFuncs } from '@/components/tableFuncs';
import { Table, Button, Upload, Select, message, Form, Input, Space, InputNumber } from 'antd';
import { forbiddenAccount, getList } from '@/services/account';
import { useFetchListWrapper, useContentHeight, useFetchListWithCount } from '@/hooks';
import { EmailType, formatDate, FORMATE_DATE_TIME, formatSqlData, getDateTimestamp, ITableAccount, ITableAdminAccount, PermissionType, PermissionTypeName, StatusType, StatusTypeName } from '@/types';
import { UserGameData } from '@/components/userGameData';
import { UserForbidden } from '@/components/userForbidden';
import { FormModal } from '@/components/formModal';
import { DatePicker } from 'antd';
import { isArray, toNumber } from 'lodash';
import { createUserMail } from '@/services/email';
import { useStoreState } from '@/entry/store';
import { AddAccount, FetchAdminList, ResetPwd, UpdateStatus } from '@/services/admin';
const { Search } = Input;

const AdminPage: React.FC = () => {
  const [data, fetchAccountList] = useFetchListWithCount<ITableAdminAccount>(FetchAdminList);
  const uid = useStoreState((state) => state.userModel.uid);
  const contentHeight = useContentHeight();

  useEffect(() => {
    fetchAccountList();
  }, []);

  return (
    <div>
      <TableFuncs>
        <Space size={[5, 5]} wrap>
          <FormModal
            btnText="新建账号，密码：hmf123456"
            btnType="primary"
            onOK={(args) => {
              AddAccount({
                ...args.values,
              })
                .then((res) => {
                  args.closeModal();
                  fetchAccountList();
                  message.success('添加成功');
                })
                .catch((e) => {})
                .finally(() => args.closeLoading());
            }}
          >
            <Form.Item label="名称" name="screenname" rules={[{ required: true, message: 'Please input your screenname!' }]}>
              <Input />
            </Form.Item>

            <Form.Item label="账号" name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="角色" name="permission" rules={[{ required: true, message: 'Please select permission!' }]}>
              <Select>
                {[PermissionType.Admin, PermissionType.Developer, PermissionType.Planner, PermissionType.Tester, PermissionType.Operator].map((val) => (
                  <Select.Option key={val} value={val}>
                    {PermissionTypeName[`CN${val}`]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </FormModal>
        </Space>
      </TableFuncs>
      <Table
        rowKey="id"
        columns={[
          {
            title: '名称',
            key: 'screenname',
            dataIndex: 'screenname',
          },
          {
            title: '账号',
            key: 'username',
            dataIndex: 'username',
          },
          {
            title: '角色',
            key: 'permission',
            render: (_, record) => {
              return PermissionTypeName[`CN${record.permission!}`];
            },
          },

          {
            title: '创建时间',
            key: 'create_at',
            render: (_, record) => {
              return formatSqlData(record.create_at!);
            },
          },
          {
            title: '更新时间',
            key: 'update_at',
            render: (_, record) => {
              return formatSqlData(record.update_at!);
            },
          },
          {
            title: '状态',
            key: 'status',
            render: (_, record) => {
              return StatusTypeName[`CN${record.status!}`];
            },
          },
          {
            title: '操作',
            key: 'action',
            render: (_, record) => {
              const disabled = record.username === 'hmfadmin';
              return (
                <Space size={[5, 5]} wrap>
                  {record.status !== StatusType.forbidden ? (
                    <>
                      <Button
                        disabled={disabled}
                        onClick={(evt) => {
                          UpdateStatus(record.username!, StatusType.forbidden)
                            .then((res) => {
                              message.success('设置成功');
                              fetchAccountList();
                            })
                            .catch((e) => {
                              message.error('设置失败');
                            });
                        }}
                      >
                        封禁账号
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        disabled={disabled}
                        onClick={(evt) => {
                          UpdateStatus(record.username!, StatusType.pending)
                            .then((res) => {
                              message.success('设置成功');
                              fetchAccountList();
                            })
                            .catch((e) => {
                              message.error('设置失败');
                            });
                        }}
                      >
                        解除封禁
                      </Button>
                    </>
                  )}
                  <Button
                    disabled={disabled}
                    onClick={(evt) => {
                      ResetPwd(record.username!)
                        .then((res) => {
                          message.success('设置成功');
                          fetchAccountList();
                        })
                        .catch((e) => {
                          message.error('设置失败');
                        });
                    }}
                  >
                    重置密码
                  </Button>
                </Space>
              );
            },
          },
        ]}
        dataSource={data[0]}
        pagination={{ defaultPageSize: 50, total: data[1] }}
        scroll={{ y: contentHeight - 150 }}
      />
    </div>
  );
};

export default AdminPage;
