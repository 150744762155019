import {IItem} from "@/types/typings/useData";


// =================================================
// ================ 战令活动 =======================
// =================================================
export interface ITableActivityWarOrderType {
    type: string
}

export interface IImportActivityWarOrderConfigReq {
    type: string
    configName: string
    config: string
}

export interface ITableActivityWarOrderConfig {
    id: number
    type: string
    config: string
    config_name: string
    create_name: string
    create_time: number
    delete: number
}

export interface ITableActivityWarOrder {
    id: number;
    type: string;
    cfg_id: number;
    create_time: number;
    start_time: number;
    end_time: number;
    create_name: string;
    close: number;
    audits?: string,
    audit_time?: number,
    audit_status?: number
}

export enum ActivityWarOrderAudit {
    Agree = 'Agree',
    Reject = 'Reject'
}

// =================================================
// ================ 任务型活动 =======================
// =================================================

export interface ITableActivityQuest {
    id: number;
    server_ids:string;
    type:number;
    sub_type:number;
    data:string;
    status:number;
    start_at:number;
    end_at:number;
    updated_at:string;
    created_at:string;
}

export interface ITableActivityQuestTaskCondition {
    // 条件ID，生成后无法修改
    id: number;

    // (Destination) 目的点; 统计点ID
    point: number;

    // 目标点，为统计目标，比如物品ID，英雄ID等
    target: number;

    // 达成条件的目标值；
    target_value: number;

    // 进度值；
    progress: number;

    // 条件描述，一般是多语言
    desc: string;
}

export interface ITableActivityQuestTask {
    // 独立的任务ID
    id: number;
    // 标记信息，一般用于前端展示使用
    tag: string;
    // 任务描述
    desc: string;
    // 达成任务的条件
    conditions: ITableActivityQuestTaskCondition[],
    // 完成该任务会获得的物品奖励列表
    reward:IItem[];
}

export interface ITableActivityQuestDataCycle {
    // 周期类型；0-一直开启直到结束；1-每次开启${value}天并轮训; 2-每${value.0}天开启${value.1}天；3-每周的周${value.0}开启${value.1}天
    type: number;

    // 根据type值进行解析
    value: string;

    // 周期第一天开始时间；如 08:00:00
    start_time: string;

    // 周期最后一天结束时间；如 12:00:00
    end_time: string;
}

export interface ITableActivityQuestData {
    // 活动内的积分物品
    score_item_id: number;
    // 活动功能类型其他配置
    config: string,
    // 任务列表
    task_list: ITableActivityQuestTask[];
    // 开启周期，每次重新开启都会重置数据
    "cycle": ITableActivityQuestDataCycle;
}


// =================================================
// ================ 抽奖型活动 =======================
// =================================================

/**
 * 抽奖型活动记录数据
 */
export interface ITableActivityDraw {
    id: number;
    server_ids:string;
    sub_type:number;
    remark:string;
    data:string;
    status:number;
    start_at:number;
    end_at:number;
    updated_at:string;
    updated_by:string;
    created_at:string;
    created_by:string;
}



// =================================================
// ================ 商店型活动 =======================
// =================================================

/**
 * 商店型活动记录数据
 */
export interface ITableActivityShop {
    id: number;
    server_ids:string;
    sub_type:number;
    remark:string;
    data:string;
    status:number;
    start_at:number;
    end_at:number;
    updated_at:string;
    updated_by:string;
    created_at:string;
    created_by:string;
}

// =================================================
// ================ 英雄升星型活动 =======================
// =================================================

/**
 * 英雄升星型活动记录数据
 */
export interface ITableActivityHeroStarUpgrade {
    id: number;
    server_ids:string;
    sub_type:number;
    remark:string;
    data:string;
    status:number;
    start_at:number;
    end_at:number;
    updated_at:string;
    updated_by:string;
    created_at:string;
    created_by:string;
}

// =================================================
// ================ 英雄试炼型活动 =======================
// =================================================

/**
 * 英雄试炼型活动记录数据
 */
export interface ITableActivityHeroTrial {
    id: number;
    server_ids:string;
    sub_type:number;
    remark:string;
    data:string;
    status:number;
    start_at:number;
    end_at:number;
    updated_at:string;
    updated_by:string;
    created_at:string;
    created_by:string;
}
