import request from '@/utils/request';
import {IBaseDataResponse, ITableConfig} from '@/types';

export const getGameConfigByKey = async function (key:string) {
  return await request<IBaseDataResponse<ITableConfig>>(`/hmf/admin/config/getByKey`, {
    method: 'GET',
    params:{
      key
    }
  });
}