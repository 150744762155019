import {
    ActivityWarOrderAudit, getCurrentUtcTimestamp,
    getDateTimestamp,
    IBaseListResponse,
    IBaseResponse,
    IImportActivityWarOrderConfigReq, ITableActivityWarOrder,
    ITableActivityWarOrderConfig,
    ITableNotice
} from "@/types";
import request from "@/utils/request";

export const importConfig = async function (data: IImportActivityWarOrderConfigReq) {
    return await request<IBaseListResponse<ITableActivityWarOrderConfig>>(`/hmf/admin/activity_war_order/importConfig`, {
        method: 'POST',
        data: { activityConfig: JSON.stringify(data) },
    });
};


export const getActivityType =  async function () {
    return await request<IBaseListResponse<string>>(`/hmf/admin/activity_war_order/getType`, {
        method: 'GET',
    });
}

export const getActivityConfig =  async function ({type = 'All',page = 1, limit = 50}) {
    return await request<IBaseListResponse<ITableActivityWarOrderConfig>>(`/hmf/admin/activity_war_order/getActivityConfig`, {
        method: 'GET',
        params: {
            type,
            page,
            limit
        }
    });
}

export const createActivity =  async function (data: any) {
    return await request<IBaseResponse>(`/hmf/admin/activity_war_order/createActivity`, {
        method: 'POST',
        data: {
            createActivity: JSON.stringify(data)
        }
    });
}

export const deleteActivityConfig =  async function (id:number) {
    return await request<IBaseResponse>(`/hmf/admin/activity_war_order/deleteActivityConfig`, {
        method: 'POST',
        data: {
            id,
        }
    });
}


export const modifyActivityConfig =  async function ({id, config}:{id: number, config: string}) {
    return await request<IBaseResponse>(`/hmf/admin/activity_war_order/modifyActivityConfig`, {
        method: 'POST',
        data: {
            id,
            config,
        }
    });
}

export const getActivityAlreadyAudit =  async function ({type = 'All',page = 1, limit = 50}) {
    return await request<IBaseListResponse<ITableActivityWarOrder>>(`/hmf/admin/activity_war_order/getActivityAlreadyAudit`, {
        method: 'GET',
        params: {
            type,
            page,
            limit
        }
    });
}

export const getActivityNeedAudit =  async function ({type = 'All',page = 1, limit = 50}) {
    return await request<IBaseListResponse<ITableActivityWarOrder>>(`/hmf/admin/activity_war_order/getActivityNeedAudit`, {
        method: 'GET',
        params: {
            type,
            page,
            limit
        }
    });
}

export const modifyActivityAudit =  async function ({operate , id}:{operate: ActivityWarOrderAudit, id: number}) {
    return await request<IBaseResponse>(`/hmf/admin/activity_war_order/modifyActivityAudit`, {
        method: 'POST',
        data: {
            operate,
            id,
        }
    });
}

export const closeActivity =  async function (id:number) {
    return await request<IBaseResponse>(`/hmf/admin/activity_war_order/closeActivity`, {
        method: 'POST',
        data: {
            id,
        }
    });
}

export const createActivityConfigType = async function (type:string){
    return await request<IBaseResponse>(`/hmf/admin/activity_war_order/createActivityConfigType`, {
        method: 'POST',
        data: {
            type,
        }
    });
}

export const getActivityById =  async function (id:number) {
    return await request<ITableActivityWarOrder>(`/hmf/admin/activity_war_order/getActivityById`, {
        method: 'POST',
        data: {
            id,
        }
    });
}
