import './index.less';
import { clearCache, createData, getList, syncData, updateData } from '@/services/table';
import { FormType, ITableConfig, formatDate, StatusType, formatSqlData } from '@/types';
import React, { useEffect, useState, useRef } from 'react';
import { Button, Upload, message, Modal, Spin, Form, Input, Checkbox } from 'antd';
import { Space, Table, Tag, Popover, Switch } from 'antd';
import { FormModal } from '@/components/formModal';
import { useFetchListWrapper, useContentHeight } from '@/hooks';
import { CopyText } from '@/components/copyText';
import { TableFuncs } from '@/components/tableFuncs';
import {isArray, isEqual, isString} from 'lodash';
import { JsonPreview } from '@/components/jsonPreview';
import { formatStringSize } from '@/utils/strKit';
import { saveAs } from 'file-saver';
import {XlsxUploader} from "@/components/xlsxUploader";

const TableForm: React.FC<{ type: FormType; record?: ITableConfig }> = ({ record, type }) => {
  return (
    <>
      <Form.Item label="英文" name="name_en" initialValue={record?.name_en} rules={[{ required: true, message: '输入英文' }]}>
        <Input disabled={type === FormType.update} maxLength={50} minLength={1} />
      </Form.Item>
      <Form.Item label="中文" name="name_cn" initialValue={record?.name_cn} rules={[{ required: true, message: '输入中文' }]}>
        <Input maxLength={50} minLength={1} />
      </Form.Item>
      {type === FormType.update ? (
        <>
          <Form.Item label="内容" name="value" rules={[{ required: true, message: '输入内容' }]}>
            <Input.TextArea rows={5} minLength={1} />
          </Form.Item>
          <Form.Item label="检测" name="check">
            <Checkbox checked={true}>检测值json</Checkbox>
          </Form.Item>
        </>
      ) : null}
    </>
  );
};

const TablePage: React.FC = () => {
  const [data, fetchList] = useFetchListWrapper<ITableConfig>(getList);
  const contentHeight = useContentHeight();
  const [sync, setSync] = useState(false);
  const [tip, setTip] = useState('');
  const inputFile = useRef<any>();
  const syncConfig = async (data: ITableConfig[]) => {
    console.log(data);
    setSync(true);
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      item.last = i === data.length - 1 ? 1 : 0;
      try {
        setTip(`${item.name_en}`);
        console.log(`【${item.name_en}】正在同步`);
        const res = await syncData(item);
        if (!res.data.result) {
          throw new Error('error');
        }
      } catch (e) {
        // message.error(`表【${item.name_en}】同步错误，停止同步`);
        setSync(false);
        return;
      }
    }
    setSync(false);
    message.success(`同步完成`);
  };

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <div className="Page table">
      {sync ? (
        <div className="loading">
          <div style={{ textAlign: 'center' }}>
            <Spin size="large"></Spin>
            <div style={{ color: '#1677ff', paddingTop: 10 }}>{tip}</div>
          </div>
        </div>
      ) : null}

      <TableFuncs>
        <Space size={[5, 5]} wrap>
          <FormModal
            btnText="新建配置"
            btnType="primary"
            onOK={(args) => {
              createData({ ...args.values })
                .then((res) => {
                  args.closeModal();
                  fetchList();
                })
                .catch((e: any) => {})
                .finally(() => args.closeLoading());
            }}
          >
            <TableForm type={FormType.create} />
          </FormModal>
          {/* <Button
            onClick={() => {
              clearCache().then((res) => {
                message.success('清除成功');
                fetchList();
              });
            }}
          >
            清空缓存
          </Button> */}
          <Button
            onClick={async () => {
              const JSZip = (await import(/* webpackChunkName: "jszip" */ 'jszip')).default as any;
              const zip = new JSZip();
              for (let i = 0; i < data.length; i++) {
                const item = data[i];
                zip.file(`${item.name_en}.json`, JSON.stringify(item));
              }
              const content = await zip.generateAsync({
                type: 'blob',
              });
              saveAs(content, `${formatDate(new Date().getTime())}.zip`);
            }}
          >
            下载配置zip
          </Button>

          <Button
            onClick={async () => {
              inputFile.current?.click();
            }}
          >
            上传配置zip
            <input
              style={{ width: 0, height: 0, opacity: 0 }}
              type="file"
              accept=".zip"
              ref={inputFile}
              id="fileInput"
              onChange={async (event: any) => {
                const selectedFile = event.target.files[0];
                if (!selectedFile) {
                  message.error('未选择文件');
                  return;
                }
                const JSZip = (await import(/* webpackChunkName: "jszip" */ 'jszip')).default as any;
                // 读取上传的ZIP文件
                const reader = new FileReader();
                reader.onload = async (e: any) => {
                  const zipData = e.target.result;
                  const zipObj = await JSZip.loadAsync(zipData);
                  // console.log(zipObj.files);
                  const files = Object.keys(zipObj.files);
                  const data = [];
                  for (let i = 0; i < files.length; i++) {
                    const fileName = files[i];
                    const json = JSON.parse(await zipObj.file(fileName).async('string'));
                    // console.log(fileName, json);
                    data.push(json);
                  }
                  const fileInput = document.getElementById('fileInput') as HTMLInputElement;
                  if (fileInput) {
                    fileInput.value = '';
                  }
                  syncConfig(data).then(() => {
                    fetchList();
                  });
                };
                reader.readAsArrayBuffer(selectedFile);
              }}
            />
          </Button>
        </Space>
      </TableFuncs>
      <Table
        rowKey="id"
        columns={[
          {
            title: '#',
            key: '#',
            width: 50,
            render: (text, record, rowIndex) => rowIndex + 1,
          },
          {
            title: '英文',
            dataIndex: 'name_en',
            key: 'name_en',
          },
          {
            title: '中文',
            dataIndex: 'name_cn',
            key: 'name_cn',
          },
          {
            title: '内容',
            key: 'value',
            width: '150px',
            ellipsis: true,
            render: (_, record) => {
              return <JsonPreview json={record.value} />;
            },
          },
          {
            title: 'MD5',
            dataIndex: 'v_md5',
            key: 'v_md5',
            render: (_, record) => {
              return record.v_md5 || '-';
            },
          },
          {
            title: '大小',
            key: 'size',
            render: (_, record) => {
              return record.value ? formatStringSize(record.value) : '-';
            },
          },
          {
            title: '更新时间',
            key: 'update_at',
            render: (_, record) => {
              return formatSqlData(record.update_at!);
            },
          },
          {
            title: '更新人',
            key: 'updated_by',
            dataIndex: 'updated_by'
          },
          {
            title: '状态',
            key: 'status',
            render: (_, record) => {
              return (
                <Switch
                  onClick={() => {
                    if (!record.value) {
                      message.error('请先更新配置');
                      return;
                    }
                    updateData({
                      id: record.id,
                      status: record.status === StatusType.active ? StatusType.pending : StatusType.active,
                    }).then((res) => {
                      fetchList();
                    });
                  }}
                  checked={record.status === StatusType.active}
                />
              );
            },
          },
          {
            title: '操作',
            key: 'action',
            render: (_, record) => {
              return (

                <Space size={[5, 5]} wrap>
                  <CopyText btnText="复制 Json" content={record.value} />

                  <XlsxUploader btnTxt={'上传表格'} onChange={(file,data)=>{
                        let isSuccess = true;
                        let confJson:any = data;



                        if (record.name_en === 'Parameter') {
                            const res: any = {};
                            confJson.forEach((item: any) => {
                                try {
                                    res[item.Key] = JSON.parse(item.Value);
                                } catch (e) {
                                    res[item.Key] = item.Value;
                                }
                            });
                            confJson = res;
                        } else {
                            if (confJson.length == 0){
                                return;
                            }

                            if (record.value) {
                                const oldValue = JSON.parse(record.value!);
                                if (oldValue.Items.length > 0) {
                                    const oldElemKeys = Object.keys(oldValue.Items[0]);
                                    const newElemKeys = Object.keys(confJson[0]);

                                    oldElemKeys.sort();
                                    newElemKeys.sort();

                                    if (!isEqual(oldElemKeys, newElemKeys)){
                                        message.error(<>
                                            【{record.name_cn}】上传了文件【{file.name}】，但新数据字段名与旧数据字段名不符合。
                                            <br />
                                            旧数据字段：{oldElemKeys.join(',')}
                                            <br />
                                            新数据字段：{newElemKeys.join(',')}
                                            <br/>
                                            删除表格无用字段或者使用【更新配置】进行调整
                                        </>);
                                        // message.error(`【${record.name_cn}】上传了文件【${file.name}，但新数据字段名与旧数据字段名不符合。旧数据字段：${oldElemKeys}\r\n，新数据字段：${newElemKeys}。删除表格无用字段或者使用【更新配置】进行调整`);
                                        return;
                                    }
                                }
                            }

                            if (record.name_en !== 'Language') {
                                confJson.shift();
                                confJson.shift();
                            }

                            confJson.forEach((item: { [x: string]: any; }) => {
                                Object.keys(item).forEach((key) => {
                                    try {
                                        const val = JSON.parse(item[key]);
                                        if (isArray(val)) {
                                            item[key] = val;
                                        }
                                    } catch (e) {
                                        // isSuccess = false;
                                    }
                                });
                            });
                            confJson = { Items: confJson };
                        }

                        const checkItems = confJson.Items;
                        if (checkItems && record.name_en !== 'Language') {
                            let checkIsSuccess = '';
                            for (let i = 0; i < checkItems.length; i++) {
                                const element = checkItems[i];
                                const keys = Object.keys(element);
                                for (let j = 0; j < keys.length; j++) {
                                    const key = keys[j];
                                    const val = element[key];
                                    if (isString(val) && val.length) {
                                        const firstChar = val.charAt(0);
                                        const lastChar = val.charAt(val.length - 1);
                                        if (( firstChar === '{' && lastChar === '}') || (firstChar === '[' && lastChar === ']')) {
                                            try {
                                                JSON.parse(val);
                                            } catch (e) {
                                                checkIsSuccess = val;
                                            }
                                        }
                                    }
                                }
                            }

                            if (checkIsSuccess) {
                                message.error('Json 格式错误' + checkIsSuccess);
                                console.log(checkIsSuccess);
                                return;
                            }
                        }

                        if (!isSuccess) {
                            message.error('Json 格式错误');
                            return;
                        }
                        updateData({
                            value: JSON.stringify(confJson),
                            id: record.id,
                        })
                            .then((res) => {
                                message.success(`${record.name_cn}更新数据成功`);
                                fetchList();
                            })
                            .catch((e: any) => {
                                message.error(`${record.name_cn}更新失败,${e.toString()}`);
                            })
                    }} />

                  <FormModal
                    btnText="更新配置"
                    onOK={(args) => {
                      let isSuccess = true;
                      try {
                        JSON.parse(args.values.value);
                      } catch (e) {
                        message.error('Json 格式错误:'+e);
                        isSuccess = false;
                      }
                      let confJson = JSON.parse(args.values.value);
                      if (record.name_en === 'Parameter') {
                        if (isArray(confJson)) {
                          const res: any = {};
                          confJson.forEach((item) => {
                            console.log(item);
                            try {
                              res[item.Key] = JSON.parse(item.Value);
                            } catch (e) {
                              res[item.Key] = item.Value;
                            }
                          });
                          confJson = res;
                        }
                      } else {
                        if (isArray(confJson)) {
                          confJson.shift();
                          confJson.shift();
                          confJson.forEach((item) => {
                            Object.keys(item).forEach((key) => {
                              try {
                                const val = JSON.parse(item[key]);
                                if (isArray(val)) {
                                  item[key] = val;
                                }
                              } catch (e) {
                                // isSuccess = false;
                              }
                            });
                          });
                          confJson = { Items: confJson };
                        }
                      }
                      console.log(confJson);

                      const checkItems = confJson.Items;
                      if (checkItems) {
                        let checkIsSuccess = '';
                        for (let i = 0; i < checkItems.length; i++) {
                          const element = checkItems[i];
                          const keys = Object.keys(element);
                          for (let j = 0; j < keys.length; j++) {
                            const key = keys[j];
                            const val = element[key];
                            if (isString(val) && val.length) {
                              const firstChar = val.charAt(0);
                              const lastChar = val.charAt(val.length - 1);
                              const needCheck = args.values.check;
                              console.log('args.values.check', args.values.check);
                              if ((needCheck && firstChar === '{' && lastChar === '}') || (firstChar === '[' && lastChar === ']')) {
                                try {
                                  JSON.parse(val);
                                } catch (e) {
                                  checkIsSuccess = val;
                                }
                              }
                            }
                          }
                        }
                        if (checkIsSuccess) {
                          message.error('Json 格式错误' + checkIsSuccess);
                          console.log(checkIsSuccess);
                          return;
                        }
                      }

                      if (!isSuccess) {
                        message.error('Json 格式错误1');
                        return;
                      }
                      updateData({
                        ...args.values,
                        value: JSON.stringify(confJson),
                        id: record.id,
                      })
                        .then((res) => {
                          args.closeModal();
                          fetchList();
                        })
                        .catch((e: any) => {})
                        .finally(() => args.closeLoading());
                    }}
                  >
                    <TableForm record={record} type={FormType.update} />
                  </FormModal>
                </Space>
              );
            },
          },
        ]}
        dataSource={data}
        pagination={{ pageSize: 50 }}
        scroll={{ y: contentHeight - 150 }}
      />
    </div>
  );
};

export default React.memo(TablePage);
