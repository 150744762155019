import request from '@/utils/request';
import { IBaseListResponse, IBaseResponse, ITableRedisKey } from '@/types';

export const getRedisKeyList = async function () {
  return await request<IBaseListResponse<ITableRedisKey>>(`/hmf/admin/redis/getList`, {
    method: 'GET',
  });
};

export const getRedisKeyCache = async function () {
  return await request<{ cache: string }>(`/hmf/admin/redis/getCache`, {
    method: 'GET',
  });
};

export const createRedisKey = async function (data: ITableRedisKey) {
  return await request<IBaseResponse>(`/hmf/admin/redis/create`, {
    method: 'POST',
    data,
  });
};

export const updateRedisKey = async function (data: ITableRedisKey) {
  return await request<IBaseResponse>(`/hmf/admin/redis/update`, {
    method: 'POST',
    data,
  });
};

export const batchUpdateRedisKey = async function (value: string) {
  return await request<IBaseResponse>(`/hmf/admin/redis/batch`, {
    method: 'POST',
    data: {
      value,
    },
  });
};
