import './app.less';
import React, { useState, useRef, useEffect } from 'react';
import {Breadcrumb, Layout, Menu, theme, Avatar, Space, Popover, Input, Dropdown, MenuProps} from 'antd';
import { UserOutlined, LogoutOutlined,DownOutlined } from '@ant-design/icons';
import AppMenu, { BreadcrumbList, MenuContent } from '@/menu/menu';
import { useStoreState } from './store';
import Login, { logout } from './login';

import { getApiVersion } from '@/services/version';
import { StatusType } from '@/types';
import ChangePwd from './changPwd';
import utc from "dayjs/plugin/utc";
import timezone from 'dayjs/plugin/timezone'
import {setDaytsTz} from "@/types/utils/dateKit";

const { Header, Content, Footer, Sider } = Layout;

const App = () => {


  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const uid = useStoreState((state) => state.userModel.uid);
  const status = useStoreState((state) => state.userModel.status);
  const nickname = useStoreState((state) => state.userModel.screenname);
  const [tz, setTz] = useState<string>('Asia/Shanghai');

  setDaytsTz(tz);

  const setDefaultTimezone = (t :string) =>{
    setTz(t);
    setDaytsTz(tz);
  }

  useEffect(() => {}, []);

  if (status === StatusType.pending) {
    return <ChangePwd />;
  }



  const languageDropdownMenuProps:MenuProps = {};
  languageDropdownMenuProps.items = [
    {
      key:'0',
      label:(<a href='#' onClick={() =>setDefaultTimezone('UTC')}>UTC</a>)
    },
    {
      key:'+8',
      label:(<a href='#' onClick={() =>setDefaultTimezone('Asia/Shanghai')}>UTC +8</a>)
    }
  ];
  return (
    <>
      {uid ? (
        <Layout style={{ minHeight: '100vh' }}>
          <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
            <AppMenu />
          </Sider>

          <Layout>
            <Content style={{ margin: '0', padding:'06px', display: 'flex', flexFlow: 'column' }}>
              <Space>
                <BreadcrumbList />
                  <Space style={{position:'fixed', right:16,top:10}}>
                    <Dropdown menu ={{ ...languageDropdownMenuProps }}>
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          时区:{tz}
                          <DownOutlined />
                        </Space>
                      </a>
                    </Dropdown>
                    <Popover
                        content={
                          <div>
                            <div style={{ cursor: 'pointer' }} onClick={logout}>
                              <Space>
                                <LogoutOutlined />
                                <span>退出</span>
                              </Space>
                            </div>
                          </div>
                        }
                        title={nickname}
                        trigger="hover"
                    >
                      <Avatar style={{ backgroundColor: '#87d068',}} icon={<UserOutlined />} />
                    </Popover>
                  </Space>

              </Space>

              <div id="mainContent" style={{ flex: 1, minHeight: 360, background: colorBgContainer }}>
                <MenuContent />
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>©2023 Quiet Fire, v{require('../../package.json').version}</Footer>
          </Layout>
        </Layout>
      ) : (
        <Login />
      )}
    </>
  );
};

export default App;
