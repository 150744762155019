import React, { useEffect, useState } from 'react';
import { Menu, Breadcrumb } from 'antd';
import items, { itemsLabelMap, defaultSelectItem, itemsMap } from './config';
import store, { useStoreActions, useStoreState } from '@/entry/store';
import Scrollbars from 'react-custom-scrollbars';
import { FetchAdmin } from '@/services/admin';
import { logout } from '@/entry/login';
import { setAccessToken } from '@/utils/request';
import { cloneDeep } from 'lodash';

const AppMenu: React.FC = () => {
  const setBreadcrumb = useStoreActions((actions) => actions.menuModel.setBreadcrumb);
  const setMenuKey = useStoreActions((actions) => actions.menuModel.setMenuKey);
  const permission = useStoreState((state) => state.userModel.permission);
  const [menuConfig, setMenuConfig] = useState<any[]>([]);

  useEffect(() => {
    setBreadcrumb(defaultSelectItem.breadcrumb);
    setMenuKey(defaultSelectItem.key);
  }, []);

  useEffect(() => {
    setMenuConfig(
      cloneDeep(items).filter((v: any) => {
        if (v.children) {
          v.children = v.children.filter((i: any) => {
            if (!i.permission) return true;
            return i.permission <= permission;
          });
        }
        if (!v.permission) return true;
        return v.permission <= permission;
      }),
    );
  }, []);

  return (
    <Scrollbars>
      <Menu
        theme="dark"
        onSelect={(item) => {
          const data: string[] = [];
          item.keyPath.forEach((i) => {
            data.unshift(itemsLabelMap[i]);
          });
          setMenuKey(item.key);
          setBreadcrumb(data);
          // FetchAdmin()
          //   .then((res) => {
          //     if (res.data.accessToken) {
          //       setAccessToken(res.data.accessToken, false);
          //     }
          //     store.getActions().userModel.login(res.data);
          //   })
          //   .catch((e) => {
          //     logout();
          //   });
        }}
        defaultSelectedKeys={[defaultSelectItem.key]}
        mode="inline"
        items={menuConfig}
      />
    </Scrollbars>
  );
};

export const BreadcrumbList: React.FC = () => {
  const breadcrumb = useStoreState((state) => state.menuModel.breadcrumb);

  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        {breadcrumb.map((item, key) => {
          return <Breadcrumb.Item key={key}>{item}</Breadcrumb.Item>;
        })}
      </Breadcrumb>
    </>
  );
};

export const MenuContent: React.FC = () => {
  const menuKey = useStoreState((state) => state.menuModel.menuKey);

  if (!menuKey || !itemsMap[menuKey]?.page) return null;

  return itemsMap[menuKey].page;
};

export default AppMenu;
