import React, { useEffect } from 'react';
import { message, Form, Input, Space, Tag } from 'antd';
import { Table } from 'antd';
import { ITableSingleConfig } from '@/types';
import { TableFuncs } from '@/components/tableFuncs';
import { FormModal } from '@/components/formModal';
import { useFetchListWrapper, useContentHeight } from '@/hooks';
import { addSingleConfig, batchUpdateSingleConfig, deleteSingleConfig, getSingleConfigList, modifySingleConfig } from '@/services/singleConfig';
import { CopyText } from '@/components/copyText';
import {JsonPreview} from "@/components/jsonPreview";

const SingleConfig: React.FC = () => {
  const [data, fetchList] = useFetchListWrapper<ITableSingleConfig>(getSingleConfigList);
  const contentHeight = useContentHeight();

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <div>
      <TableFuncs>
        <Space size={[5, 5]} wrap>
          <FormModal
            btnText="新增配置"
            btnType="primary"
            onOK={(args) => {
              message.info('新建配置');
              try {
                addSingleConfig(args.values)
                  .then((res) => {
                    args.closeModal();
                    fetchList({ limit: 50, page: 1 });
                    message.success('新建成功');
                  })
                  .catch((e) => {})
                  .finally(() => args.closeLoading());
              } catch (e: any) {
                args.closeLoading();
                message.error(e);
              }
            }}
          >
            <Form.Item label="config_key" name="config_key" rules={[{ required: true, message: 'config_key不能为空' }]}>
              <Input maxLength={100} minLength={1} />
            </Form.Item>
            <Form.Item label="value" name="value" rules={[{ required: true, message: 'value不能为空' }]}>
              <Input.TextArea rows={5} minLength={1} placeholder={'输入json格式的配置'} />
            </Form.Item>
            <Form.Item label="描述" name="detail" rules={[{ required: true, message: '输入配置描述' }]}>
              <Input maxLength={100} minLength={1} />
            </Form.Item>
          </FormModal>
          {data.length ? <CopyText btnText="复制配置" content={JSON.stringify(data)} /> : null}
          <FormModal
            btnText="批量更新"
            title="批量更新"
            onOK={(args) => {
              try {
                batchUpdateSingleConfig(args.values.value)
                  .then((res) => {
                    args.closeModal();
                    fetchList();
                    message.success('批量更新成功');
                  })
                  .catch((e) => {})
                  .finally(() => args.closeLoading());
              } catch (e: any) {
                args.closeLoading();
                message.error(e);
              }
            }}
          >
            <Form.Item label="内容" name="value" rules={[{ required: true, message: '输入内容' }]}>
              <Input.TextArea rows={5} minLength={1} />
            </Form.Item>
          </FormModal>
        </Space>
      </TableFuncs>

      <Table
        rowKey="config_key"
        columns={[
          {
            title: 'config_key',
            dataIndex: 'config_key',
            key: 'config_key',
          },
          {
            title: 'Value',
            key: 'value',
            render: (_, record) => {
                if (record.value.indexOf("{")>=0){
                   return <JsonPreview json={record.value} />;
                }
              return record.value;
            },
          },
          {
            title: '描述',
            key: 'detail',
            dataIndex: 'detail',
          },
          {
            title: '操作',
            render: (_, record) => {
              return (
                <Space size={[5, 5]} wrap>
                  <FormModal
                    btnType={'primary'}
                    btnText={'修改'}
                    title={'修改配置'}
                    onOK={(args) => {
                      try {
                        message.info('修改配置');
                        modifySingleConfig(args.values)
                          .then((res) => {
                            args.closeModal();
                            fetchList({ limit: 50, page: 1 });
                            message.success('修改成功');
                          })
                          .catch((e) => {})
                          .finally(() => args.closeLoading());
                      } catch (e: any) {
                        args.closeLoading();
                        message.error(e);
                      }
                    }}
                  >
                    <Form.Item label="config_key" name="config_key" initialValue={record.config_key}>
                      {record.config_key}
                    </Form.Item>
                    <Form.Item label="value" name="value" rules={[{ required: true, message: 'value不能为空' }]} initialValue={JSON.stringify(JSON.parse(record.value),null,"    ")} >
                      <Input.TextArea rows={5} minLength={1} placeholder={'输入json格式的配置'} />
                    </Form.Item>
                    <Form.Item label="描述" name="detail" rules={[{ required: true, message: '输入配置描述' }]} initialValue={record.detail}>
                        <Input  maxLength={100} minLength={1}  />
                    </Form.Item>
                  </FormModal>
                  <FormModal
                    btnType={'primary'}
                    danger={true}
                    btnText={'删除'}
                    title={'删除配置'}
                    onOK={(args) => {
                      try {
                        message.info('删除配置');
                        console.log(record);
                        deleteSingleConfig(record.config_key)
                          .then((res) => {
                            args.closeModal();
                            fetchList({ limit: 50, page: 1 });
                            message.success('删除成功');
                          })
                          .catch((e) => {})
                          .finally(() => args.closeLoading());
                      } catch (e: any) {
                        args.closeLoading();
                        message.error(e);
                      }
                    }}
                  ></FormModal>
                </Space>
              );
            },
          },
        ]}
        dataSource={data}
            pagination={{ pageSize: 50 }}
        scroll={{ y: contentHeight - 150 }}
      />
    </div>
  );
};

export default React.memo(SingleConfig);
