import React, { useEffect, useState } from 'react';
import { getOnlineCount } from '@/services/dashboard';
import numeral from 'numeral';
import ReactJson from 'react-json-view';

const OnlineUsers = () => {
  const [count, setCount] = useState({});
  const fetchCount = () => {
    getOnlineCount().then((res) => {
      const result: any = {};
      Object.keys(res.data.count).forEach((key) => {
        result[key] = `${numeral((res.data.count as any)[key]).format('0,0')}人`;
      });
      setCount(result);
    });
  };
  useEffect(() => {
    const handler = setInterval(() => {
      fetchCount();
    }, 30000);
    fetchCount();

    return () => {
      clearInterval(handler);
    };
  }, []);
  return (
    <div>
      在线实时统计:
      <div>
        <ReactJson groupArraysAfterLength={1000} name={false} enableClipboard={false} displayDataTypes={false} src={count} />
      </div>
    </div>
  );
};

const UserMPage: React.FC = () => {
  return (
    <>
      <OnlineUsers />
    </>
  );
};

export default UserMPage;
