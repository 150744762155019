import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, Spin, Select, message } from 'antd';
import { AddAccount, AdminLogin, FetchAdmin, RegisterAccount, UpdatePwd } from '@/services/admin';
import store, { useStoreState } from './store';
import { clearAccessToken, getAccessToken, setAccessToken } from '@/utils/request';

const ChangePwd: React.FC = () => {
  const onFinish = (values: any) => {
    console.log('Success:', values);
    UpdatePwd(values.password).then((res) => {
      message.success('修改成功');
      const data = res.data;
      setAccessToken(data.accessToken, values.remember);
      store.getActions().userModel.login(data);
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: 500,
        position: 'absolute',
        left: '50%',
        top: '30%',
        transform: 'translate(-50%,-50%)',
      }}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ width: 500 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item label="新密码" name="password" rules={[{ required: true, message: '请输入密码!' }]}>
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default ChangePwd;
