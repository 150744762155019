import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Alert,
    Button, Card,
    DatePicker,
    Form,
    Input,
    InputNumber,
    message,
    Select,
    Space,
    Switch,
    Tag,
    Table
} from 'antd';
import {DownloadOutlined} from "@ant-design/icons";
import {
    formatDate, FORMATE_DATE_TIME, FORMATE_TIME,
    formatTimestamp,  IServerInfo,  ITableActivityDraw,
} from '@/types';
import {useFetchListWrapper, useContentHeight, useFetchListWithCount} from '@/hooks';
import { FormModal,IFormOK } from '@/components/formModal';

import {getList, create, update, deleteRow} from "@/services/activityDraw";
import {TableFuncs} from "@/components/tableFuncs";
import {cloneDeep, isArray, isNumber, isString, toNumber, unset} from "lodash";
import {createServer, getAllServerList} from "@/services/server";
import {JsonPreview} from "@/components/jsonPreview";
import dayjs from "dayjs";
import {XlsxUploader} from "@/components/xlsxUploader";
import {IItem} from "@/types/typings/useData";
import {FormContext} from "antd/es/form/context";
import {PackItemInput} from "@/components/packItemInput";


// activityList 活动列表ID
const activityList = [
    {
        id:20001,
        name:'限时抽奖'
    },
]

/**
 * 配置信息
 */
interface ActivityConfig {
    ItemID:number,
    Num:number,
    Rate:number,
    Repeat:number,
    NeedOwn:number,
    Series:number
}

/**
 * 活动数据属性
 */
interface  ActivityDataProps {
    config_list?:ActivityConfig[],
    consume?:IItem[],
    guarantee?:any,
    recommend?:number[]
}

/**
 * 活动记录属性
 */
interface ActivityProps {
    sub_type?:number,
    remark?:string,
    server_ids?:number[],
    start_at?:number,
    end_at?:number,
    data?:ActivityDataProps
}

/**
 * 活动渲染器属性
 */
interface ActivityViewProps {
    initialValue?:any;
    disabled?:boolean
}

/**
 * 活动表单子渲染器 ActivityFormChildView
 * @param initialValue
 * @param disabled
 * @constructor
 */
const ActivityFormChildView: React.FC<ActivityViewProps> =({initialValue, disabled=false}) =>{
    const [serverList, fetchAllServer] = useFetchListWrapper<IServerInfo>(getAllServerList);

    const [serverSelectOptions, setServerSelectOptions] = useState((activityList || []).map((d) => ({
        value: d.id,
        label: `(${d.id}) ${d.name}`
    })))


    const myValue:any = {
        ...initialValue
    }

    if (initialValue?.sub_type != undefined){
        myValue.sub_type = initialValue.sub_type;
    }

    if (initialValue?.server_ids != undefined){
        myValue.server_ids = initialValue.server_ids;
        if (isString(myValue.server_ids)){
            myValue.server_ids =JSON.parse(myValue.server_ids);
        }
    }

    if (initialValue?.start_at != undefined){
        myValue.start_at = initialValue.start_at;
        if (isNumber(myValue.start_at)){
            myValue.start_at = dayjs.unix(myValue.start_at).format(FORMATE_DATE_TIME);
        }
    }else {
        myValue.start_at = dayjs().format('YYYY-MM-DD 00:00:00');
    }

    if (initialValue?.end_at != undefined && initialValue.end_at > 0){
        myValue.end_at = initialValue.end_at;
        if (isNumber(myValue.end_at)){
            myValue.end_at = dayjs.unix(myValue.end_at).format(FORMATE_DATE_TIME);
        }
    }else{
        myValue.end_at = dayjs().add(1,'month').format('YYYY-MM-DD 23:23:59');
    }

    if (initialValue?.data != undefined){
        myValue.data = JSON.parse(JSON.parse(initialValue.data));
    }

    if (!myValue.data){
        myValue.data = {
            config_list:[],
            consume:[],
            guarantee:{},
            recommend:[]
        }
    }

    const [configList, setConfigList] = useState<ActivityConfig[]>(myValue.data.config_list)

    useEffect(()=>{
        fetchAllServer();
    },[]);

    const serverSelectChange = (e :any, o:any)=>{

    }
    const {form} = useContext(FormContext);

    const onGuaranteeInputChange = (idx:number,v:any)=>{
        let data = form?.getFieldValue('data') as ActivityDataProps;
        if (!data){
            data = {
                guarantee:{}
            };
        }
        if (!data.guarantee){
            data.guarantee = {};
        }
        data.guarantee![idx] = v;
        form?.setFieldValue('data',data);
    }

    const onRecommendInputChange = (idx:number,v:any)=>{
        let data = form?.getFieldValue('data') as ActivityDataProps;
        if (!data){
            data = {
                recommend:[]
            };
        }

        if (!data.recommend){
            data.recommend = [];
        }

        data.recommend![idx] = v;
        form?.setFieldValue('data', data);
    }

    return (
        <span>
            <Form.Item label="活动子类型" name="sub_type" initialValue={myValue.sub_type} rules={[{ required: true, message: '活动不能为空' }]}>
                <Select
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    options={serverSelectOptions}
                    disabled={disabled}
                />
            </Form.Item>
            <Form.Item label={'备注'} name={'remark'} initialValue={myValue.remark}>
                <Input.TextArea rows={2} disabled={disabled} />
            </Form.Item>
            <Form.Item label="分服" initialValue={myValue.server_ids} name="server_ids" rules={[{ required: true, type: 'array', message: '选择分服' }]}>
                <Select
                    showSearch
                    onSelect={serverSelectChange}
                    mode="multiple"
                    allowClear
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    options={(serverList || []).map((d) => ({
                        value: d.id,
                        label: `(${d.id}) ${d.name}`,
                    }))}
                    disabled={disabled}
                />
            </Form.Item>
            <Space>
                <Form.Item label="开始时间" name="start_at" initialValue={dayjs(myValue.start_at)} rules={[{ required: true, message: '未设置开始时间' }]}>
                    <DatePicker showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss" disabled={disabled} />
                </Form.Item>
                <Form.Item label="结束时间" name="end_at" initialValue={myValue.end_at?dayjs(myValue.end_at):''} rules={[{ required: true, message: '未设置结束时间' }]} >
                    <DatePicker showTime={{ format: 'HH:mm:ss' }}  format="YYYY-MM-DD HH:mm:ss" disabled={disabled} />
                </Form.Item>
            </Space>
            <Card title={"高级配置"} size={"small"} style={{marginBottom:10}}>
                <div style={{height:0,position:'absolute',right:3,top:3}}>
                    {(()=>{
                        if (disabled) return '';
                        return (
                            <Space >
                                <Button type={'link'} href={'./assets/抽奖型活动配置表模板.xlsx'} >下载模板</Button>
                                <XlsxUploader icon={<DownloadOutlined />} btnTxt={'导入数据表'} onChange={(_,data)=>{
                                    try {
                                        // 删除头两个元素
                                        data.shift();
                                        data.shift();
                                        setConfigList(data);

                                        let cfgData = form?.getFieldValue('data') as ActivityDataProps;
                                        if (!cfgData){
                                            cfgData = {};
                                        }

                                        cfgData.config_list = data;

                                        form?.setFieldsValue({'data':cfgData});
                                    } catch (e: any) {
                                        message.error(e.toString());
                                    }
                                }} />
                            </Space>
                        )
                    })()
                    }
                </div>
                <Form.Item label={'配置列表'} name={['data','config_list']} rules={[{ required: true, type:'array', message: '未设置配置信息' }]} initialValue={myValue.data.config_list}>
                    <Input style={{display:'none'}} />
                    <Table scroll={{y:400}} rowKey={'ItemID'} columns={[
                        {
                            title:'#',
                            key:'id',
                            rowScope: 'row',
                            render:(_,__,index)=>{
                                return index;
                            },
                            width:60
                        },
                        {
                            title:'物品ID',
                            key:'ItemID',
                            dataIndex:'ItemID',
                        },
                        {
                            title:'数量',
                            key:'Num',
                            dataIndex:'Num',
                            width:60
                        },
                        {
                            title:'权重',
                            key:'Rate',
                            dataIndex:'Rate',
                            width:60
                        },
                        {
                            title:'可重复领取数量',
                            key:'Repeat',
                            dataIndex:'Repeat',
                            width:130
                        },
                        {
                            title:'需要先获得',
                            key:'NeedOwn',
                            dataIndex:'NeedOwn',
                            width:102
                        },
                        {
                            title:'系列',
                            key:'Series',
                            dataIndex:'Series',
                            width:60
                        }
                    ]} dataSource={configList} pagination={false}>
                    </Table>
                </Form.Item>


                <Form.Item label={'系列保底数'} name={['data','guarantee']} rules={[{required:true, validator:(rule, value) => {
                    if (!value[1]){
                        return Promise.reject(new Error('系列1保底数未填写'));
                    }
                        if (!value[2]){
                            return Promise.reject(new Error('系列2保底数未填写'));
                        }
                        return Promise.resolve()
                    }}]} initialValue={myValue.data.guarantee}>
                     <Input style={{display:'none'}}/>

                    系列1：<InputNumber defaultValue={myValue.data.guarantee[1]} placeholder={'系列1'} onChange={(e)=>onGuaranteeInputChange(1,e)} disabled={disabled} />

                    系列2：<InputNumber defaultValue={myValue.data.guarantee[2]} placeholder={'系列2'} onChange={(e)=>onGuaranteeInputChange(2,e)} disabled={disabled} />
                </Form.Item>


                <Form.Item label={'推荐物品，物品ID'} name={['data','recommend']} rules={[{required:true, validator:(rule, value) => {
                        if (!value[0]){
                            return Promise.reject(new Error('推荐物品必须填写'));
                        }
                        return Promise.resolve()
                    }}]}  initialValue={myValue.data.recommend}>
                        <Input style={{display:'none'}}/>
                        <InputNumber defaultValue={myValue.data.recommend[0]} placeholder={'推荐物品ID'} onChange={(e)=>onRecommendInputChange(0,e)} disabled={disabled}  />
                </Form.Item>
                <Form.Item label={'消耗物品'} name={['data','consume']} rules={[{ required: true, message: '未设置消耗品' }]} initialValue={myValue.data.consume} >
                    <PackItemInput multiple={false} disabled={disabled} />
                </Form.Item>
            </Card>
        </span>
    );
}

const ActivityDrawPage: React.FC = () => {
    const [serverList, fetchAllServer] = useFetchListWrapper<IServerInfo>(getAllServerList);
    const [data, fetchActivityList] = useFetchListWithCount<ITableActivityDraw>(getList);
    const contentHeight = useContentHeight();

    const searchServerId = useRef(-1);
    const searchSubType = useRef(0);
    const searchStatus = useRef([0,1]);

    useEffect(() => {
        fetchList();
        fetchAllServer();
    }, []);

    const fetchList = (page: number = 0, pageSize: number = 50) => {
        const param: { [key: string]: any } = { page, pageSize };

        if (searchServerId.current >= 0) {
            param['serverId'] = searchServerId.current;
        }else{
            param['serverId'] = -1
        }

        if (searchSubType.current){
            param['subType'] = searchSubType.current;
        }

        if (searchStatus.current != undefined){
            param['status'] = searchStatus.current.join(',');
        }

        fetchActivityList(param);
    };

    // 解析活动数据
    const parseActivityData = (values:any) => {
        console.log(cloneDeep(values));

        // 如果有设置全部区服活动，则进行对应替换
        values.server_ids.sort((a:number,b:number)=>{return a-b});
        if (values.server_ids[0]==0){
            values.server_ids=[0];
        }
        // 将区服转换成字符串
        values.server_ids = JSON.stringify(values.server_ids);

        values.data = JSON.stringify(values.data);
        values.start_at = values.start_at.unix();
        values.end_at = values.end_at.unix();

        return values
    }

    // 创建活动
    const createActivity = (args:IFormOK) => {
        try {
            const values = parseActivityData(args.values);

            create(values)
                .then((res) => {
                    if (!res.data.result){
                        message.error('创建失败');
                        return
                    }
                    args.closeModal();
                    fetchList();
                    message.success('创建成功');
                })
                .catch((e) => {})
                .finally(() => args.closeLoading());
        } catch (e: any) {
            args.closeLoading();
            message.error(e.toString());
        }
    }

    // 更新活动
    const updateActivity = (args:IFormOK) => {
        try {
            const values = parseActivityData(args.values);
            update(values)
                .then((res) => {
                    if (!res.data.result){
                        message.error('修改失败');
                        return
                    }
                    args.closeModal();
                    fetchList();
                    message.success('修改成功');
                })
                .catch((e) => {})
                .finally(() => args.closeLoading());
        } catch (e: any) {
            args.closeLoading();
            message.error(e.toString());
        }
    }

    // 删除活动
    const deleteActivity = (args:IFormOK) => {
        try {
            const values = args.values;
            values.actividyId = values.actividy_id;
            delete values.actividy_id;
            deleteRow(values)
                .then((res) => {
                    if (!res.data.result){
                        message.error('删除失败');
                        return
                    }
                    args.closeModal();
                    fetchList();
                    message.success('删除成功');
                })
                .catch((e) => {})
                .finally(() => args.closeLoading());
        } catch (e: any) {
            args.closeLoading();
            message.error(e.toString());
        }
    }


    return (
        <div>
            <TableFuncs>
                <Space size={[5, 5]} wrap>
                    <FormModal
                        btnText="新增活动"
                        btnType="primary"
                        onOK={createActivity}
                        winWidth={800}
                        layout={'vertical'}
                    >
                        <ActivityFormChildView />
                    </FormModal>
                </Space>
            </TableFuncs>
            <TableFuncs>
                <Space size={[5, 5]} wrap>
                    <Select
                        style={{ width: 200 }}
                        size={'large'}
                        showSearch
                        allowClear={true}
                        placeholder={'分服'}
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        options={
                        (serverList || []).
                            map((d) => ({
                            value: d.id,
                            label: `(${d.id}) ${d.name}`
                        }))}
                        onChange={(val: any, option) => {
                            searchServerId.current = val;
                        }}
                    />
                    <Select
                        style={{ width: 200 }}
                        size={'large'}
                        showSearch
                        allowClear
                        placeholder={'活动'}
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        options={(activityList || []).map((d) => ({
                            value: d.id,
                            label: `(${d.id}) ${d.name}`
                        }))}
                    />
                    <Select
                        style={{ width: 200 }}
                        size={'large'}
                        showSearch
                        mode={'multiple'}
                        allowClear={true}
                        placeholder={'状态'}
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        options={[{value:1,label:'启用中'},{value:0,label:'未启用'},{value:2,label:'已删除'}]}
                        onChange={(val: any, option) => {
                            searchStatus.current = val;
                        }}
                        defaultValue={searchStatus.current}
                    />
                    <Button
                        size={'large'}
                        type={'primary'}
                        onClick={(evt) => {
                            fetchList();
                        }}
                    >
                        查询
                    </Button>
                </Space>
            </TableFuncs>
            <Table
                rowKey="id"
                columns={[
                    {
                        title: "#",
                        key: '#',
                        width: 50,
                        dataIndex: 'id'
                    },
                    {
                        title: '分服ID',
                        key: 'server_ids',
                        dataIndex: 'server_ids',
                        render:(value, record, index)=>{
                            const serverIDs = JSON.parse(record.server_ids);
                            if (serverIDs.includes(0)){
                                return '所有分服'
                            }
                            return record.server_ids
                        }
                    },
                    {
                        title: '活动',
                        key: 'sub_type',
                        render: (_,r )=>{
                            const v = activityList.find(v => (v.id == r.sub_type));
                            if (!v){
                                return  '无效的活动';
                            }
                            return `(${v.id}) ${v.name}`;
                        },
                    },
                    {
                        title: '活动配置',
                        key: 'data',
                        dataIndex: 'data',
                        render: (_, record) => {
                            if (record.data.indexOf("{")>=0){
                                return <JsonPreview json={record.data} />;
                            }
                            return record.data;
                        },
                    },
                    {
                        title: '开始时间',
                        key:'start_at',
                        render:(_,r)=>{
                            return formatTimestamp(r.start_at!)
                        }
                    },
                    {
                        title: '结束时间',
                        key:'end_at',
                        render:(_,r)=>{
                            if (r.end_at){
                                return formatTimestamp(r.end_at!)
                            }
                            return '永久'
                        }
                    },
                    {
                        title: '状态',
                        key: 'status',
                        render:(_,r)=>{
                            if (r.status == 2){
                                return (<Tag color={"error"} >已删除</Tag>);
                            }
                            if (r.status == 0){
                                return (<Tag color={"yellow"} >未启用</Tag>);
                            }
                            if (r.status == 1){
                                return (<Tag color={"success"} >启用中</Tag>);
                            }
                        }
                    },
                    {
                        title: '更新时间',
                        key: 'updated_at',
                        dataIndex: 'updated_at',
                    },
                    {
                        title: '更新人',
                        key: 'updated_by',
                        dataIndex: 'updated_by',
                    },
                    {
                        title: '创建时间',
                        key: 'created_at',
                        dataIndex: 'created_at',
                    },
                    {
                        title: '创建人',
                        key: 'created_by',
                        dataIndex: 'created_by',
                    },
                    {
                        title: '操作',
                        key: 'operate',
                        render:(_,r)=>{
                            return (
                                <Space size={[5, 5]} wrap>
                                    <FormModal
                                        title={'预览数据'}
                                        btnText="预览"
                                        okText={'确定'}
                                        onOK={(args)=>args.closeModal()}
                                        winWidth={800}
                                        layout={'vertical'}
                                    >
                                        <ActivityFormChildView initialValue={{...r,data:JSON.stringify(r.data) as ActivityDataProps}} disabled={true} />
                                    </FormModal>
                                    <FormModal
                                        title={'复制活动'}
                                        btnText="复制"
                                        okText={'复制'}
                                        onOK={createActivity}
                                        winWidth={800}
                                        layout={'vertical'}
                                    >
                                        <ActivityFormChildView initialValue={{...r,data:JSON.stringify(r.data) as ActivityDataProps}} />
                                    </FormModal>
                                    {r.status == 0 && (
                                        <FormModal
                                            title={'修改活动'}
                                            btnText="修改"
                                            okText={'修改'}
                                            btnType="primary"
                                            onOK={updateActivity}
                                            winWidth={800}
                                            layout={'vertical'}
                                        >
                                            <Space style={{marginBottom:24}}>
                                                <Form.Item label="启用" name="status" style={{margin:0}} initialValue={false}>
                                                    <Switch defaultChecked={false} />
                                                </Form.Item>
                                                <Alert  type={"warning"} message={'启用后将无法进行修改,需要进行删除后重新创建活动'}></Alert>
                                            </Space>

                                            <Form.Item name='id' initialValue={r.id} style={{display:'none'}}>
                                                <Input type={'hidden'} />
                                            </Form.Item>
                                            <ActivityFormChildView initialValue={{...r,data:JSON.stringify(r.data) as ActivityDataProps}} />
                                        </FormModal>
                                    )}

                                    {r.status != 2 && (
                                        <FormModal
                                            btnType={'primary'}
                                            danger={true}
                                            btnText={'删除'}
                                            okText={'删除'}
                                            title={'删除活动'}
                                            onOK={deleteActivity}
                                        >
                                            <Form.Item name='id' initialValue={r.id} style={{display:'none'}}>
                                                <Input type={'hidden'} />
                                            </Form.Item>
                                            <Alert message={'请输入活动ID: '+r.sub_type+' 进行删除'} />
                                            <Form.Item label="活动ID" name="sub_type"  rules={[{ required: true, message: '活动ID不能为空' }]}>
                                                <InputNumber maxLength={100} minLength={1} style={{ width: 200 }} />
                                            </Form.Item>
                                        </FormModal>
                                    )}
                                </Space>
                            )
                        }
                    }
                ]}
                dataSource={data[0]}
                pagination={{pageSize: 50,total:data[1]}}
                scroll={{y:contentHeight - 150}}
            >
            </Table>
        </div>
    );
};

export default ActivityDrawPage;