import { getSignUrl } from '@/types';
import { message } from 'antd';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import sparkMD5 from 'spark-md5';

export const isDevelopEnv = function () {
  return process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_LOCAL;
};

export const isPreEnv = function () {
  return process.env.REACT_APP_ENV === 'pre';
};

export const isProdEnv = function () {
  return process.env.REACT_APP_ENV === 'prod';
};

const getAipPrefix = () => {
  const namespace = getNamespace();
  if (namespace === 'pre') {
    return `api-pre`;
  } else if (namespace === 'beta') {
    return `api-beta`;
  }
  return 'api';
};

export const getHostname = () => {
  const url: any = {
    dev: 'http://127.0.0.1:7001',
    prod: '',
  };
  if (isDevelopEnv()) {
    return url.dev;
  } else {
    if (isProdEnv()) {
      return url.prod;
    } else {
      return `https://${getAipPrefix()}.quietfire.fun`;
    }
  }
};

export function getNamespace() {
  return window.location.hostname.split('.')[0].split('-')[0];
}

export const getUploadHostName = getHostname;

export interface RequestInterface {
  <T>(url: string, options: AxiosRequestConfig, prefix?: string): Promise<AxiosResponse<T>>;
}

export function getAccessToken() {
  return window.localStorage.getItem('access_token') || window.sessionStorage.getItem('access_token');
}

export function setAccessToken(accessToken: string, remember = true) {
  window.localStorage.removeItem('access_token');
  if (remember) {
    window.localStorage.setItem('access_token', accessToken);
  } else {
    window.sessionStorage.setItem('access_token', accessToken);
  }
}

export function clearAccessToken() {
  window.localStorage.removeItem('access_token');
  window.sessionStorage.removeItem('access_token');
}

export const getHeaders = (url: string, timezone = 0) => {
  const headers: any = {
    'client-version': process.env.REACT_APP_CLIENT_VERSION,
    'client-id': process.env.REACT_APP_CLIENT_ID,
    'client-tz': timezone,
  };
  const ts = new Date().getTime();
  headers.sign = sparkMD5.hashBinary(getSignUrl(`${url}`, `${timezone}${ts}`, `${process.env.REACT_APP_CLIENT_SECRET}`));
  headers.timestamp = ts;
  const token = getAccessToken();
  if (token) {
    headers['access-token'] = token;
  }

  return headers;
};

// let instance: AxiosInstance;
const instancesMap: { [key: string]: AxiosInstance } = {};
/**
 * 创建axios实例
 */
const getInstance = () => {
  const instanceKey = 'default';

  if (instancesMap[instanceKey]) {
    return instancesMap[instanceKey];
  }

  instancesMap[instanceKey] = axios.create({
    baseURL: getHostname(),
    headers: {},
    // withCredentials: true,
  });

  instancesMap[instanceKey].interceptors.request.use(
    (config) => {
      let timezone = 0;
      try {
        const data = config.data;
        timezone = data.timezone || 0;
      } catch (e) {}
      const headers = getHeaders(`${config.url}`, timezone);
      Object.keys(headers).forEach((key) => {
        config.headers[key] = headers[key];
      });
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  instancesMap[instanceKey].interceptors.response.use(
    async (response) => {
      // 对响应数据做点什么
      return response;
    },
    (error) => {
      const data = error.response.data;
      const { errMsg, errCode } = data;

      if (errMsg) {
        message.error(errMsg);
      }

      // token 错误
      if (errCode === 10004) {
        clearAccessToken();
        window.location.reload();
      }

      return Promise.reject(data);
    },
  );

  return instancesMap[instanceKey];
};

const request: RequestInterface = (url: string, options: AxiosRequestConfig) => {
  const requestInstance = getInstance();

  return requestInstance(url, options);
};

export default request;
