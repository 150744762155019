import { useEffect, useState } from 'react';

export const useContentHeight = () => {
  const [contentHeight, setWindowSize] = useState(360);

  useEffect(() => {
    const handler = () => {
      const contentDiv = document.getElementById('mainContent')?.getBoundingClientRect();
      setWindowSize(contentDiv?.height || 360);
    };

    handler();

    window.addEventListener('resize', handler);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return contentHeight;
};
