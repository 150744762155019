import React from 'react';
import { Button } from 'antd';
import { Popover } from 'antd';
import { useQRCode } from 'next-qrcode';

export const QRCode: React.FC<{ content?: string; title?: string }> = ({ content, title = '' }) => {
  const { SVG } = useQRCode();

  return (
    <>
      {content ? (
        <Popover
          content={
            <SVG
              text={content}
              options={{
                margin: 2,
                width: 250,
              }}
            />
          }
          title={title}
          trigger="click"
        >
          <Button>生成二维码</Button>
        </Popover>
      ) : null}
    </>
  );
};
