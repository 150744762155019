import { IBaseListResponse, IBaseResponse, ITableConfig } from '@/types';
import request from '@/utils/request';

export const getList = async function () {
  return await request<IBaseListResponse<ITableConfig>>(`/hmf/admin/config/getList`, {
    method: 'GET',
  });
};

export const createData = async function (params: ITableConfig) {
  return await request<IBaseResponse>(`/hmf/admin/config/create`, {
    method: 'POST',
    data: params,
  });
};

export const updateData = async function (params: ITableConfig) {
  return await request<IBaseResponse>(`/hmf/admin/config/update`, {
    method: 'POST',
    data: params,
  });
};

export const clearCache = async function () {
  return await request<IBaseResponse>(`/hmf/admin/config/clearCache`, {
    method: 'GET',
  });
};

export const syncData = async function (params: ITableConfig) {
  return await request<IBaseResponse>(`/hmf/admin/config/sync`, {
    method: 'POST',
    data: params,
  });
};
