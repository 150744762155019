import request from '@/utils/request';
import { IBaseListResponse, IBaseResponse, ITableSingleConfig } from '@/types';

export const getSingleConfigList = async function () {
  return await request<IBaseListResponse<ITableSingleConfig>>(`/hmf/admin/singleConfig/getList`, {
    method: 'GET',
  });
};

export const batchUpdateSingleConfig = async function (value: string) {
  return await request<IBaseResponse>(`/hmf/admin/singleConfig/batch`, {
    method: 'POST',
    data: {
      value,
    },
  });
};

export const addSingleConfig = async function (data: ITableSingleConfig) {
  return await request<IBaseResponse>(`/hmf/admin/singleConfig/addSingleConfig`, {
    method: 'POST',
    data: {
      singleConfig: data,
    },
  });
};

export const modifySingleConfig = async function (data: ITableSingleConfig) {
  return await request<IBaseResponse>(`/hmf/admin/singleConfig/modifySingleConfig`, {
    method: 'POST',
    data: {
      singleConfig: data,
    },
  });
};

export const deleteSingleConfig = async function (config_key: string) {
  return await request<IBaseResponse>(`/hmf/admin/singleConfig/deleteSingleConfig`, {
    method: 'POST',
    data: {
      config_key,
    },
  });
};
