import { IUserData } from '@/models/userModel';
import { ITableAdminAccount, PermissionType, StatusType } from '@/types';
import request from '@/utils/request';

interface ILoginRes extends IUserData {
  accessToken: string;
}

interface ILoginReq {
  username: string;
  pwd: string;
}

export const AdminLogin = async function (params: ILoginReq) {
  return await request<ILoginRes>(`/hmf/admin/login`, {
    method: 'POST',
    data: params,
  });
};

export const FetchAdmin = async function () {
  return await request<ILoginRes>(`/hmf/admin/getUserInfo`, {
    method: 'GET',
  });
};

export const FetchAdminList = async function name() {
  return await request<ITableAdminAccount>(`/hmf/admin/getList`, {
    method: 'GET',
  });
};

interface IAddAccount {
  screenname: string;
  username: string;
  permission: PermissionType;
}
export const AddAccount = async function (params: IAddAccount) {
  return await request<{ result: boolean }>(`/hmf/admin/add`, {
    method: 'POST',
    data: params,
  });
};

interface IRegisterAccount {
  screenname: string;
  username: string;
  pwd: string;
}
export const RegisterAccount = async function (params: IRegisterAccount) {
  return await request<{ result: boolean }>(`/hmf/admin/register`, {
    method: 'POST',
    data: params,
  });
};

export const UpdatePwd = async function (pwd: string) {
  return await request<ILoginRes>(`/hmf/admin/updatePwd`, {
    method: 'POST',
    data: { pwd },
  });
};

export const UpdateStatus = async function (username: string, status: StatusType) {
  return await request<{ result: boolean }>(`/hmf/admin/updateStatus`, {
    method: 'POST',
    data: { username, status },
  });
};

export const ResetPwd = async function (username: string) {
  return await request<{ result: boolean }>(`/hmf/admin/resetPwd`, {
    method: 'POST',
    data: { username },
  });
};
