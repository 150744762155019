import {RcFile, UploadFile} from "antd/es/upload";
import React, {useState} from "react";
import {Button, Upload, UploadProps} from "antd";
import * as XLSX from "xlsx";
import {UploadOutlined} from "@ant-design/icons";
import {SizeType} from "antd/es/config-provider/SizeContext";

type XlsxUploadOnChange = (file:RcFile, data:any[])=>void;

interface XlsxProps {
    onChange?:XlsxUploadOnChange;
    btnTxt?:string;
    size?: SizeType;
    icon?: React.ReactNode;
    style?:React.CSSProperties;
}

export const XlsxUploader: React.FC<XlsxProps> = (
    {
        onChange ,
        btnTxt='上传Excel表格',
        size,
        icon=<UploadOutlined />,
        style
    }) => {
    const [fileList,setFileList ] = useState<UploadFile[]>([])
    const props: UploadProps = {
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
        },
        accept: '.xls,.xlsx',
        beforeUpload:(file, FileList)=>{
            const reader= new FileReader();
            reader.onload = (e)=>{
                const data = new Uint8Array(e.target!.result as  ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array'});
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                let jsonData = XLSX.utils.sheet_to_json(worksheet, {header:1,blankrows:false} );

                const result :any[] = [];
                const headers :any[] = jsonData[0] as any[];
                for (let i = 1; i < jsonData.length; i++) {
                    const elem:any[] = jsonData[i] as any[];
                    const jsonElem:any = {};
                    for (let j = 0; j < headers.length; j++) {
                        const  k = headers[j];
                        let v = elem[j];
                        if (v !== undefined){
                            jsonElem[k] = v;
                        }else {
                            jsonElem[k] = '';
                        }
                    }
                    result.push(jsonElem);
                }

                result.forEach((item:any)=>{
                   for (const k in item) {
                       if (k.indexOf('__EMPTY') == 0 || k == 'undefined' || k == undefined){
                           delete item[k];
                       }
                   }
                })

                if (onChange){
                    onChange(file, result);
                }

                setFileList([]);
            };
            reader.readAsArrayBuffer(file);
            return false;
        },
        multiple:false,
        // listType:"picture-card",
        maxCount:1,
    };
    return (
        <Upload {...props} fileList={fileList} style={{display:"none"}}>
            <Button style={style} icon={icon} type={'primary'} size={size} >{btnTxt}</Button>
        </Upload>
    );
};
