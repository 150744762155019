import React, { useEffect, useRef, useState } from 'react';
import { message, Form, Input, Space, Tag } from 'antd';
import { Table, Switch } from 'antd';
import { ITableRedisKey, ITableSingleConfig } from '@/types';
import { TableFuncs } from '@/components/tableFuncs';
import { FormModal } from '@/components/formModal';
import { useFetchListWrapper, useContentHeight } from '@/hooks';
import { batchUpdateRedisKey, createRedisKey, getRedisKeyCache, getRedisKeyList, updateRedisKey } from '@/services/redisKey';
import { CopyText } from '@/components/copyText';
import { JsonPreview } from '@/components/jsonPreview';

const RedisKey: React.FC = () => {
  const [data, fetchListData] = useFetchListWrapper<ITableRedisKey>(getRedisKeyList);
  const contentHeight = useContentHeight();
  const [cacheData, setCacheData] = useState('');
  const fetchList = async () => {
    fetchListData().then(() => {
      getRedisKeyCache()
        .then((res) => {
          setCacheData(res.data.cache);
        })
        .catch(() => {
          setCacheData('');
        });
    });
  };

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <div>
      <TableFuncs>
        <Space size={[5, 5]} wrap>
          <FormModal
            btnText="新增配置"
            btnType="primary"
            onOK={(args) => {
              try {
                createRedisKey(args.values)
                  .then((res) => {
                    args.closeModal();
                    fetchList();
                    message.success('新建成功');
                  })
                  .catch((e) => {})
                  .finally(() => args.closeLoading());
              } catch (e: any) {
                args.closeLoading();
                message.error(e);
              }
            }}
          >
            <Form.Item label="key" name="r_key" rules={[{ required: true, message: 'key不能为空' }]}>
              <Input maxLength={200} minLength={1} />
            </Form.Item>
            <Form.Item label="value" name="r_value" rules={[{ required: true, message: 'value不能为空' }]}>
              <Input maxLength={200} minLength={1} />
            </Form.Item>
            <Form.Item label="描述" name="r_comment" rules={[{ required: true, message: '描述不能为空' }]}>
              <Input maxLength={200} minLength={1} />
            </Form.Item>
          </FormModal>
          {cacheData ? <JsonPreview btntxt="查看缓存" json={cacheData} /> : null}
          {data.length ? <CopyText btnText="复制配置" content={JSON.stringify(data)} /> : null}
          <FormModal
            btnText="批量更新"
            title="批量更新"
            onOK={(args) => {
              try {
                batchUpdateRedisKey(args.values.value)
                  .then((res) => {
                    args.closeModal();
                    fetchList();
                    message.success('批量更新成功');
                  })
                  .catch((e) => {})
                  .finally(() => args.closeLoading());
              } catch (e: any) {
                args.closeLoading();
                message.error(e);
              }
            }}
          >
            <Form.Item label="内容" name="value" rules={[{ required: true, message: '输入内容' }]}>
              <Input.TextArea rows={5} minLength={1} />
            </Form.Item>
          </FormModal>
        </Space>
      </TableFuncs>

      <Table
        rowKey="id"
        columns={[
          {
            title: 'key',
            dataIndex: 'r_key',
            key: 'r_key',
          },
          {
            title: 'value',
            key: 'r_value',
            dataIndex: 'r_value',
          },
          {
            title: '描述',
            key: 'r_comment',
            dataIndex: 'r_comment',
          },
          {
            title: '操作',
            render: (_, record) => {
              return (
                <Space size={[5, 5]} wrap>
                  <FormModal
                    btnType={'primary'}
                    btnText={'修改'}
                    title={'修改配置'}
                    onOK={(args) => {
                      try {
                        message.info('修改配置');
                        updateRedisKey({
                          ...args.values,
                          id: record.id,
                        })
                          .then((res) => {
                            args.closeModal();
                            fetchList();
                            message.success('修改成功');
                          })
                          .catch((e) => {})
                          .finally(() => args.closeLoading());
                      } catch (e: any) {
                        args.closeLoading();
                        message.error(e);
                      }
                    }}
                  >
                    <Form.Item label="key" name="r_key" initialValue={record.r_key} rules={[{ required: true, message: 'key不能为空' }]}>
                      <Input maxLength={200} minLength={1} />
                    </Form.Item>
                    <Form.Item label="value" name="r_value" initialValue={record.r_value} rules={[{ required: true, message: 'value不能为空' }]}>
                      <Input maxLength={200} minLength={1} />
                    </Form.Item>
                    <Form.Item label="描述" name="r_comment" initialValue={record.r_comment} rules={[{ required: true, message: '描述不能为空' }]}>
                      <Input maxLength={200} minLength={1} />
                    </Form.Item>
                  </FormModal>
                </Space>
              );
            },
          },
        ]}
        dataSource={data}
        pagination={{ pageSize: 50 }}
        scroll={{ y: contentHeight - 150 }}
      />
    </div>
  );
};

export default React.memo(RedisKey);
