/**
 * 计算字符串大小
 * @param str
 * @returns
 */
export function formatStringSize(str: string) {
  const encoder = new TextEncoder();
  const encodedStr = encoder.encode(str);
  const sizeInBytes = encodedStr.length;

  if (sizeInBytes < 1024) {
    return `${sizeInBytes} B`;
  } else if (sizeInBytes < 1024 * 1024) {
    const sizeInKb = (sizeInBytes / 1024).toFixed(2);
    return `${sizeInKb} KB`;
  } else {
    const sizeInMb = (sizeInBytes / (1024 * 1024)).toFixed(2);
    return `${sizeInMb} MB`;
  }
}

/**
 * 实现C#的格式化字符串
 * @param format 需要格式化的文本
 * @param args 格式化的传参
 */
export function formatString(format: string, ...args: any[]): string {
  return format.replace(/{(\d+)}/g, (match, index) => {
    return typeof args[index] !== 'undefined' ? args[index] : match;
  });
}