import { PermissionType, StatusType } from '@/types';
import { action, Action } from 'easy-peasy';

export interface IUserData {
  uid: string;
  screenname: string;
  permission: PermissionType;
  status: StatusType;
}

export interface IUserModel extends IUserData {
  login: Action<IUserModel, IUserData>;
  logout: Action<IUserModel>;
}

export const userModel: IUserModel = {
  uid: '',
  screenname: '',
  permission: PermissionType.Operator,
  status: StatusType.forbidden,
  login: action((state, payload) => {
    state.uid = payload.uid;
    state.screenname = payload.screenname;
    state.permission = payload.permission;
    state.status = payload.status;
  }),
  logout: action((state) => {
    state.uid = '';
    state.screenname = '';
    state.permission = PermissionType.Operator;
    state.status = StatusType.forbidden;
  }),
};
