import { IBaseResponse, ISqlModelGameServer } from '@/types';
import request from '@/utils/request';

export const delCache = async function (keys: string) {
  return await request<IBaseResponse>(`/hmf/admin/mock/delCache`, {
    method: 'POST',
    data: { keys },
  });
};

export const mockByUid = async function (uids: string, targetUid?: string, lv?: string) {
  return await request<IBaseResponse>(`/hmf/admin/mock/mockByUid`, {
    method: 'POST',
    data: { uids, targetUid, lv },
  });
};

export const mockByLTUid = async function (lt_uids: string) {
  return await request<IBaseResponse>(`/hmf/admin/mock/mockByLTUid`, {
    method: 'POST',
    data: { lt_uids },
  });
};

export const clearUserCache = async function (uid: string) {
  return await request<IBaseResponse>(`/hmf/admin/mock/clearUserCache`, {
    method: 'POST',
    data: { uid },
  });
};

export const clearLtUidCache = async function (lt_uid: string) {
  return await request<IBaseResponse>(`/hmf/admin/user/clearLtUidCache`, {
    method: 'POST',
    data: { lt_uid },
  });
};

export const registerAccount = async function ({ account, pwd }: any) {
  return await request<IBaseResponse>(`/hmf/admin/user/registerByAccount`, {
    method: 'POST',
    data: { account, pwd },
  });
};

export const getGameServer = async function () {
  return await request<ISqlModelGameServer>(`/hmf/admin/server/getInfo`, {
    method: 'GET',
  });
};

export const updateGamerServer = async function (row: string) {
  return await request<IBaseResponse>(`/hmf/admin/server/update`, {
    method: 'POST',
    data: { row },
  });
};

export const cloneUserData = async function (uids: string, targetUid?: string) {
  return await request<IBaseResponse>(`/hmf/admin/mock/clone`, {
    method: 'POST',
    data: { uids, targetUid},
  });
};
