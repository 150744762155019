import React, { useEffect, useRef, useState } from 'react';
import { TableFuncs } from '@/components/tableFuncs';
import {Table, Button, Upload, message, Form, Input, Space, InputNumber} from 'antd';
import {forbiddenAccount, getList, kickOutAccount} from '@/services/account';
import { useFetchListWrapper, useContentHeight, useFetchListWithCount } from '@/hooks';
import { EmailType, formatDate, FORMATE_DATE_TIME, formatSqlData, getDateTimestamp, ITableAccount, StatusType, StatusTypeName } from '@/types';
import { UserGameData } from '@/components/userGameData';
import { UserForbidden } from '@/components/userForbidden';
import { FormModal } from '@/components/formModal';
import { DatePicker } from 'antd';
import { isArray, toNumber } from 'lodash';
import { createUserMail } from '@/services/email';
import { useStoreState } from '@/entry/store';
import {AccountForbidden} from "@/components/accountForbidden";
import {kickOutUser} from "@/services/user";
const { Search } = Input;

const UserPage: React.FC = () => {
  const [data, fetchAccountList] = useFetchListWithCount<ITableAccount>(getList);
  const uid = useStoreState((state) => state.userModel.uid);
  const contentHeight = useContentHeight();
  const searchAccountId = useRef(0);
  const searchAccount = useRef('');

  useEffect(() => {
      fetchAccountList();
  }, []);

  const fetchList = (page:number = 0,pageSize:number = 50) => {
      const param:{[key:string]:any} = {page,pageSize};

      if (searchAccountId.current) {
        param['accountId'] = searchAccountId.current;
      }

      if (searchAccount.current) {
          param['account'] = searchAccount.current;
      }

      fetchAccountList(param)
  };


  return (
    <div>
      <TableFuncs>
        <Space size={[5, 5]} wrap>
            <Input style={{width:200}}  size={'large'} key={'nicknameSearcher'} placeholder={'账户名'} allowClear onChange={(evt)=>{
                searchAccount.current = evt.target.value
            }} />
            <InputNumber style={{width:200}} size={'large'} key={'accountIdSearcher'} placeholder={'账户ID'} min={0} onChange={(val:any)=>{
                searchAccountId.current = val
            }}/>
            <Button size={'large'} type={'primary'} onClick={(evt)=>{
                fetchList();
            }}>查询</Button>
        </Space>
      </TableFuncs>
      <Table
        rowKey="id"
        columns={[
          {
            title: '账户ID',
            key: 'id',
            dataIndex: 'id',
            // render: (text, record, rowIndex) => rowIndex + 1,
          },

          {
            title: '设备号',
            key: 'deviceid',
            dataIndex: 'deviceid',
          },
            {
                title: '账号',
                key: 'username',
                dataIndex: 'username',
            },
          {
            title: '平台',
            dataIndex: 'platform',
            key: 'platform',
          },
          {
            title: '创建版本',
            dataIndex: 'version',
            key: 'version',
          },
          {
            title: '创建时间',
            key: 'create_at',
            render: (_, record) => {
              return formatSqlData(record.create_at!);
            },
          },
          {
            title: '更新时间',
            key: 'update_at',
            render: (_, record) => {
              return formatSqlData(record.update_at!);
            },
          },
          {
            title: '状态',
            key: 'status',
            render: (_, record) => {
              return <>{StatusTypeName[`CN${record.status || 1}`]}</>;
            },
          },
          {
            title: '操作',
            key: 'action',
            render: (_, record) => {
              return (
                <Space size={[5, 5]} wrap>
                  <AccountForbidden accountId={record.id} />
                  {record.status === StatusType.active ? (
                    <>
                      <FormModal
                        btnText="封禁账户"
                        btnType={"primary"}
                        danger={true}
                        onOK={(args) => {
                          forbiddenAccount({
                            isEnable: false,
                            accountId: record.id,
                            create_by: uid,
                            days: args.values.days,
                          })
                            .then((res) => {
                              message.success('设置成功');
                              args.closeModal();
                              fetchList();
                            })
                            .catch((e) => {
                              message.error('设置失败');
                            })
                            .finally(() => args.closeLoading());
                        }}
                      >
                        <Form.Item label="天数" name="days" rules={[{ required: true, message: '输入封禁天数' }]}>
                          <Input maxLength={100} minLength={1} />
                        </Form.Item>
                      </FormModal>

                        <Button danger={true} type={"dashed"}
                            onClick={() => {
                                kickOutAccount({
                                    accountId: record.id,
                                })
                                    .then(() => {
                                        message.success('将账户踢下线成功');
                                    })
                                    .catch(() => {
                                        message.error('将账户踢下线失败');
                                    });
                            }}
                        >
                            踢  下  线
                        </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          forbiddenAccount({
                            isEnable: true,
                            uid: record.id,
                            create_by: uid,
                          })
                            .then(() => {
                              message.success('设置成功');
                              fetchList();
                            })
                            .catch(() => {
                              message.error('设置失败');
                            });
                        }}
                      >
                        解除封禁
                      </Button>
                    </>
                  )}
                </Space>
              );
            },
          },
        ]}
        dataSource={data[0]}
        pagination={{ defaultPageSize: 50,total:data[1],onChange:fetchList}}
        scroll={{ y: contentHeight - 150 }}
      />
    </div>
  );
};

export default UserPage;
