import React, {useContext, useEffect, useState} from 'react';
import { TableFuncs } from '@/components/tableFuncs';
import { Table, Button, message, Form, Input, Select, Switch, Space, InputNumber, Tag,DatePicker } from 'antd';
import { useFetchListWrapper, useContentHeight, useFetchListWithCount, useFetchItems } from '@/hooks';
import {
  checkIsExpiryUTCTimestamp,
  Dayts,
  EmailStatus,
  formatDate,
  FORMATE_DATE_TIME,
  formatSqlData,
  formatTimestamp,
  getDateTimestamp,
  IServerInfo,
  ITableConfig,
  ITableSystemMail
} from '@/types';
import { createSystemMail, getSystemMailList, updateSystemMail } from '@/services/email';
import { JsonPreview } from '@/components/jsonPreview';
import { FormModal } from '@/components/formModal';
import { CloseCircleTwoTone } from '@ant-design/icons';
import { cloneDeep, isArray, isUndefined, toNumber } from 'lodash';
import { getAllServerList } from '@/services/server';
import {convertQualityName} from "@/types/typings/config";
import {PackItemInput} from "@/components/packItemInput";
import {FormContext} from "antd/es/form/context";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

interface IConfigItem {
  ID: number;
  /** 品质 */
  Quality: number;
  Desc: string;
  Name: string;
}

const MailView: React.FC<{value?:any,disable?:boolean}> = ({value= {},disable=false})=>{
  const [serverList, fetchAllServer] = useFetchListWrapper<IServerInfo>(getAllServerList);
  const initialValue = {...value}

  if (!initialValue.rangtime){
    initialValue.rangtime = [dayjs(dayjs().format("YYYY-MM-DD 00:00:00")), dayjs(dayjs().add(1,'month').format("YYYY-MM-DD 23:59:59"))];
    if (initialValue.EffectiveTime){
      initialValue.rangtime[0] = dayjs(initialValue.EffectiveTime*1000);
    }
    if (initialValue.ExpiryTime){
      initialValue.rangtime[1] = dayjs(initialValue.ExpiryTime*1000);
    }
  }

  useEffect(() => {
    fetchAllServer();
  }, []);

  return (
      <>
        <Form.Item label="分服" initialValue={initialValue.server_id||0} name="server_id" rules={[{ required: true, type: 'number', message: '选择分服' }]}>
          <Select
              disabled={disable}
              showSearch
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              options={(serverList || []).map((d) => ({
                value: d.id,
                label: d.name,
              }))}
          />
        </Form.Item>
        <Form.Item label="标题" name="Title" initialValue={initialValue.Title} rules={[{ required: true, message: '输入标题' }]}>
          <Input maxLength={100} minLength={1} disabled={disable} />
        </Form.Item>
        <Form.Item label="内容" name="Content" initialValue={initialValue.Content} rules={[{ required: true, message: '输入内容' }]}>
          <Input.TextArea maxLength={2000} rows={5} minLength={1} disabled={disable}/>
        </Form.Item>
        <Form.Item label="日期" name="rangtime" initialValue={initialValue.rangtime} rules={[{ required: true, message: '输入时间范围' }]}>
          <RangePicker showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss" disabled={disable}/>
        </Form.Item>
        <Form.Item label="奖励" name="Rewards" initialValue={initialValue.Rewards} rules={[{ required: false, message: '输入奖励' }]}>
          <PackItemInput disabled={disable}/>
        </Form.Item>
      </>
  );

}

const MailPage: React.FC = () => {
  const [data, fetchList] = useFetchListWithCount<ITableSystemMail>(getSystemMailList);
  const [serverList, fetchAllServer] = useFetchListWrapper<IServerInfo>(getAllServerList);
  const contentHeight = useContentHeight();

  const [rewardsValue, setRewardsValue] = useState<any[]>([]);


  useEffect(() => {
    fetchList({ limit: 50, preLastId: 0 });
    fetchAllServer();
  }, []);

  return (
    <div>
      <TableFuncs>
        <FormModal
          btnText="新建邮件"
          btnType="primary"
          onOK={(args) => {
            try {
              const EffectiveTime = getDateTimestamp(formatDate(args.values.rangtime[0].$d, FORMATE_DATE_TIME));
              const ExpiryTime = getDateTimestamp(formatDate(args.values.rangtime[1].$d, FORMATE_DATE_TIME));
              if (EffectiveTime >= ExpiryTime) {
                throw new Error('生效时间必须小于过期时间');
              }
              const data = {
                ...args.values,
                ExpiryTime,
                EffectiveTime,
                status: EmailStatus.Disable,
              };
              delete data.rangtime;

              createSystemMail(data)
                .then((res) => {
                  args.closeModal();
                  fetchList({ limit: 50, preLastId: 0 });
                })
                .catch((e) => {})
                .finally(() => args.closeLoading());
            } catch (e: any) {
              args.closeLoading();
              message.error(e.message);
            }
          }}
          onOpen={() => {
            setRewardsValue([]);
          }}
        >
          <MailView />
        </FormModal>
      </TableFuncs>
      <Table
        rowKey={(data) => {
          return data.id!.toString();
        }}
        columns={[
          {
            title: 'id',
            key: 'id',
            dataIndex: 'id',
          },
          {
            title: '分服',
            key: 'server_id',
            render: (_, r) => {
              const svr = serverList.find((value, index, obj) => {
                return r.server_id == value.id;
              });
              if (!svr) {
                return '分服:' + r.server_id + '[不存在]';
              } else {
                return svr.id + ':' + svr.name;
              }
            },
          },
          {
            title: '标题',
            dataIndex: 'Title',
            key: 'Title',
          },
          {
            title: '生效时间/UTC',
            key: 'EffectiveTime',
            render: (_, record) => {
              return formatTimestamp(record.EffectiveTime!);
            },
          },
          {
            title: '过期时间/UTC',
            key: 'ExpiryTime',
            render: (_, record) => {
              if (checkIsExpiryUTCTimestamp(record.ExpiryTime!)) {
                return <span style={{ color: '#cf1322' }}>{formatTimestamp(record.ExpiryTime!)}(过期)</span>;
              }
              return formatTimestamp(record.ExpiryTime!);
            },
          },
          {
            title: '创建时间',
            key: 'create_at',
            render: (_, record) => {
              return formatSqlData(record.create_at!);
            },
          },
          {
            title: '状态',
            key: 'status',
            render: (_, record) => {
              return (
                <Switch
                  onClick={() => {
                    updateSystemMail({
                      ...record,
                      status: record.status === EmailStatus.Enable ? EmailStatus.Disable : EmailStatus.Enable,
                    }).then((res) => {
                      fetchList({ limit: 50, preLastId: 0 });
                    });
                  }}
                  checked={record.status === EmailStatus.Enable}
                />
              );
            },
          },
          {
            title: '预览',
            key: 'content',
            render: (_, record) => {
              return (
                  <FormModal
                      btnText="预览"
                      btnType="primary">
                    <MailView value={record} disable={true} />
                  </FormModal>
              );
            },
          },
        ]}
        dataSource={data[0]}
        pagination={{ pageSize: 50 }}
        scroll={{ y: contentHeight - 150 }}
      />
    </div>
  );
};

export default MailPage;
