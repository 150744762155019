import React, {useContext, useEffect} from 'react';
import {Button, Upload, message, Form, Input, Space, Tabs, UploadProps} from 'antd';
import { Table, Switch } from 'antd';
import {formatDate, ITableNotice, NoticeStatus, FormType, formatSqlData, VersionPlatformType} from '@/types';
import { useFetchListWrapper, useContentHeight } from '@/hooks';
import { FormModal } from '@/components/formModal';
import { TableFuncs } from '@/components/tableFuncs';
import {createNotice, getNoticeList, instantNotice, updateNotice} from '@/services/notice';
import { JsonPreview } from '@/components/jsonPreview';
import {getHeaders, getUploadHostName} from "@/utils/request";
import {UploadOutlined} from "@ant-design/icons";
import {updateVersion} from "@/services/version";
import {FormContext} from "antd/es/form/context";
import {RcFile} from "antd/es/upload";

const uploadPath = '/hmf/admin/notice/uploadPic';

const UploadPic: React.FC<{ onSuccess?: Function; onError?: Function }> = ({  onSuccess, onError }) => {

    const checkFileSize = (file:RcFile)=>{
        return new Promise<boolean>((resolve, reject)=>{
            const [w,h] = [400,182];
            const reader= new FileReader();
            reader.onload = (e)=>{
                const image = new Image();
                image.onload = () => {
                    const { width, height } = image;
                    if (width != w || height != h) {
                        reject(image);
                    }else {
                        resolve(true);
                    }
                };
                image.onerror = reject;
                image.src = e.target?.result as string;
            };
            reader.readAsDataURL(file);
        })
    }

    const asyncBeforeUpload = async (file:RcFile, fileList:RcFile[])=>{
        const pass = await checkFileSize(file).then(()=>{return true}).catch(()=>{return false});
        if (!pass){
            message.error('图片宽高不符合：必须是:400x182');
        }
        return pass || Upload.LIST_IGNORE;
    }

    const props: UploadProps = {
        accept: '.png,.gif,.jpeg,.jpg',
        name: 'file',
        action: `${getUploadHostName()}${uploadPath}`,
        headers: {
            ...getHeaders(uploadPath),
        },
        beforeUpload:asyncBeforeUpload,
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                onSuccess?.call(this, info.file.response);
                message.success(`上传成功:${info.file.name}`);
            } else if (info.file.status === 'error') {
                onError?.call(this, info.file.response);
                message.error(`上传失败:${info.file.response.errMsg}`);
            }
        },
        // beforeUpload: asyncBeforeUpload,
        multiple:false,
        listType:"picture-card",
        maxCount:1,
        // progress: {
        //     strokeColor: {
        //         '0%': '#108ee9',
        //         '100%': '#87d068',
        //     },
        //     strokeWidth: 3,
        //     format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
        // },
    };

    return (
        <Upload {...props}>
            <Button icon={<UploadOutlined />} style={{border:0,background:"none"}}>上传图片</Button>
        </Upload>
    );
};

const TableForm: React.FC<{ type: FormType; record?: ITableNotice }> = ({ record, type }) => {
    const {form} = useContext(FormContext);
    return (
        <>
            <Form.Item label="标题" initialValue={record?.title} name="title" rules={[{ required: true, message: '输入标题' }]}>
                <Input maxLength={100} minLength={1} />
            </Form.Item>
            <Form.Item label="内容" initialValue={record?.content} name="content" rules={[{ required: true, message: '输入内容' }]}>
                <Input.TextArea maxLength={2000} rows={5} minLength={1} />
            </Form.Item>
            <Space>
                <Form.Item initialValue={record?.picture} rules={[{ message: '上传文件' }]}>
                    <UploadPic
                        onSuccess={(res: any) => {
                            console.log('form',form,res);
                            form?.setFieldsValue({'picture':res.url});
                        }}
                    />
                </Form.Item>
            </Space>

            <Form.Item label="图片链接" initialValue={record?.picture} name="picture" rules={[{ message: '输入图片链接' }]}>
                <Input disabled={true} />
            </Form.Item>
        </>
    );
};

const DialogTipTableForm: React.FC<{ type: FormType; record?: ITableNotice }> = ({ record, type }) => {
    return (
        <>
            <Tabs tabPosition="left">
                <Tabs.TabPane key="Tw" tab="繁体中文">
                    <Form.Item name="title_Tw" >
                        <Input placeholder="输入’繁体中文‘的标题"/>
                    </Form.Item>
                    <Form.Item name="content_Tw">
                        <Input.TextArea rows={10} minLength={1} placeholder={"输入‘繁体中文’的内容"}/>
                    </Form.Item>
                </Tabs.TabPane>
                <Tabs.TabPane key="Zh" tab="简体中文">
                    <Form.Item name="title_Zh">
                        <Input placeholder="输入’简体中文‘的标题"/>
                    </Form.Item>
                    <Form.Item name="content_Zh">
                        <Input.TextArea rows={10} minLength={1} placeholder={"输入‘简体中文’的内容"}/>
                    </Form.Item  >
                </Tabs.TabPane>
                <Tabs.TabPane key="En" tab="英语">
                    <Form.Item name="title_En">
                        <Input placeholder="输入‘英语’的标题" />
                    </Form.Item>
                    <Form.Item name="content_En">
                        <Input.TextArea rows={10} minLength={1}  placeholder={"输入‘英语’的内容"} />
                    </Form.Item>
                </Tabs.TabPane>
            </Tabs>
        </>
    );
};

const NoticePage: React.FC = () => {
    const [data, fetchList] = useFetchListWrapper<ITableNotice>(getNoticeList);
    const contentHeight = useContentHeight();

    useEffect(() => {
        fetchList({ limit: 50, preLastId: 0 });
    }, []);

    return (
        <div>
            <TableFuncs>
                <Space>
                    <FormModal
                        btnText="新建公告"
                        btnType="primary"
                        onOK={(args) => {
                            createNotice({
                                ...args.values,
                                status: NoticeStatus.Disable,
                            })
                                .then((res) => {
                                    args.closeModal();
                                    fetchList({ limit: 50, preLastId: 0 });
                                })
                                .catch((e) => {})
                                .finally(() => args.closeLoading());
                        }}
                    >
                        <TableForm type={FormType.create} />
                    </FormModal>
                    {/*<FormModal*/}
                    {/*    btnText="发布即时公告"*/}
                    {/*    btnType="primary"*/}
                    {/*    onOK={(args) => {*/}
                    {/*        instantNotice({*/}
                    {/*            ...args.values,*/}
                    {/*        })*/}
                    {/*            .then((res) => {*/}
                    {/*                args.closeModal();*/}
                    {/*            })*/}
                    {/*            .catch((e) => {})*/}
                    {/*            .finally(() => args.closeLoading());*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <DialogTipTableForm type={FormType.create} />*/}
                    {/*</FormModal>*/}
                </Space>
            </TableFuncs>



            <Table
                rowKey="id"
                columns={[
                    {
                        title: 'id',
                        key: 'id',
                        dataIndex: 'id',
                    },
                    {
                        title: '标题',
                        dataIndex: 'title',
                        key: 'title',
                    },
                    {
                        title: '创建时间',
                        key: 'create_at',
                        render: (_, record) => {
                            return formatSqlData(record.create_at!);
                        },
                    },
                    {
                        title: '状态',
                        key: 'status',
                        render: (_, record) => {
                            return (
                                <Switch
                                    onClick={() => {
                                        updateNotice({
                                            ...record,
                                            status: record.status === NoticeStatus.Enable ? NoticeStatus.Disable : NoticeStatus.Enable,
                                        }).then((res) => {
                                            fetchList({ limit: 50, preLastId: 0 });
                                        });
                                    }}
                                    checked={record.status === NoticeStatus.Enable}
                                />
                            );
                        },
                    },
                    {
                        title: '公告详情',
                        key: 'content',
                        render: (_, record) => {
                            return <JsonPreview json={JSON.stringify(record)} />;
                        },
                    },
                    {
                        title: '操作',
                        key: 'action',
                        render: (_, record) => {
                            return (
                                <Space size={[5, 5]} wrap>
                                    <FormModal
                                        btnText="更新公告"
                                        onOK={(args) => {
                                            updateNotice({
                                                ...record,
                                                ...args.values,
                                            })
                                                .then((res) => {
                                                    message.success('更新成功');
                                                    args.closeModal();
                                                    fetchList({ limit: 50, preLastId: 0 });
                                                })
                                                .catch((e: any) => {})
                                                .finally(() => args.closeLoading());
                                        }}
                                    >
                                        <TableForm record={record} type={FormType.update} />
                                    </FormModal>
                                </Space>
                            );
                        },
                    },
                ]}
                dataSource={data}
                pagination={{ pageSize: 50 }}
                scroll={{ y: contentHeight - 150 }}
            />
        </div>
    );
};

export default React.memo(NoticePage);
