import React, {useEffect} from 'react';
import {Alert, Button, Form, Input, message, Select, Space, Tabs, Tag,DatePicker} from 'antd';
import { Table } from 'antd';
import {
    ActivityWarOrderAudit,
    formatDate,
    FORMATE_DATE_TIME,
    formatTimestamp,
    getCurrentUtcTimestamp,
    getDateTimestamp,
    getLocalUnix,
    IServerInfo,
    ITableActivityWarOrder,
    ITableActivityWarOrderConfig, ITableActivityWarOrderType,
} from '@/types';
import { useFetchListWrapper, useContentHeight } from '@/hooks';
import { FormModal } from '@/components/formModal';

import {
    closeActivity, createActivity, createActivityConfigType, deleteActivityConfig,
    getActivityAlreadyAudit,
    getActivityById,
    getActivityConfig,
    getActivityNeedAudit, getActivityType,
    importConfig,
    modifyActivityAudit, modifyActivityConfig,
} from "@/services/activityWarOrder";
import {getAllServerList} from "@/services/server";
import {TableFuncs} from "@/components/tableFuncs";
import {JsonPreview} from "@/components/jsonPreview";
import {CopyText} from "@/components/copyText";
import dayjs from "dayjs";


// =======================================================

const ListView:React.FC = ()=>{
    const [data, fetchList] = useFetchListWrapper<ITableActivityWarOrder>(getActivityAlreadyAudit);
    const contentHeight = useContentHeight();

    useEffect(() => {
        fetchList({ type: 'All',limit: 50, page: 1 });
    }, []);
    return (
        <div>
            <Table
                rowKey="id"
                columns={[
                    {
                        title: "#",
                        key: '#',
                        width: 50,
                        render: (text, record, rowIndex) =>  rowIndex + 1
                    },
                    {
                        title: '分服ID',
                        key: 'server_id',
                        dataIndex: 'server_id'
                    },
                    {
                        title: '配置类型',
                        key: 'type',
                        dataIndex: 'type'
                    },
                    {
                        title: '配置名',
                        key: 'config_name',
                        dataIndex: 'config_name'
                    },
                    {
                        title: '创建人',
                        dataIndex: 'create_name',
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'create_time',
                        render: (_, record) => {
                            return formatTimestamp(record.create_time)
                        }
                    },
                    {
                        title: '活动开始时间',
                        dataIndex: 'start_time',
                        render: (_, record) => {
                            return formatTimestamp(record.start_time)
                        }
                    },
                    {
                        title: '活动结束时间',
                        dataIndex: 'end_time',
                        render: (_, record) => {
                            return formatTimestamp(record.end_time)
                        }
                    },
                    {
                        title: '审核人',
                        dataIndex: 'audits',
                    },
                    {
                        title: '状态',
                        render: (_, record) => {
                            const now = getLocalUnix()
                            if (record.start_time > now) {
                                return <Tag color="#2db7f5">未开始</Tag>
                            } else if (record.start_time < now && record.end_time > now){
                                return <Tag color="#87d068">进行中</Tag>
                            } else {
                                return <Tag color="#108ee9">已结束</Tag>
                            }
                        }
                    },
                    {
                        title: '活动操作',
                        render: (_, record) => {
                            return (<Space size={[5, 5]} wrap>
                                <Button>{"查看配置"}</Button>
                            </Space>)
                        }
                    },
                    {
                        title: '活动操作',
                        render: (_, record) => {
                            return (<Space size={[5, 5]} wrap>
                                <FormModal
                                    btnType={"primary"}  btnText={"修改"} title={"修改活动,先不搞"} onOK={(args) => {
                                } }>
                                </FormModal>
                                <FormModal
                                    btnType={"primary"} danger={true}  btnText={"关闭"} title={"关闭活动后此次活动将无法重新开启，是否确认关闭！"} onOK={(args) => {
                                    closeActivity(record.id)
                                        .then((res) => {
                                            fetchList({ type: 'All',limit: 50, page: 1 });
                                            args.closeModal();
                                        })
                                        .catch((e) => {})
                                        .finally(() => args.closeLoading());
                                } }>
                                </FormModal>
                            </Space>)
                        }
                    }
                ]}
                dataSource={data}
                pagination={{pageSize: 50}}
                scroll={{y:contentHeight - 150}}
            >
            </Table>
        </div>
    );
}

// =======================================================

const AuditView:React.FC = () =>{
    const [data, fetchList] = useFetchListWrapper<ITableActivityWarOrder>(getActivityNeedAudit);
    const contentHeight = useContentHeight();

    useEffect(() => {
        fetchList({ type: 'All',limit: 50, page: 1 });
    }, []);

    return (
        <div>
            <Table
                rowKey="id"
                columns={[
                    {
                        title: "#",
                        key: '#',
                        width: 50,
                        render: (text, record, rowIndex) =>  rowIndex + 1
                    },
                    {
                        title: '分服ID',
                        key: 'server_id',
                        dataIndex: 'server_id'
                    },
                    {
                        title: '配置类型',
                        key: 'type',
                        dataIndex: 'type'
                    },
                    {
                        title: '配置名',
                        key: 'config_name',
                        dataIndex: 'config_name'
                    },
                    {
                        title: '创建人',
                        dataIndex: 'create_name',
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'create_time',
                        render: (_, record) => {
                            return formatTimestamp(record.create_time)
                        }
                    },
                    {
                        title: '活动开始时间',
                        dataIndex: 'start_time',
                        render: (_, record) => {
                            return formatTimestamp(record.start_time)
                        }
                    },
                    {
                        title: '活动结束时间',
                        dataIndex: 'end_time',
                        render: (_, record) => {
                            return formatTimestamp(record.end_time)
                        }
                    },
                    {
                        title: '审核操作',
                        render: (_, record) => {
                            return (<Space size={[5, 5]} wrap>
                                <FormModal
                                    btnType={"primary"}  btnText={"同意"} title={"审核同意"} onOK={(args) => {
                                    modifyActivityAudit({ operate: ActivityWarOrderAudit.Agree, id: record.id})
                                        .then((res) => {
                                            fetchList({ type: 'All',limit: 50, page: 1 });
                                            args.closeModal();
                                        })
                                        .catch((e) => {})
                                        .finally(() => args.closeLoading());
                                } }>
                                </FormModal>
                                <FormModal
                                    btnType={"primary"} danger={true}  btnText={"拒绝"} title={"审核拒绝"} onOK={(args) => {
                                    modifyActivityAudit({ operate: ActivityWarOrderAudit.Reject, id: record.id})
                                        .then((res) => {
                                            fetchList({ type: 'All',limit: 50, page: 1 });
                                            args.closeModal();
                                        })
                                        .catch((e) => {})
                                        .finally(() => args.closeLoading());
                                } }>
                                </FormModal>
                            </Space>)
                        }
                    }
                ]}
                dataSource={data}
                pagination={{pageSize: 50}}
                scroll={{y:contentHeight - 150}}
            >

            </Table>
        </div>
    );
}


// =====================================================

const TableForm: React.FC = () => {
    const [data, fetchList] = useFetchListWrapper<ITableActivityWarOrderType>(getActivityType);

    useEffect(() => {
        fetchList();

    }, []);
    return (
        <>
            <GetSelectType data={data}></GetSelectType>
            <Form.Item label="活动配置名称" name="configName" rules={[{required: true, message: "输入给配置的命名"}]} >
                <Input showCount maxLength={100} minLength={1} placeholder={"给活动配置命名"} />
            </Form.Item>
            <Form.Item label="活动配置"  name="config" rules={[{ required: true, message: '输入内容' }]}>
                <Input.TextArea rows={5} minLength={1} placeholder={"输入json格式的配置"} />
            </Form.Item>
        </>
    );
};


const disabledDate = (current: any) => {
    return current && current < dayjs().startOf('day');
};

const ImportConfig: React.FC<{ record: ITableActivityWarOrderConfig, serverList:IServerInfo[] }> = ({ record,serverList=[] }) => {
    return (
        <>
            <Form.Item hidden={true} label={"id"} name="id" initialValue={record.id}>
            </Form.Item>
            <Form.Item label="分服" name="server_id" rules={[{ required: true, message: '选择分服' }]}>
                <Select
                    defaultActiveFirstOption={true}
                    showSearch
                    filterOption={(input,option)=>(option?.label ?? '').includes(input)}
                    options={(serverList.filter((v,_)=>{return v.id !== 0})|| []).map((d) => ({
                        value: d.id,
                        label: d.name,
                    }))}
                />
            </Form.Item>
            <Form.Item label="活动类型" name="type" initialValue={record.type}>
                <Alert message={record.type} type="info" style={{ width: '40%', color:"gray", padding: 0, textAlign: "center"}}/>
            </Form.Item>
            <Form.Item label="配置名称" >
                <Alert message={record.config_name} type="info" style={{ width: '40%', color:"gray", padding: 0, textAlign: "center"}}/>
            </Form.Item>
            <Form.Item label={"活动时间"} name={"rangeTime"}>
                <DatePicker.RangePicker showTime={{ format: 'HH:mm:ss' }} disabledDate={disabledDate} format="YYYY-MM-DD HH:mm:ss" />
            </Form.Item>
        </>
    );
};

const GetSelectType:React.FC<{data: ITableActivityWarOrderType[]}> = ({data}) => {
    return (
        <Form.Item label="活动类型" name="type">
            <Select>
                {data.map((val) => <Select.Option key={val.type} value={val.type}>{val.type}</Select.Option>)}
            </Select>
        </Form.Item>
    )
}


const ConfigView:React.FC = ()=>{
    const [data, fetchList] = useFetchListWrapper<ITableActivityWarOrderConfig>(getActivityConfig);
    const [serverList, fetchAllServer] = useFetchListWrapper<IServerInfo>(getAllServerList);
    const contentHeight = useContentHeight();

    useEffect(() => {
        fetchList({ type: 'All',limit: 50, page: 1 });
        fetchAllServer();
    }, []);

    return (
        <div>
            <TableFuncs>
                <Space size={[5, 5]} wrap>
                    <FormModal
                        btnText="新建配置"
                        btnType="primary"
                        onOK={(args) => {
                            importConfig({
                                ...args.values,
                            })
                                .then((res) => {
                                    args.closeModal();
                                    fetchList({ type: 'All',limit: 50, page: 1 });
                                })
                                .catch((e) => {})
                                .finally(() => args.closeLoading());
                        }}
                    >
                        <TableForm />
                    </FormModal>
                    <FormModal
                        btnText="新建配置类型"
                        btnType="primary"
                        onOK={(args) => {
                            message.info('创建类型')
                            createActivityConfigType(
                                args.values.type,
                            )
                                .then((res) => {
                                    args.closeModal();
                                    message.success('创建成功')
                                })
                                .catch((e) => {})
                                .finally(() => args.closeLoading());
                        }}
                    >
                        <Form.Item label={"活动配置类型"} name={"type"}>
                            <Input placeholder={"例如战令配置类型输入 WarOrder"}></Input>
                        </Form.Item>
                    </FormModal>
                </Space>
            </TableFuncs>

            <Table
                rowKey="id"
                columns={[
                    {
                        title: "#",
                        key: '#',
                        width: 50,
                        render: (text, record, rowIndex) =>  rowIndex + 1
                    },
                    {
                        title: '配置类型',
                        key: 'type',
                        dataIndex: 'type'
                    },
                    {
                        title: '配置名',
                        key: 'config_name',
                        dataIndex: 'config_name'
                    },
                    {
                        title: '内容',
                        dataIndex: 'config',
                        key: 'config',
                        width: '150px',
                        ellipsis: true,
                        render: (_, record) => {
                            return <JsonPreview json={record.config}></JsonPreview>
                        }
                    },
                    {
                        title: '创建人',
                        dataIndex: 'create_name',
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'create_time',
                        render: (_, record) => {
                            return formatTimestamp(record.create_time)
                        }
                    },
                    {
                        title: '复制 Json',
                        render: (_, record) => {
                            return (<Space size={[5, 5]} wrap>
                                <CopyText btnText="复制 Json" content={record.config} />
                            </Space>)
                        }
                    },
                    {
                        title: '导入活动',
                        render: (_, record) => {
                            return (<Space size={[20, 20]} wrap>
                                <FormModal
                                    btnType="primary"  btnText="导入活动" title="导入活动" onOK={(args)  => {
                                    try{
                                        const start_time = getDateTimestamp(formatDate(args.values.rangeTime[0].$d, FORMATE_DATE_TIME))
                                        const end_time = getDateTimestamp(formatDate(args.values.rangeTime[1].$d, FORMATE_DATE_TIME));
                                        const now = getCurrentUtcTimestamp()

                                        if (start_time <= now) {
                                            throw new Error('活动开始时间必须大于当前时间!')
                                        }
                                        if (end_time <= start_time) {
                                            throw new Error('活动结束时间必须大于开始时间!')
                                        }

                                        const sendData: any = {
                                            id: args.values.id,
                                            server_id:args.values.server_id,
                                            type: args.values.type,
                                            start_time,
                                            end_time
                                        }

                                        createActivity(sendData)
                                            .then((res) => {
                                                args.closeModal();
                                            })
                                            .catch((e) => {})
                                            .finally(() => args.closeLoading());
                                    } catch (e:any) {
                                        args.closeLoading()
                                        message.error(e.message)
                                    }
                                } }>
                                    <ImportConfig record={record} serverList={serverList}/>
                                </FormModal>
                            </Space>)
                        }
                    },
                    {
                        title: '删除配置',
                        render: (_, record) => {
                            return (<Space size={[5, 5]} wrap>
                                <FormModal
                                    btnType={"primary"}  btnText={"修改配置"} title={"是否修改配置"} onOK={(args) => {
                                    try{
                                        JSON.parse(args.values.config)
                                        modifyActivityConfig({id: record.id, config: args.values.config})
                                            .then((res) => {
                                                args.closeModal();
                                                fetchList({ type: 'All',limit: 50, page: 1 });
                                            })
                                            .catch((e) => {})
                                            .finally(() => args.closeLoading());
                                    } catch (e:any) {
                                        message.error(e.message)
                                    }

                                } }>
                                    <Form.Item label="活动配置"  name="config" rules={[{ required: true, message: '输入内容' }]}>
                                        <Input.TextArea  rows={5} minLength={1} placeholder={"输入json格式的配置"} />
                                    </Form.Item>
                                </FormModal>
                                <FormModal
                                    btnType={"primary"} danger={true}  btnText={"删除配置"} title={"是否删除配置"} onOK={(args) => {
                                    deleteActivityConfig(record.id!)
                                        .then((res) => {
                                            args.closeModal();
                                        })
                                        .catch((e) => {})
                                        .finally(() => args.closeLoading());
                                } }>
                                </FormModal>
                            </Space>)
                        }
                    }
                ]}
                dataSource={data}
                pagination={{pageSize: 50}}
                scroll={{y:contentHeight - 150}}
            >

            </Table>
        </div>
    );
}


const ActivityWarOrderPage: React.FC = () => {
return     <Tabs
     items={
        [
            {
                'key':'list',
                'label':'列表',
                'children':<ListView />
            },
            {
                'key':'audit',
                'label':'审核',
                'children':<AuditView />
            },
            {
                'key':'config',
                'label':'配置',
                'children':<ConfigView />
            }
        ]
     }
     type={'card'}
    >
    </Tabs>
};

export default ActivityWarOrderPage;