import React from 'react';

export const UrlLink: React.FC<{ href?: string }> = ({ href }) => {
  return (
    <>
      {href ? (
        <a href={href} target="_blank">
          {href}
        </a>
      ) : (
        '-'
      )}
    </>
  );
};
